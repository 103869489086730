import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { styled } from "@mui/system";
import clsx from "clsx";

const StyledButton = styled(Button)(({ theme, color, size, round, fullWidth, disabled, simple, block, link, justIcon }) => ({
  //...theme.typography.button,
  padding: "12px 30px",
  borderRadius: round ? "30px" : "3px",
  textTransform: "uppercase",
  fontWeight: "bold",
  letterSpacing: "0.025em",
  textDecoration: "none",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  border: "none",
  outline: "none",
  position: "relative",
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "translateY(-1px)",
    boxShadow: "0 3px 10px rgba(0, 0, 0, 0.2)"
  },
  "&:active": {
    transform: "translateY(0)",
    boxShadow: "none"
  },
  "&:disabled": {
    opacity: 0.65,
    pointerEvents: "none"
  },
  ...(color && theme.palette[color]),
  ...(size === "sm" && {
    padding: "10px 24px",
    fontSize: "0.875rem"
  }),
  ...(size === "lg" && {
    padding: "14px 36px",
    fontSize: "1.25rem"
  }),
  ...(fullWidth && { width: "100%" }),
  ...(disabled && { opacity: 0.5 }),
  ...(simple && {
    color: theme.palette.text.primary,
    background: "transparent",
    boxShadow: "none"
  }),
  ...(block && { width: "100%", display: "block" }),
  ...(link && {
    background: "transparent",
    color: theme.palette.primary.main,
    boxShadow: "none"
  }),
  ...(justIcon && {
    paddingLeft: "12px",
    paddingRight: "12px",
    fontSize: "20px",
    height: "41px",
    minWidth: "41px",
    width: "41px",
    "& .fab,& .fas,& .far,& .fal,& svg,& .material-icons": {
      marginRight: "0px"
    },
    "& svg": {
      width: "24px",
      height: "24px",
      lineHeight: "41px"
    }
  })
}));

const RegularButton = React.forwardRef((props, ref) => {
  const {
    color = "primary",
    size = "md",
    round = false,
    fullWidth = false,
    disabled = false,
    simple = false,
    block = false,
    link = false,
    justIcon = false,
    className,
    children,
    ...rest
  } = props;

  return (
    <StyledButton
      {...rest}
      ref={ref}
      color={color}
      size={size}
      round={round}
      fullWidth={fullWidth}
      disabled={disabled}
      simple={simple}
      block={block}
      link={link}
      justIcon={justIcon}
      className={clsx(className)}
    >
      {children}
    </StyledButton>
  );
});

RegularButton.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "error",
    "info",
    "success",
    "warning",
    "white",
    "transparent"
  ]),
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  simple: PropTypes.bool,
  round: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default RegularButton;
