import Axios from "axios";
import axios from "axios";
import { Alert } from "react";

import React, { useState, useEffect } from "react";

import {pass_function} from '../index';

import { Capacitor, CapacitorCookies } from '@capacitor/core';

var diaAnterior = -1;

export async function temPedidos(){

  return new Promise((resolve, reject) => {
    

    var CancelToken = axios.CancelToken;

    var parametros = 'parresid=' + global.cod_restaurante +
    "&parverapp=" + global.versaoApp +
    '&parcliid=' + global.log_usu_id;

    let source = CancelToken.source();
    setTimeout(() => {
      source.cancel();
      
    }, global.timeOutTimer);

    
    axios.post(
      global.siteurl + 'listapedidos_search.php', 
      parametros,
      {
          cancelToken: source.token,
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
          }
      }).then((response) => {
        var responseData = response.data;
        global.pedidoAtencao = false;

        if (responseData.resposta == '1') {
          global.pedidoAtencao = responseData.pedidoAtencao;
          resolve(true);                
        }else{
          resolve(false);
        }
    })
    .catch((error) =>{
      if(axios.isCancel(error)){

        resolve(false); 
    
      }
      else{
    
        resolve(false); 
    
      }
    });
    
  });    
}

export function calculaValorProduto(){

  var preco_final = 0;
  var valorAdicional = 0;

  if (global.tmp_produto_qtdesab > 1) {
    for (var i = 0; i < global.produto_atual.sabores.length; i++){

      if (global.tipo_calc_misto == global.tp_calc_media){

        preco_final = preco_final + global.produto_atual.sabores[i].preco;

      }else{

        preco_final = (preco_final < global.produto_atual.sabores[i].preco) ? global.produto_atual.sabores[i].preco : preco_final;

      }

      for (var n = 0; n < global.produto_atual.sabores[i].adicionais.length; n++){
        var regAdic = global.produto_atual.sabores[i].adicionais[n];
        valorAdicional = (global.buscar) ? valorAdicional + (regAdic.precor * regAdic.qtde) : valorAdicional + (regAdic.precod * regAdic.qtde);
      }       
      
    }

    if (global.tipo_calc_misto == global.tp_calc_media){

      preco_final = preco_final / global.produto_atual.sabores.length;      

    }
  }else{

    if (global.produto_atual.sabores.length >= 1){
      preco_final = global.produto_atual.sabores[0].preco;

      for (var n = 0; n < global.produto_atual.sabores[0].adicionais.length; n++){
        var regAdic = global.produto_atual.sabores[0].adicionais[n];
        valorAdicional = (global.buscar) ? valorAdicional + (regAdic.precor * regAdic.qtde) : valorAdicional + (regAdic.precod * regAdic.qtde);
      }
    }     

  }

  for (var n = 0; n < global.produto_atual.adicionais.length; n++){
    var regAdic = global.produto_atual.adicionais[n];
    valorAdicional = (global.buscar) ? valorAdicional + (regAdic.precor * regAdic.qtde) : valorAdicional + (regAdic.precod * regAdic.qtde);
  } 

  preco_final = preco_final + valorAdicional;

  return preco_final;
  
}

export function geraNumeroRandom( qtde ){
  var resultado = '';
  for (var i = 0; i < qtde; i++){
    resultado = resultado + Math.floor(Math.random() * 10).toString();
  }

  return resultado;
}

export function removeEComercial(txt){
  try{
    return (txt.replace('&', 'E').replace('=', '-'));
  }catch(e){
    return '';
  }
  
}

export function verificaHoraAtu(){
  var horasEntreDatas = hoursBetweenDates(global.dataatualCliente);

  return (horasEntreDatas >= 2);
}

export function databaseDesatualizado(ctx){
  BS_ALE(ctx, 'Atenção', 'Já faz tempo que você iniciou o aplicativo e sua sessão expirou. Você será direcionado para a tela inicial.', [{text: 'OK', onPress: () => {ctx.props.navigation.navigate('Home');}},],{cancelable: false},);
}

export function hoursBetweenDates(dataAntiga, dataAtual = new Date().getTime()){
  var msDiff = new Date(dataAtual).getTime() - new Date(dataAntiga).getTime();    //Future date - current date
  var hoursTillNow = Math.floor(msDiff / (1000 * 60 * 60));
  return hoursTillNow;
}

function geraEndereco() {
  var endereco = {
    REFERENCIA    : '',
    COMPLEMENTO   : '',
    BAIRROCOD     : '',
    BAIRRO        : '',
    CIDADE        : '',
    NUMERO        : '',
    CEP           : '',
    ENDERECO      : '',
    ESTADO        : '',
    CONSULTA_CEP  : {
      LOGRADOURO  : '',
      COMPLEMENTO : '',
      BAIRRO      : '',
      CIDADE      : '',
      UF          : '',
      UNIDADE     : '', 
    },
  }; 

  return endereco;
}

export function renovaJWT(){

  return new Promise((resolve, reject) => {
    
    

    var CancelToken = axios.CancelToken;

    var parametros = 
    "&partoken="    + global.myToken +
    "&pargrures=" + '199' +
    "&parres=" + global.cod_restaurante +
    "&parverapp=" + '99';

    let source = CancelToken.source();
    setTimeout(() => {
      source.cancel();
      
    }, global.timeOutTimer);

    
    axios.post(
      global.siteurl + 'renova_jwt.php', 
      parametros,
      {
      cancelToken: source.token,
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
      },
      withCredentials: true,
    }).then((response) => {
        var responseData = response.data;

        
        if (responseData.resposta == '1') {
          localStorage.removeItem(global.chaveLocalLogin);
          localStorage.setItem(global.chaveLocalLogin, responseData.JWT);
          
          global.myToken = responseData.JWT;

          setTimeout( () => {
            resolve(true);
          }, 600);
        }else{          
          resolve(false);
        }
    })
    .catch((error) =>{
      if(axios.isCancel(error)){

        resolve(false); 
    
      }
      else{
    
        resolve(false); 
    
      }
    });
    
  });    
}

export function renovaJWWWWT() {
  try{
    var parametros = 
    "&partoken="    + global.myToken +
    "&pargrures=" + '199' +
    "&parres=" + global.cod_restaurante +
    "&parverapp=" + '99';
    
    let source = axios.CancelToken.source();
    setTimeout(() => {
        source.cancel();
        
    }, global.timeOutTimer);

    axios.post(
      global.siteurl + 'renova_jwt.php', 
      parametros,
      {
      cancelToken: source.token,
      headers: {
          'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
      },
      withCredentials: true,
    }).then((response) => {
        var responseData = response.data;
        alert( responseData.JWT );
        localStorage.removeItem(global.chaveLocalLogin);
        localStorage.setItem(global.chaveLocalLogin, responseData.JWT);
        global.myToken = responseData.JWT;
    })
    .catch((error) =>{
      if (error == '785936'){
        var teste = error;
      }else if(axios.isCancel(error)){
        var teste = error;
      }else{

        var teste = error;

      }
    });
  } catch (err) {
    var teste = err;
  }  
}


export function BS_ALE(ctx, title, message, callback){

  //Alert.alert(title, message, buttons, options);
  //alert(message);

  //pass_function(message);

  if (ctx.props.vmxale){
    ctx.props.vmxale(title, message, callback);
  }else if (ctx.vmxale){
    ctx.vmxale(title, message, callback);
  }
  

}

function URLToArray(url) {
  var request = {};
  var pairs = url.substring(url.indexOf('?') + 1).split('&');
  for (var i = 0; i < pairs.length; i++) {
      if(!pairs[i])
          continue;
      var pair = pairs[i].split('=');
      request[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
   }
   return request;
}

function ArrayToURL(array) {
  var pairs = [];
  for (var key in array)
    if (array.hasOwnProperty(key))

      pairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(array[key]));
  return pairs.join('&');
}

export function myEncodeURI(parametros){

  //var dataArr = URLToArray(parametros);
  return (parametros);

}


function atualizaProds(){
  try {

    var prodJson = [];

    var produtos = leProdutos();
    
    for (var indexProd = 0; indexProd < produtos.length; indexProd++){
      var sabores = produtos[indexProd].sabores;
      
      var arrRefPar = {contAdics : 0}

      prodJson.push({
        VALOR_TOTAL      : '',
        TAMANHO          : '',
        QUANTIDADE       : produtos[indexProd].qtde,
        NOME             : '',
        OBSERVACOES      : '',
        ITEM_PRODUTO     : (indexProd + 1),
        VALOR_UNITARIO   : '',
        CODIGO_PRODUTO   : 0,
        VALOR_DESCONTO   : 0,
        INGREDIENTES     : [],
        VALOR_ACRESCIMO  : 0,
        COMBO            : false,
        PRODUTO_INTERNO  : sabores[0].produto.idProduto,
        PIZZA            : true,
        MEIA             : {},
      });

      prodJson[indexProd].INGREDIENTES = leAdicionais(produtos[indexProd].grupoAdics, (indexProd + 1), arrRefPar, true);

      for (var n = 0; n < sabores.length; n++){
          sabores[n].adicionais = leAdicionais(sabores[n].produto.grupoAdics, (indexProd + 1), arrRefPar);
          sabores[n].remocoes   = leRemocoes(sabores[n].produto.ingredientes, (indexProd + 1), arrRefPar);

          if (n == 0){
            prodJson[indexProd].NOME             = sabores[n].produto.nomeProduto;
            prodJson[indexProd].OBSERVACOES      = sabores[n].produto.observacao;
            prodJson[indexProd].UNIDADE          = sabores[n].produto.unidade;
            prodJson[indexProd].CODIGO_PRODUTO   = sabores[n].produto.idProduto;
            prodJson[indexProd].RESGATE_PONTOS   = sabores[n].produto.checkedFidelidade >= 1;

            for (var indexAdic = 0; indexAdic < sabores[n].adicionais.length; indexAdic++){
              prodJson[indexProd].INGREDIENTES.push(sabores[n].adicionais[indexAdic]);
            }

            for (var indexRemoc = 0; indexRemoc < sabores[n].remocoes.length; indexRemoc++){
              prodJson[indexProd].INGREDIENTES.push(sabores[n].remocoes[indexRemoc]);
            }
            
          }else{
            prodJson[indexProd].MEIA = {
              INGREDIENTES     : [],
              PRODUTO_INTERNO  : sabores[n].produto.idProduto,
              CODIGO_PRODUTO   : sabores[n].produto.idProduto,
              NOME             : sabores[n].produto.nomeProduto,
              UNIDADE          : sabores[n].produto.unidade,
              OBSERVACOES      : sabores[n].produto.observacao,
            };

            for (var indexAdic = 0; indexAdic < sabores[n].adicionais.length; indexAdic++){
              prodJson[indexProd].MEIA.INGREDIENTES.push(sabores[n].adicionais[indexAdic]);
            }

            for (var indexRemoc = 0; indexRemoc < sabores[n].remocoes.length; indexRemoc++){
              prodJson[indexProd].MEIA.INGREDIENTES.push(sabores[n].remocoes[indexRemoc]);
            }
            
          }
      }
      
    }

    return (prodJson);
  } catch (err) {
      return ([]);
  }
}

function leProdutos(){

  try{
    let localCart = global.ProductObj.getCarrinhoCompra();
    localCart = JSON.parse(localCart);
    //load persisted cart into state if it exists
    if (!localCart) {
        localCart = [];
    }
    return (localCart);
  } catch (err) {
    
  }
}

function leSabores(registro){
  return new Promise((resolve, reject) => { 
    try{
      resolve([])
    } catch (err) {
      
    }
  });
}

function leAdicionais(registro, ITEM_PRODUTO, arrRefPar, embutido = false){
  var jsonAdic = [];

  let grupoAdicRows = registro;

  for (var n = 0; n < grupoAdicRows.length; n++){
    let adicRows = grupoAdicRows[n].adicionais;
    for (var i = 0; i < adicRows.length; i++){
      if (adicRows[i].checked > 0){
        arrRefPar.contAdics += 1;
        jsonAdic.push({
          CODIGO_INGREDIENTE   : adicRows[i].idAdicional,
          INGREDIENTE_INTERNO  : adicRows[i].idAdicional,
          QUANTIDADE           : adicRows[i].checked,
          ADICIONA_RETIRA      : 'A',
          ITEM_PRODUTO         : ITEM_PRODUTO,
          ITEM_INGREDIENTE     : arrRefPar.contAdics,
          DESCRICAO            : adicRows[i].nome,
          EMBUTIDO             : embutido,
        });
      }
    }
  }

  return (jsonAdic);
}

function leRemocoes(registro, ITEM_PRODUTO, arrRefPar){

  var ingredientes = registro;
  var jsonAdic     = [];

  for (var i = 0; i < ingredientes.length; i++){
    if (ingredientes[i].checked > 0){
      arrRefPar.contAdics += 1;
      jsonAdic.push({
        CODIGO_INGREDIENTE   : ingredientes[i].idIngred,
        INGREDIENTE_INTERNO  : ingredientes[i].idIngred,
        QUANTIDADE           : 1,
        ADICIONA_RETIRA      : 'R',
        ITEM_PRODUTO         : ITEM_PRODUTO,
        ITEM_INGREDIENTE     : arrRefPar.contAdics,
        DESCRICAO            : ingredientes[i].nome,
        EMBUTIDO             : false,
      });
    }
  }

  return (jsonAdic);
}

export function validaCpfCnpj(val) {
  if (val.length == 14) {
      var cpf = val.trim();
   
      cpf = cpf.replace(/\./g, '');
      cpf = cpf.replace('-', '');
      cpf = cpf.split('');
      
      var v1 = 0;
      var v2 = 0;
      var aux = false;
      
      for (var i = 1; cpf.length > i; i++) {
          if (cpf[i - 1] != cpf[i]) {
              aux = true;   
          }
      } 
      
      if (aux == false) {
          return false; 
      } 
      
      for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
          v1 += cpf[i] * p; 
      } 
      
      v1 = ((v1 * 10) % 11);
      
      if (v1 == 10) {
          v1 = 0; 
      }
      
      if (v1 != cpf[9]) {
          return false; 
      } 
      
      for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
          v2 += cpf[i] * p; 
      } 
      
      v2 = ((v2 * 10) % 11);
      
      if (v2 == 10) {
          v2 = 0; 
      }
      
      if (v2 != cpf[10]) {
          return false; 
      } else {   
          return true; 
      }
  } else if (val.length == 18) {
      var cnpj = val.trim();
      
      cnpj = cnpj.replace(/\./g, '');
      cnpj = cnpj.replace('-', '');
      cnpj = cnpj.replace('/', ''); 
      cnpj = cnpj.split(''); 
      
      var v1 = 0;
      var v2 = 0;
      var aux = false;
      
      for (var i = 1; cnpj.length > i; i++) { 
          if (cnpj[i - 1] != cnpj[i]) {  
              aux = true;   
          } 
      } 
      
      if (aux == false) {  
          return false; 
      }
      
      for (var i = 0, p1 = 5, p2 = 13; (cnpj.length - 2) > i; i++, p1--, p2--) {
          if (p1 >= 2) {  
              v1 += cnpj[i] * p1;  
          } else {  
              v1 += cnpj[i] * p2;  
          } 
      } 
      
      v1 = (v1 % 11);
      
      if (v1 < 2) { 
          v1 = 0; 
      } else { 
          v1 = (11 - v1); 
      } 
      
      if (v1 != cnpj[12]) {  
          return false; 
      } 
      
      for (var i = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > i; i++, p1--, p2--) { 
          if (p1 >= 2) {  
              v2 += cnpj[i] * p1;  
          } else {   
              v2 += cnpj[i] * p2; 
          } 
      }
      
      v2 = (v2 % 11); 
      
      if (v2 < 2) {  
          v2 = 0;
      } else { 
          v2 = (11 - v2); 
      } 
      
      if (v2 != cnpj[13]) {   
          return false; 
      } else {  
          return true; 
      }
  } else {
      return false;
  }
}

function diaToTxt(dia) {

  if (diaAnterior == dia){
    return "";
  }

  diaAnterior = dia;

  switch (dia) {
    case 0:
      return "Domingo";
      break;
    case 1:
      return "Segunda";
      break;
    case 2:
      return "Terça";
      break;
    case 3:
      return "Quarta";
      break;
    case 4:
      return "Quinta";
      break;
    case 5:
      return "Sexta";
      break;
    case 6:
      return "Sábado";
      break;
    default:
      return "Domingo";
  }
}

export function geraHorarios(horariosFunc) {
  var strResp = '';

  diaAnterior = -1;

  var horariosFinal = [{
    dia : 0,
    horarios : []
  }, {
    dia : 1,
    horarios : []
  }, {
    dia : 2,
    horarios : []
  }, {
    dia : 3,
    horarios : []
  }, {
    dia : 4,
    horarios : []
  }, {
    dia : 5,
    horarios : []
  }, {
    dia : 6,
    horarios : []
  }];

  var tmpHorFunc = horariosFunc;

  for (var i = 0; i < tmpHorFunc.length; i++){
    var itemHor  = tmpHorFunc[i];
    var nextDay  = 0;

    var tmp_dia = 0;
    var tmp_ini = '00:00';
    var tmp_fim = '23:59';

    tmp_dia = itemHor.dia;
    tmp_ini = itemHor.ini;
    tmp_fim = itemHor.fim;

    horariosFinal[tmp_dia].horarios.push(tmp_ini + ' às ' + tmp_fim);
  }

  var strHorarioFinal = horariosFinal.map((item, index) => {
    if (item.horarios.length > 0){
      return (item.horarios.map( (itemHorarios, indexHorarios) => {
        return (
          <div style={{display: 'flex', flexDirection: 'row'}} key={index + indexHorarios}>
            <div style={{width: '60%'}}>
              <text style={{fontSize: 16, textAlign: 'right', fontWeight: 'bold', paddingRight: 5}}>{diaToTxt(item.dia)}</text>
            </div>
            <div style={{whiteSpace: 'nowrap'}}>
              <text style={{fontSize: 16}} >{itemHorarios}</text>
            </div>
          </div>
        );
      }));
    }else{
      return (
        <div style={{display: 'flex', flexDirection: 'row'}} key={index}>
          <div style={{width: '40%'}}>
            <text style={{fontSize: 16, textAlign: 'right', fontWeight: 'bold', paddingRight: 5}}>{diaToTxt(item.dia)}</text>
          </div>
          <div style={{width: '50%', whiteSpace: 'nowrap'}}>
            <text style={{fontSize: 16}} >{"Fechado"}</text>
          </div>
        </div>
      );
    }
    
  })

  return strHorarioFinal;
}

export function geraJson(){

  try{
    var json = {
      PEDIDO : {
        CODIGO_WEB   :  0,
        REFERENCIA   : '',
        CRITICAS     : '',
        STATUS       : 0,
        PHE_TIPO     : '',
        PHE_MIN      : 0,
        PHE_HOR      : '',
        OBSERVACAO   : '', 
        TRANSACTIONCODE: geraNumeroRandom(7),
        PEDIDO_TEMPO : 0,
        ENDERECO     : geraEndereco(),
        PRODUTOS     : atualizaProds(),
        CLIENTE : {
            CNPJ_CPF_CUPOM  : '',
            RAMAL           : '',
            FONE            : '',
            CLIENTE         : '',
            ID              : '',   
            AUTORIZACAOSESS : geraNumeroRandom(5),
        },
        PEDIDO : {
            HORA               : '',
            VALOR_PRE_RECEBIDO :  0,
            PRE_PAGAMENTO      : '',  
            VALOR_POS_RECEBIDO :  0,
            POS_PAGAMENTO      : '',
            VALOR_PEDIDO       :  0,  
            VALOR_RECEBER      :  0,
            TAXA_ENTREGA       :  0,
            VALOR_DESCONTO     :  0,  
            //BUSCAR             : true,
            PAGO               : '',
            VALOR_TROCO        :  0,  
            DATA               : '',
        },
      },        
    };

    return (json);
  }catch (nativeError) {
    return ([])
  }



}

export function formatarNumero(n, digits) {

    var negative = false;
    if (digits === undefined) {
        digits = 0;
    }
    if (n < 0) {
        negative = true;
        n = n * -1;
    }
    var multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    n = (Math.round(n) / multiplicator).toFixed(digits);
    if (negative) {
        n = (n * -1).toFixed(digits);
    }
    return Number( n ).toFixed(2).replace(".", ",");

}