import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

const StyledGrid = styled(Grid)(({ theme }) => ({
  position: "relative",
  width: "100%",
  minHeight: "1px",
  paddingRight: "15px",
  paddingLeft: "15px",
  flexBasis: "auto"
}));

export default function GridItem(props) {
  const { children, className, ...rest } = props;
  return (
    <StyledGrid item {...rest} className={className}>
      {children}
    </StyledGrid>
  );
}

GridItem.defaultProps = {
  className: ""
};

GridItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};
