/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { styled } from "@mui/material/styles";
import Icon from "@mui/material/Icon";
import Home from "@mui/icons-material/Home";
import ShoppingCart from "@mui/icons-material/ShoppingCart";
import ListAlt from "@mui/icons-material/ListAlt";
import AccountCircle from "@mui/icons-material/AccountCircle";
import Favorite from "@mui/icons-material/Favorite";

import styles from "assets/jss/material-kit-react/components/footerStyle.js";
import { makeStyles } from "tss-react/mui";

import { useLocation, useNavigate, useParams } from "react-router-dom";

const useStyles = makeStyles()(styles);

const FooterRoot = styled('div')(({ theme }) => ({
  ...styles.root,
  ...styles.stickToBottom,
}));

const FooterExtraDiv = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  height: '56px',
  width: '100%',
  maxWidth: '720px',
  margin: 'auto',
}));

export default function Footer(props) {
  var value = 7;
  var myToken = localStorage.getItem(global.chaveLocalLogin);

  const { classes } = useStyles();
  const { whiteFont, rotaAtual, footerExtra, qtdeTiposProds } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });

  function renderFooterExtra(footerExtra) {
    if (footerExtra) {
      return (
        <FooterExtraDiv>
          {footerExtra}
        </FooterExtraDiv>
      );
    }
  }

  const navigate = useNavigate();
  const params   = useLocation();

  return (
    <FooterRoot>
      {renderFooterExtra(footerExtra)}
      <BottomNavigation
        value={value}
        style={{
            padding: 10,
        }}
        onChange={(event, newValue) => {
            switch (newValue) {
                case 0:
                    navigate("/")
                break;
                case 1:
                    navigate("/carrinho-compra")
                break;
                case 2:
                    navigate("/pedido-lista")
                break;
                case 3:
                    navigate("/login-page")
                break;
            }
        }}
        showLabels
      >
        <BottomNavigationAction
          className={(params?.pathname === "/" || params?.pathname?.includes('selecionaprod')) ? classes.iconeFooterSelecionado : classes.iconeFooter}
          label="Produtos"
          icon={<Icon className="fa fa-store" style={{ fontSize: '1.3em', width: 'auto', height: '24px' }} />}
        />
        <BottomNavigationAction
          className={(params?.pathname === "carrinho-compra" || params?.pathname?.includes('carrinho-compra')) ? classes.iconeFooterSelecionado : classes.iconeFooter}
          label="Carrinho"
          icon={(qtdeTiposProds > 0) ? <div> <ShoppingCart /> <div className={classes.floatText}>{qtdeTiposProds}</div></div> : <ShoppingCart />}
        />
        <BottomNavigationAction
          className={(params?.pathname === "/pedido-lista" || params?.pathname?.includes('pedido-')) ? classes.iconeFooterSelecionado : classes.iconeFooter}
          label="Pedidos"
          icon={(global.pedidosPendentes) ? <div> <ListAlt /> <div className={classes.floatText} style={{ backgroundColor: '#ffeb3b', color: '#000', fontWeight: 'bold', fontSize: '16px' }}>!</div></div> : <ListAlt />}
        />
        <BottomNavigationAction
          className={(params?.pathname === "/login-page" || params?.pathname?.includes('usuarioinfo')) ? classes.iconeFooterSelecionado : classes.iconeFooter}
          label={(myToken) ? "Conta" : "Login"}
          icon={<AccountCircle />}
        />
      </BottomNavigation>
    </FooterRoot>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool,
  rotaAtual: PropTypes.object.isRequired,
  footerExtra: PropTypes.node,
  qtdeTiposProds: PropTypes.number
};
