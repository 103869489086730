import React, { useState, useEffect } from "react";
// @mui/material components
import { withStyles } from "tss-react/mui";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "@mui/material/Icon";
// @mui/icons-material
import Email from "@mui/icons-material/Email";
import People from "@mui/icons-material/People";
import ArrowBack from "@mui/icons-material/ArrowBack";

import PaymentIcon from '@mui/icons-material/Payment';
import MoneyIcon from 'assets/img/money.png';
import MPIcon from 'assets/img/mercadopago.png';
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import { Dialog, DialogContent, } from "@mui/material";

import { IconButton } from "@mui/material";

import Confirmacao from "components/Dialogs/Confirmacao.js";

import styles from "assets/jss/material-kit-react/views/carrinhoCompra.js";
import "assets/css/CarrinhoCompra.css";
import 'prevent-pull-refresh';

import image from "assets/img/bg7.jpg";
import { Message } from "@mui/icons-material";

import 'prevent-pull-refresh';

import axios from 'axios';

import { renovaJWT, BS_ALE, removeEComercial, geraJson } from 'funcs/MyFuncs.js';

import "@fortawesome/fontawesome-free/css/all.css";

import myCookies from 'universal-cookie';

import CurrencyInput from 'components/CurrencyInput/CurrencyInput';
import TextField from '@mui/material/TextField';

import { useNavigate } from "react-router-dom";
import { formatarNumero } from "funcs/MyFuncs";

const delayProcessando = 0;
const tempoMinProcess = 0;

const tp_status_pedido_enviado = 0;
const tp_status_recebido = 1;
const tp_status_em_preparo = 2;
const tp_status_saiu_entrega = 3;
const tp_status_entregue = 4;
const tp_status_concluido = 5;
const tp_status_recusado = 6;
const tp_status_pendente = 99;

const tp_pagamento_rest_conhecido = 73;

const tp_pagamento_pendente = 80;
const tp_pagamento_processando = 81;
const tp_pagamento_rejeitado = 82;
const tp_pagamento_restituido = 83;
const tp_pagamento_cancelado = 84;
const tp_pagamento_mediacao = 85;
const tp_pagamento_expirado = 86;

var pedidoCompOK = true;
var STS_tentativas = 0;

var totalRequestsPedido = 0;

var executaTimer = true;

class PedidoStatus extends React.Component {

    arrPosItems = [];
    arrMenuItems = [];
    cardBody = null;
    myMenu = null;
    cabecalho = null;

    ultElementMenu = -1;

    constructor(props) {
        super(props);
        // Não chame this.setState() aqui!

        let localCart = global.ProductObj.getCarrinhoCompra();
        localCart = JSON.parse(localCart);
        //load persisted cart into state if it exists
        if (!localCart) {
            localCart = [];
        }

        this.state = {
            carrinhoCompra: [],
            carrinhoAtual: localCart,
            pesquisaTxt: global.ProductObj.pesquisaProd,
            listaGruPro: [], usr_id: 12, usr_grures: 6, scrollMarginTop: (global.ProductObj.pesquisaProd.length > 0) ? 170 : 120,
            grupos_prods: [null, null, null, null, null, null, null, null],
            nomeRest: "",
            restID: "",
            grupoRest: "",
            promocao: "0",
            itemAtivo: 0,
            posItemClicado: -1,
            eventClicado: -1,
            listaPedidos: [],
            listaStatus: [],

            tmpQtdProd: 0,
            tmpProdSelect: [],
            tmpProdIndex: -1,

            senhaPedido: '',
            alertaConsultaPausada: false,
            alertaConfCopia: false,

            listaProds: [],
            valor_pedido: "0", taxa_entrega: "0", total_pedido: "0", forma_pag: '', val_pag: "0", total_desc: "0", total_troco: "0",
            enderecoFinal: '', nfeDoc: '', observacao: '',
            codCli: '',
            pedido_data: '',
            pedido_hora: '',
            pedido_tement: '',
            tipoPrevTemp: '',
            tempPrevTemp: '',
            minuPrevTemp: '',
            alertaIndisponiveis: false,
            alertaSelecionaPagamento: false,
            alertaTroco: false,

            listaPags: [],
        };

        this.updateDimensions = this.updateDimensions.bind(this);

    }

    setScrollToTop = () => {

        window.scrollTo(0, 100);

    }

    geraTempoRetirada = (pedido) => {

        if (pedido.PEDIDOS[0].PHE_TIPO == 'P') {

            return 'Estamos preparando seu pedido, a previsão do término é: ' + pedido.PEDIDOS[0].PHE_HOR;

        } else if (pedido.PEDIDOS[0].PHE_TIPO == 'M') {
            if (pedido.PEDIDOS[0].PHE_MIN > 0) {
                return 'Estamos preparando seu pedido, dentro de ' + ((pedido.PEDIDOS[0].PHE_MIN) ? 'aproximadamente ' + pedido.PEDIDOS[0].PHE_MIN + " minuto(s) a partir do horário do pedido, " : "alguns minutos") + ' você pode vir retirá-lo.';
            } else {
                return 'Estamos preparando seu pedido, dentro de alguns minutos você pode vir retirá-lo.';
            }
        } else if (pedido.PEDIDOS[0].PHE_TIPO == 'H') {
            if (pedido.PEDIDOS[0].PHE_MIN > 0) {
                return 'Estamos preparando seu pedido, dentro de ' + ((pedido.PEDIDOS[0].PHE_MIN) ? 'aproximadamente ' + pedido.PEDIDOS[0].PHE_MIN + " minuto(s) a partir do horário do pedido, " : "alguns minutos") + ' você pode vir retirá-lo.';
            } else {
                return 'Estamos preparando seu pedido, dentro de alguns minutos você pode vir retirá-lo.';
            }
        } else if ((pedido.PEDIDOS[0].PEDIDO.TEMPO_PEDIDO.length > 0) && (pedido.PEDIDOS[0].PEDIDO.TEMPO_PEDIDO > 0)) {
            return 'Estamos preparando seu pedido, dentro de ' + ((pedido.PEDIDOS[0].PEDIDO.TEMPO_PEDIDO) ? 'aproximadamente ' + pedido.PEDIDOS[0].PEDIDO.TEMPO_PEDIDO + " minuto(s) a partir do horário do pedido " : "alguns minutos") + ' você pode vir retirá-lo.';
        } else {
            return 'Estamos preparando seu pedido, dentro de alguns minutos você pode vir retirá-lo.';
        }
    }

    preencheStatus = (pedido) => {
        let arrStatus = [];

        arrStatus.push({
            'ID': tp_status_pedido_enviado,
            'Status': 'Enviado à loja',
            'Mensagem': 'Você enviou seu pedido com sucesso, aguarde uma resposta da Loja.',
            'Icone': 'paper-plane',
            'Fonte': "FontAwesome5",
        });

        arrStatus.push({
            'ID': tp_status_recebido,
            'Status': 'Recebido pela Loja',
            'Mensagem': 'Nós já recebemos seu pedido.',
            'Icone': 'store',
            'Fonte': "FontAwesome5",
        });

        arrStatus.push({
            'ID': tp_status_em_preparo,
            'Status': 'Em Preparo',
            'Mensagem': (global.buscar) ? this.geraTempoRetirada(pedido) : 'Estamos preparando seu pedido.',
            'Icone': 'clock',
            'Fonte': "FontAwesome5",
        });

        arrStatus.push({
            'ID': tp_status_saiu_entrega,
            'Status': (global.buscar) ? 'Pronto para Retirada' : 'Saiu para Entrega',
            'Mensagem': (global.buscar) ? 'Seu pedido está pronto para ser retirado.' : 'Seu pedido já está chegando, falta pouco ;)',
            'Icone': (global.buscar) ? 'hands' : 'motorcycle',
            'Fonte': "FontAwesome5",
        });

        arrStatus.push({
            'ID': tp_status_entregue,
            'Status': (global.buscar) ? 'Retirado' : 'Entregue',
            'Mensagem': 'Bom apetite !!!',
            'Icone': 'gift',
            'Fonte': "FontAwesome5",
        });

        /*arrStatus.push({
          'ID'       : tp_status_concluido,
          'Status'   : 'Concluido',
          'Mensagem' : 'Esse aqui já foi, que tal pedir mais um? rs',
          'Icone'    : 'check-double',
          'Fonte'    : "FontAwesome5",
        });*/

        arrStatus.push({
            'ID': tp_status_recusado,
            'Status': 'Recusado',
            'Mensagem': 'Infelizmente não podemos atender seu pedido, para mais informações entre em contato conosco pelo telefone: ',
            'Icone': 'times-circle',
            'Fonte': "FontAwesome5",
        });

        return arrStatus;
    }

    preenchePagamento = (status) => {
        let arrStatus = [];

        arrStatus.push({
            'ID': tp_pagamento_pendente,
            'Status': 'Pagamento não realizado',
            'Mensagem': 'Estamos esperando pelo seu pagamento. Clique a baixo e efetue seu pagamento.',
            'Icone': 'credit-card',
            'Fonte': "FontAwesome5",
        });

        arrStatus.push({
            'ID': tp_pagamento_processando,
            'Status': 'Aguardando confirmação',
            'Mensagem': 'Estamos aguardando a confirmação do seu pagamento.',
            'Icone': 'network-wired',
            'Fonte': "FontAwesome5",
        });

        arrStatus.push({
            'ID': tp_pagamento_rejeitado,
            'Status': 'Pagamento Rejeitado',
            'Mensagem': 'Infelizmente seu pagamento não pôde ser concluído.',
            'Icone': 'exclamation-triangle',
            'Fonte': "FontAwesome5",
        });

        arrStatus.push({
            'ID': tp_pagamento_restituido,
            'Status': 'Pagamento restituido',
            'Mensagem': 'O pagamento foi devolvido ao cliente.',
            'Icone': 'undo',
            'Fonte': "FontAwesome5",
        });

        arrStatus.push({
            'ID': tp_pagamento_cancelado,
            'Status': 'Pagamento Cancelado',
            'Mensagem': 'O pagamento foi cancelado. Clique a baixo e efetue seu pagamento.',
            'Icone': 'window-close',
            'Fonte': "FontAwesome5",
        });

        arrStatus.push({
            'ID': tp_pagamento_mediacao,
            'Status': 'Pagamento em mediação',
            'Mensagem': 'O seu pagamento está em mediação.',
            'Icone': 'hourglass-end',
            'Fonte': "FontAwesome5",
        });

        arrStatus.push({
            'ID': tp_pagamento_expirado,
            'Status': 'Tempo do pagamento expirado',
            'Mensagem': 'O tempo disponível para pagamento acabou.',
            'Icone': 'hourglass-end',
            'Fonte': "FontAwesome5",
        });

        return arrStatus;
    }

    geraEndereco = (arrPedido) => {
        var enderecoFinal = '';

        if (arrPedido.PEDIDO.BUSCAR) {
            enderecoFinal = 'Este pedido será retirado na loja.'
        } else {
            if (arrPedido.ENDERECO.ENDERECO.length > 0) {
                enderecoFinal = enderecoFinal + arrPedido.ENDERECO.ENDERECO;
            }

            if (arrPedido.ENDERECO.NUMERO.length > 0) {
                if (enderecoFinal.length > 0) {
                    enderecoFinal = enderecoFinal + ', ';
                }

                enderecoFinal = enderecoFinal + arrPedido.ENDERECO.NUMERO.toString();
            }

            if (arrPedido.ENDERECO.COMPLEMENTO.length > 0) {

                if (enderecoFinal.length > 0) {
                    enderecoFinal = enderecoFinal + ', ';
                }

                enderecoFinal = enderecoFinal + arrPedido.ENDERECO.COMPLEMENTO;
            }

            if (arrPedido.ENDERECO.REFERENCIA.length > 0) {

                if (enderecoFinal.length > 0) {
                    enderecoFinal = enderecoFinal + ', ';
                }

                enderecoFinal = enderecoFinal + arrPedido.ENDERECO.REFERENCIA;
            }

            if (arrPedido.ENDERECO.BAIRRO.length > 0) {

                if (enderecoFinal.length > 0) {
                    enderecoFinal = enderecoFinal + ', ';
                }

                enderecoFinal = enderecoFinal + arrPedido.ENDERECO.BAIRRO;
            }

            if (arrPedido.ENDERECO.CIDADE.length > 0) {

                if (enderecoFinal.length > 0) {
                    enderecoFinal = enderecoFinal + ' - ';
                }

                enderecoFinal = enderecoFinal + arrPedido.ENDERECO.CIDADE;
            }

            if (arrPedido.ENDERECO.ESTADO.length > 0) {

                if (enderecoFinal.length > 0) {
                    enderecoFinal = enderecoFinal + ' - ';
                }

                enderecoFinal = enderecoFinal + arrPedido.ENDERECO.ESTADO;
            }

            if (arrPedido.ENDERECO.CEP.length > 0) {

                if (enderecoFinal.length > 0) {
                    enderecoFinal = enderecoFinal + ' - ';
                }

                enderecoFinal = enderecoFinal + arrPedido.ENDERECO.CEP;

            }
        }

        return enderecoFinal;
    }

    atualizaStatus = async () => {
        var CancelToken = axios.CancelToken;


        let source = CancelToken.source();
        setTimeout(() => {
            source.cancel();

        }, global.timeOutTimer);

        if (!pedidoCompOK) {
            //this.atualizaLista();
        } else {

            const cookies = new myCookies();
            var nologin_hash = global.intercept_nologin_hash;
            nologin_hash = (nologin_hash) ? nologin_hash : '';

            axios.post(
                global.siteurl + 'pedido_consulta_simplificado.php',
                "partoken=" + global.myToken +
                "&pargrures=" + global.cod_grurest +
                "&parres=" + global.cod_restaurante +
                "&parpedido=" + global.pedidoAtual +
                "&nologin_hash=" + nologin_hash +
                "&parverapp=" + '99',
                {
                    cancelToken: source.token,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                    },
                    withCredentials: true,
                }).then((response) => {
                    var responseData = response.data;
                    if (responseData.PEDIDOS[0].STATUS == tp_status_pendente) {
                        this.carregaDados();
                    } else if (responseData.PEDIDOS.length > 0) {

                        global.buscar = (responseData.PEDIDOS[0].PEDIDO.BUSCAR == "1");

                        let arrStatus = this.preencheStatus(responseData);
                        STS_tentativas = 0;
                        if (responseData.PEDIDOS[0].STATUS == tp_pagamento_rest_conhecido) {
                            responseData.PEDIDOS[0].STATUS = tp_pagamento_restituido;
                        }
                        if ((this.state.statusPedido != responseData.PEDIDOS[0].STATUS) || (this.state.tempPrevTemp != responseData.PEDIDOS[0].PHE_HOR) || (this.state.tipoPrevTemp != responseData.PEDIDOS[0].PHE_TIPO)) {
                            this.setState({
                                listaStatus: arrStatus,
                                statusPedido: responseData.PEDIDOS[0].STATUS,
                                senhaPedido: responseData.PEDIDOS[0].SENHAPED,
                                tipoPrevTemp: responseData.PEDIDOS[0].PHE_TIPO,
                                tempPrevTemp: responseData.PEDIDOS[0].PHE_HOR,
                                minuPrevTemp: responseData.PEDIDOS[0].PHE_MIN,
                            });
                        } else if ((responseData.PEDIDOS[0].PHE_TIPO == 'M') && (this.state.minuPrevTemp != responseData.PEDIDOS[0].PHE_MIN)) {
                            this.setState({
                                listaStatus: arrStatus,
                                statusPedido: responseData.PEDIDOS[0].STATUS,
                                senhaPedido: responseData.PEDIDOS[0].SENHAPED,
                                tipoPrevTemp: responseData.PEDIDOS[0].PHE_TIPO,
                                tempPrevTemp: responseData.PEDIDOS[0].PHE_HOR,
                                minuPrevTemp: responseData.PEDIDOS[0].PHE_MIN,
                            });
                        } else if (this.state.listaStatus != arrStatus) {
                            this.setState({
                                listaStatus: arrStatus,
                                statusPedido: responseData.PEDIDOS[0].STATUS,
                                senhaPedido: responseData.PEDIDOS[0].SENHAPED,
                                tipoPrevTemp: responseData.PEDIDOS[0].PHE_TIPO,
                                tempPrevTemp: responseData.PEDIDOS[0].PHE_HOR,
                                minuPrevTemp: responseData.PEDIDOS[0].PHE_MIN,
                            });
                        }
                    } else {
                        BS_ALE(this, 'Atenção', 'Não foi possível consultar a loja, tente novamente mais tarde.', [{ text: 'OK', onPress: () => { } },], { cancelable: false },);
                    }

                })
                .catch((error) => {

                    if (axios.isCancel(error)) {

                        STS_tentativas = STS_tentativas + 1;
                        if (STS_tentativas > 4) {
                            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 067', [{ text: 'OK', onPress: () => { } },], { cancelable: false },);
                            STS_tentativas = 0;
                        }

                    }
                    else {
                        STS_tentativas = STS_tentativas + 1;
                        if (STS_tentativas > 4) {
                            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 068', [{ text: 'OK', onPress: () => { } },], { cancelable: false },);
                            STS_tentativas = 0;
                        }

                    }
                });
        }
    }

    carregaDados = () => {

        const cookies = new myCookies();
        var nologin_hash = global.intercept_nologin_hash;
        nologin_hash = (nologin_hash) ? nologin_hash : '';

        if ((global.myToken) || (nologin_hash != '')) {

            try {
                // here place your login logic     

                var CancelToken = axios.CancelToken;

                const { log_senha, log_usu } = this.state;

                var parametros =
                    "&partoken=" + global.myToken +
                    "&pargrures=" + global.cod_grurest +
                    "&parres=" + global.cod_restaurante +
                    "&parpedido=" + global.pedidoAtual +
                    "&nologin_hash=" + nologin_hash +
                    "&parverapp=" + '99';


                let source = CancelToken.source();
                setTimeout(() => {
                    source.cancel();
                }, global.timeOutTimer);


                axios.post(
                    global.siteurl + 'pedido_consulta_completo.php',
                    parametros,
                    {
                        cancelToken: source.token,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                        },
                        withCredentials: true,
                    }).then((response) => {
                        var responseData = response.data;

                        if (responseData.PEDIDOS.length > 0) {

                            let arrStatus = this.preencheStatus(responseData);
                            let arrPagamentos = this.preenchePagamento(responseData.PEDIDOS[0].STATUS);

                            let tmpProd = responseData.PEDIDOS[0].PRODUTOS;

                            var enderecoFinal = this.geraEndereco(responseData.PEDIDOS[0]);
                            global.buscar = responseData.PEDIDOS[0].PEDIDO.BUSCAR;

                            if (responseData.PEDIDOS[0].STATUS == tp_pagamento_rest_conhecido) {
                                responseData.PEDIDOS[0].STATUS = tp_pagamento_restituido;
                            }

                            pedidoCompOK = true;

                            this.setState({
                                showProgress: false,
                                pedidoTMP: responseData.PEDIDOS[0],
                                restaurante: responseData.PEDIDOS[0].RESTAURANTE,
                                hashPed: responseData.PEDIDOS[0].HASH,
                                valor_pedido: responseData.PEDIDOS[0].PEDIDO.VALOR_PEDIDO,
                                taxa_entrega: responseData.PEDIDOS[0].PEDIDO.TAXA_ENTREGA,
                                total_desc: responseData.PEDIDOS[0].PEDIDO.VALOR_DESCONTO,
                                total_pedido: responseData.PEDIDOS[0].PEDIDO.VALOR_RECEBER,
                                forma_pag: (responseData.PEDIDOS[0].PEDIDO.POS_PAGAMENTO == '') ? responseData.PEDIDOS[0].PEDIDO.PRE_PAGAMENTO : responseData.PEDIDOS[0].PEDIDO.POS_PAGAMENTO,
                                val_pag: (responseData.PEDIDOS[0].PEDIDO.VALOR_POS_RECEBIDO == 0) ? responseData.PEDIDOS[0].PEDIDO.VALOR_PRE_RECEBIDO : responseData.PEDIDOS[0].PEDIDO.VALOR_POS_RECEBIDO,
                                total_troco: responseData.PEDIDOS[0].PEDIDO.VALOR_TROCO,
                                pedido_data: responseData.PEDIDOS[0].PEDIDO.DATA,
                                pedido_hora: responseData.PEDIDOS[0].PEDIDO.HORA,
                                pedido_tement: responseData.PEDIDOS[0].PEDIDO.TEMPO_PEDIDO,
                                nfeDoc: responseData.PEDIDOS[0].CLIENTE.CNPJ_CPF_CUPOM,
                                codCli: responseData.PEDIDOS[0].CLIENTE.CODIGO,
                                carrinhoCompra: global.ProductObj.jsonProdPedToProductObj(tmpProd),
                                listaStatus: arrStatus,
                                FlatListPags: arrPagamentos,
                                statusPedido: responseData.PEDIDOS[0].STATUS,
                                senhaPedido: responseData.PEDIDOS[0].SENHAPED,
                                enderecoFinal: enderecoFinal,
                                observacao: responseData.PEDIDOS[0].OBSERVACAO,
                                tipoPrevTemp: responseData.PEDIDOS[0].PHE_TIPO,
                                tempPrevTemp: responseData.PEDIDOS[0].PHE_HOR,
                                minuPrevTemp: responseData.PEDIDOS[0].PHE_MIN,
                            }, () => {
                                this.forceUpdate();
                            });
                        } else {
                            pedidoCompOK = false;
                            BS_ALE(this, 'Atenção', 'Não foi possível consultar a loja, tente novamente mais tarde.');
                        }
                    })
                    .catch((error) => {

                        if (axios.isCancel(error)) {

                            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021');

                        }
                        else {
                            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022');

                        }
                    });

            } catch (err) {

            }
        } else {
            this.setState({
                loading: false,
            });
        }
    }

    verificaStatusRapido = (statusAtual) => {
        return (
            (statusAtual == tp_status_recebido) ||
            (statusAtual == tp_pagamento_pendente) ||
            (statusAtual == tp_pagamento_rejeitado)
        );
    }

    timerFunction = () => {

        if (executaTimer == false){
            return false;
        }

        var counter = 6000;

        var statusAtual = -1;

        if (this.state.statusPedido) {

            statusAtual = this.getLinhaById(this.state.listaStatus, this.state.statusPedido);

            if (!statusAtual) {
                statusAtual = this.getLinhaById(this.state.FlatListPags, this.state.statusPedido);
            }

            if (statusAtual) {
                if (statusAtual.ID == tp_status_recebido) { counter = 120000 }
                else if (statusAtual.ID == tp_status_em_preparo) { counter = 120000 }
                else if (statusAtual.ID == tp_status_saiu_entrega) { counter = 120000 }
                else if (statusAtual.ID == tp_status_entregue) { counter = 120000 }
                else if (statusAtual.ID == tp_status_concluido) { counter = 120000 }
                else if (statusAtual.ID == tp_status_recusado) { counter = 120000 }

                totalRequestsPedido += ((counter / 1000) / 60);
                this.atualizaStatus();
            }
        }

        if ((totalRequestsPedido <= 5) || (statusAtual?.ID == tp_pagamento_pendente)) {
            setTimeout(() => { this.timerFunction() }, counter);
        } else {
            this.setState({
                alertaConsultaPausada: true,
            });
        }
    }

    componentDidMount() {

        executaTimer = true;

        var parametros = 'parresid=' + this.state.usr_id + "&parverapp=" + 23;
        this.setState({ 'erroDB': false });

        this.updateDimensions();
        //window.addEventListener('scroll', this.updateDimensions);
        window.addEventListener('scroll', this.updateDimensions);

        try {

            let meuToken = localStorage.getItem(global.chaveLocalLogin);
            meuToken = meuToken;

            global.myToken = meuToken;
            this.setState({
                myToken: meuToken,
                estadoLogin: [0]
            }, () => {
                totalRequestsPedido = 0;

                if (global.pedidoAtual != null){
                    this.carregaDados();
                }else{
                    this.props.navigate('pedido-lista');
                }               

                setTimeout(() => { this.timerFunction() }, 6000);

                /*this.interval = setInterval(() => {
                  //this.atualizaStatus();
                  
                  pedidoCompOK = false;
                }, (pedidoCompOK) ? 6000 : 120000); //6 seconds*/
            });

        } catch (err) {
            //BS_ALE(this,  '', 'Não foi possível atualizar as informações, tente novamente mais tarde', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
            this.setState({ 'erroDB': true });
        }
        //this.verificaAtt();

        this.setScrollToTop();

        if (window.visualViewport) {
            this.fullWindowHeight = window.visualViewport.height;
            window.visualViewport.addEventListener('resize', this.handleResize);
        } else {
            this.fullWindowHeight = window.innerHeight;
        }

    }

    handleResize = () => {
        var fullWindowHeight = this.fullWindowHeight;
        if (window.visualViewport.height == fullWindowHeight) {
            if (this.keyboardIsProbablyOpen) {
                this.keyboardIsProbablyOpen = false;
            }
        } else if (window.visualViewport.height < fullWindowHeight * 0.9) {
            if (!this.keyboardIsProbablyOpen) {
                setTimeout(() => {
                    window.scrollTo(0, 5000);
                }, 500);
                this.keyboardIsProbablyOpen = true;
            }
        }
    }
    componentWillUnmount = () => {

        executaTimer = false;

        window.removeEventListener('scroll', this.updateDimensions);

        if (window.visualViewport) {
            window.visualViewport.removeEventListener("resize", this.handleResize);
        }

    }

    updateDimensions() {
        var alturaScroll = document.body.scrollHeight - window.innerHeight;
        var scrolledY = window.scrollY;

        var alturaItems = 0;

        this.arrPosItems.map((item, index) => {
            alturaItems += (item) ? item.clientHeight : 0;
        });

        var proporcao = alturaItems / (alturaScroll);

        var tmpPos = 0;
        var itemAtual = 0;

        for (var i = 0; i < this.arrPosItems.length; i++) {
            var tmpItem = this.arrPosItems[i];
            tmpPos += (tmpItem) ? tmpItem.clientHeight : 0;

            if ((scrolledY * proporcao) < (((this.cabecalho) ? tmpPos + (this.cabecalho.clientHeight) : tmpPos) - this.state.scrollMarginTop)) {
                break;
            } else {
                itemAtual = (i >= (this.ultElementMenu)) ? this.ultElementMenu : i + 1;
            }
        }

        if (this.state.posItemClicado == -1) {
            if (itemAtual !== this.state.itemAtivo) {
                this.setState({ itemAtivo: itemAtual });

                if (itemAtual > 0) {
                    this.myMenu.scrollTo({ left: this.arrMenuItems[itemAtual].offsetLeft - 30, behavior: 'smooth' })
                } else {
                    this.myMenu.scrollTo({ left: 0, behavior: 'smooth' })
                }
            }
        } else if (scrolledY == this.state.posItemClicado) {

            if (this.state.eventClicado == -1) {
                if (itemAtual !== this.state.itemAtivo) {
                    this.setState({ itemAtivo: itemAtual });
                }

                if (itemAtual > 0) {
                    this.myMenu.scrollTo({ left: this.arrMenuItems[itemAtual].offsetLeft - 30, behavior: 'smooth' })
                } else {
                    this.myMenu.scrollTo({ left: 0, behavior: 'smooth' })
                }
                this.setState({
                    posItemClicado: -1,
                });
            } else {
                this.myMenu.scrollTo({ left: this.arrMenuItems[this.state.eventClicado].offsetLeft - 30, behavior: 'smooth' })
                this.setState({
                    itemAtivo: this.state.eventClicado,
                    posItemClicado: -1,
                    eventClicado: -1,
                });
            }
        } else {
            if (itemAtual !== this.state.itemAtivo) {
                this.setState({ itemAtivo: itemAtual });
            }
        }


    }

    atualizaPadraoDB = async (parametros, metodo, tabelaNome, arrLista) => {

        try {

            var CancelToken = axios.CancelToken;


            let source = CancelToken.source();
            setTimeout(() => {
                source.cancel();

            }, 20000);


            await axios.post(
                global.urlServer + metodo,
                parametros,
                {
                    cancelToken: source.token,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                    }
                }).then((response) => {
                    var responseData = response.data;
                    if (responseData.resposta == '1') {
                        arrLista = JSON.parse(JSON.stringify(responseData.registros));

                        var arrFinal = [];

                        if (arrLista) {
                            arrLista.map((prop, key) => {
                                arrFinal.push({
                                    key: key,
                                    codInterno: prop.idGrupo,
                                    nome: prop.nome,
                                    precoDelivery: prop.posicao,
                                    descricao: global.urlServer + 'imgs/' + this.state.usr_grures + '/' + prop.cor + '.png',
                                    image: global.urlServer + 'imgs/' + this.state.usr_grures + '/' + prop.cor + '.png',
                                });
                            });
                        }



                        this.setState({
                            listaGruPro: arrFinal,
                        });
                    } else {
                        this.setState({
                            erroDB: true,
                        });
                    }
                })
                .catch((error) => {
                    if (error == '785936') {

                    } else if (axios.isCancel(error)) {

                    } else {


                    }
                });
        } catch (err) {

        }
    }

    clickProd(item) {
        alert('Hello: ' + item.nome);
    }

    scrollToItem = (index) => {
        //alert('Hello: ' + index);
        //this.arrPosItems[index].scrollIntoView({behavior: 'smooth'});

        var offsetNextScroll = 80;

        var alturaScroll = document.body.scrollHeight - window.innerHeight - this.state.scrollMarginTop;
        var scrolledY = window.scrollY;

        var alturaItems = 0;

        this.arrPosItems.map((item, index) => {
            alturaItems += (item) ? item.clientHeight : 0;
        });

        var proporcao = (alturaItems) / alturaScroll;

        var posicaoItem = 0;

        this.arrPosItems.map((item, itemIndex) => {
            if (itemIndex == index) {
                posicaoItem = (item) ? ((item.offsetTop + this.cardBody.offsetTop) - this.state.scrollMarginTop) : 0;
            }
        });

        /*for (var i = 0; i < (index); i++){
            var tmpItem = this.arrPosItems[i];
            posicaoItem += (tmpItem) ? tmpItem.clientHeight : 0;
        }

        posicaoItem -= this.state.scrollMarginTop;
        posicaoItem += this.cabecalho.clientHeight;
        posicaoItem = posicaoItem / proporcao;
        posicaoItem += offsetNextScroll;
        posicaoItem = Math.round(posicaoItem);*/

        window.scrollTo({ top: posicaoItem, behavior: 'smooth' });

        this.setState({
            posItemClicado: posicaoItem,
            eventClicado: index,
        });

    }

    renderMenu = (items) => {
        const { classes } = this.props;
        var resultado = null;

        return items.map((item, index) => {

            if (item == null) {
                return (
                    <div ref={(ref) => this.arrMenuItems[index] = ref} style={{ marginRight: '10px', display: 'inline-block', width: '125px', height: '30px' }} className={'my-loading'}></div>
                ); // my-loading
            }

            var prodArrFinal = [];
            var primeiraCateg = true;
            item.produtos.filter(produto => (this.comparaTexto(produto.nomeProduto, this.state.pesquisaTxt) || this.comparaTexto(produto.descricao, this.state.pesquisaTxt))).map(
                filteredProduto => prodArrFinal.push(filteredProduto)
            );

            if (prodArrFinal.length > 0) {
                if (index == this.state.itemAtivo) {
                    return (
                        <div ref={(ref) => this.arrMenuItems[index] = ref} style={{ marginRight: '10px', display: 'inline-block', }}>
                            <a onClick={() => { this.scrollToItem(index) }} style={{ fontSize: '15px', fontWeight: 'bold', color: 'black' }}>{item.nome}</a>
                            <div style={{ marginTop: '4px', backgroundColor: '#f00' }} className={classes.separatorMenu}></div>
                        </div>
                    );
                } else {
                    return (
                        <div ref={(ref) => this.arrMenuItems[index] = ref} style={{ marginRight: '10px', display: 'inline-block', }}>
                            <a onClick={() => { this.scrollToItem(index) }} style={{ fontSize: '15px', fontWeight: 'bold', color: 'black' }}>{item.nome}</a>
                        </div>
                    );
                }

            } else {
                return (
                    <div style={{ display: 'inline-block', }} ref={(ref) => this.arrMenuItems[index] = ref}>

                    </div>
                );
            }

        });
    }

    selectProd = (prod, grupoProd) => {

        

        global.ProductObj.setQtdeSabores(1, grupoProd, grupoProd.codigo, grupoProd.nome);
        global.ProductObj.setSabor(prod);
        this.props.navigate("/selecionaprod-seleciona-sabor");

    }

    selecionaQtdSabor = (qtdeSabore, grupoProd) => {

        

        global.ProductObj.setQtdeSabores(qtdeSabore, grupoProd, grupoProd.codigo, grupoProd.nome);
        this.props.navigate("/selecionaprod-seleciona-sabor");

    }

    renderQtdeSabores = (grupoProd) => {
        const { classes } = this.props;

        return (
            <div>
                <div onClick={(e) => this.selecionaQtdSabor(1, grupoProd)}>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '15px' }}>
                        <div>
                            <div>
                                <text style={{ fontSize: '18px', fontWeight: 'bold' }}>{grupoProd.nome} - 1 Sabor</text>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '10px' }} className={classes.separator}></div>
                </div>
                <div onClick={(e) => this.selecionaQtdSabor(2, grupoProd)}>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '15px' }}>
                        <div>
                            <div>
                                <text style={{ fontSize: '18px', fontWeight: 'bold' }}>{grupoProd.nome} - 2 Sabores</text>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '10px' }} className={classes.separator}></div>
                </div>
            </div>
        );
    }

    renderProds = (grupoProd, prodArrFinal) => {
        const { classes } = this.props;

        return prodArrFinal.map((prod, index) => {
            return (
                <div onClick={() => { this.selectProd(prod, grupoProd) }}>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: '15px' }}>
                        <div>
                            <div>
                                <text style={{ fontSize: '16px', fontWeight: 'bold' }}>{prod.nomeProduto}</text>
                            </div>
                            <div>
                                <text style={{ display: 'inline-block', fontSize: '13px', lineHeight: '18px' }}>{prod.descricao}</text>
                            </div>
                            <div>
                                <text style={{ fontSize: '16px', fontWeight: 'bold', color: '#777' }}>R$ {prod.preco.replace('.', ',')}</text>
                            </div>
                        </div>
                        <div style={{ flex: 1, minWidth: '100px', height: '80px', display: "flex", justifyContent: "flex-end" }}>
                            <img src="https://images.rappi.com.br/products/855fef0d-b498-4e0c-b0dd-228eb443237b-1602546044927.png?d=128x90" style={{ width: '80px', height: "80px" }}></img>
                        </div>
                    </div>
                    <div style={{ marginTop: '10px' }} className={classes.separator}></div>
                </div>
            );
        });
    }

    verificaGrupoProd = (grupoProd, prodArrFinal) => {
        if (grupoProd.misto > 1) {
            return this.renderQtdeSabores(grupoProd);
        } else {
            return this.renderProds(grupoProd, prodArrFinal);
        }
    }

    pesquisaLista = (value) => {
        window.scrollTo({ top: 0, behavior: 'auto' });
        global.ProductObj.pesquisaProd = value;
        this.setState({ pesquisaTxt: value }, () => {
            this.updateDimensions();
        });
    }

    abrePesquisa = (pesquisaAberta) => {
        if (pesquisaAberta) {
            this.setState({
                scrollMarginTop: 170
            });
        } else {
            this.setState({
                scrollMarginTop: 120
            });
        }
    }

    comparaTexto(val1, val2) {

        var arrPalavras = val2.split(' ');

        var resultado = true;
        arrPalavras.map((palavra, index) => {
            if (!this.trataTexto(val1).includes(this.trataTexto(palavra))) {
                resultado = false;
            }
        });

        return resultado;
    }

    trataTexto(val) {
        return (
            val.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        );
    }

    renderHeader = () => {

        return (
            <div style={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center', fontSize: '20px', }}>
                <IconButton
                    color="inherit"
                    style={{ padding: '7px' }}
                    onClick={() => {
                        
                        this.props.navigate('pedido-lista');
                    }}
                >
                    <ArrowBack />
                </IconButton>
                {"Pedido " + global.pedidoAtual}
            </div>
        );
    }

    renderPedidos = () => {
        return this.state.listaPedidos.map((value, index) => {
            return this.renderPedido(value);
        });
    }

    getLinhaById = (array, id) => {
        return array.find((element) => {
            return element.ID == id;
        })
    }

    renderPedido = (item) => {

        let statusAtual = this.getLinhaById(this.state.listaStatus, item.STATUS);

        if (statusAtual.ID == tp_status_pendente) {
            return (
                this.renderPedidoObs(item, statusAtual)
            );
        } else if ((statusAtual.ID >= tp_pagamento_pendente) && (statusAtual.ID <= tp_pagamento_expirado)) {
            return (
                this.renderPedidoObs(item, statusAtual)
            );
        } else {
            return (
                this.renderPedidoBasico(item, statusAtual)
            );
        }

    }

    renderPedidoBasico = (item, statusAtual) => {

        var dataPedido = item.PEDIDO.DATA.substr(8, 2) + '/' + item.PEDIDO.DATA.substr(5, 2) + '/' + item.PEDIDO.DATA.substr(0, 4) +
            ' ' + item.PEDIDO.HORA;

        return (
            <div style={{ marginLeft: '0%', borderColor: '#d4d4d4', borderBottomWidth: 1, width: '100%', display: 'flex', flexDirection: 'row' }}>
                <div style={{ backgroundColor: '#', width: '70%', paddingTop: '3%', marginLeft: '3%', paddingBottom: '3%' }}>
                    <div style={{ color: '#000', fontSize: 16, fontWeight: 'bold', marginBottom: 8, }}>PEDIDO {item.CODIGO_WEB}</div>
                    <text style={{ color: '#000', }}>{dataPedido}</text>
                </div>

                <div style={{ backgroundColor: '#', width: '30%', justifyContent: 'center', marginRight: '3%', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Icon className={"fa fa-" + statusAtual.Icone} style={{ fontSize: 24, color: "#c00", textAlignVertical: 'center', textAlign: 'center', }} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <text style={{ color: '#000', width: '90%', fontSize: 15, textAlign: 'center', }}>{statusAtual.Status}</text>
                    </div>
                </div>
            </div>
        );

    }

    renderPedidoObs = (item, statusAtual) => {
        //const isSelected = (this.state.itemSelecionado === item.CODIGO_WEB);

        //const corFundo  = isSelected ? global.corSelecao : global.corFundo;
        //const corFundo2 = isSelected ? global.corSelecao : global.corFundo;
        //const corFonte  = isSelected ? global.corFonte : global.corFonte;

        return (
            <div style={{ marginLeft: '0%', borderColor: '#d4d4d4', borderBottomWidth: 1, width: '100%', display: 'flex', flexDirection: 'row' }}>
                <div style={{ backgroundColor: '#', width: '70%', paddingTop: '3%', marginLeft: '3%', paddingBottom: '3%' }}>
                    <div style={{ color: '#000', fontSize: 16, fontWeight: 'bold', marginBottom: 8, }}>PEDIDO {item.CODIGO_WEB}</div>
                    <div style={{ color: '#000', fontWeight: 'bold', }}>{statusAtual.Mensagem}</div>
                </div>

                <div style={{ backgroundColor: '#', width: '30%', justifyContent: 'center', marginRight: '3%', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Icon className={"fa fa-" + statusAtual.Icone} style={{ fontSize: 24, color: "#c00", textAlignVertical: 'center', textAlign: 'center', }} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <text style={{ color: '#000', width: '90%', fontSize: 15, textAlign: 'center', }}>{statusAtual.Status}</text>
                    </div>
                </div>
            </div>
        );

        /*
        var dataPedido  =    item.PEDIDO.DATA.substr(8, 2) + '/' + item.PEDIDO.DATA.substr(5, 2) + '/' + item.PEDIDO.DATA.substr(0, 4) +
                            ' ' + item.PEDIDO.HORA;
    
        if (statusAtual.ID == tp_status_pendente){
          return (
            <div>
                <text style={{color: corFonte, fontSize: 16, fontWeight: 'bold', marginBottom: 8,}}>PEDIDO {item.CODIGO_WEB}</text>
                <text style={{color: corFonte, fontWeight: 'bold', }}>{statusAtual.Mensagem}</text>
            </div>
          );  
        }
        
        if ((statusAtual.ID >= tp_pagamento_pendente) && (statusAtual.ID <= tp_pagamento_expirado)){
          return (
            <TouchableWithoutFeedback 
              onPress={() => this.selectItem(item)}
            >
              <View key={item.CODIGO_WEB} style={{marginLeft:'0%', borderColor:'#d4d4d4', borderBottomWidth: 1, width:'100%',}}>
                <LinearGradient start={{x: 0, y: 0}} end={{x: 0, y: 1}} colors={[corFundo, corFundo2]} style={{width: '100%', flexDirection: 'row'}}>
                  <View style={{backgroundColor:'#', width: '60%', paddingTop: '3%', marginLeft: '3%', paddingBottom: '3%'}}>
                        <Text style={{color: corFonte, fontSize: 16, fontWeight: 'bold', marginBottom: 8,}}>PEDIDO {item.CODIGO_WEB}</Text>
                        <Text style={{color: corFonte, fontWeight: 'bold', }}>{statusAtual.Mensagem}</Text>
                    </View>
      
                    <View style={{backgroundColor:'#', width: '40%', justifyContent: 'center', marginRight: '3%', alignItems: 'center', }}>
                      <Icon type={statusAtual.Fonte} name={statusAtual.Icone} style={{ paddingTop: 5, fontSize: 24, color:global.rgRetirar, textAlignVertical: 'center', textAlign: 'center', }}/>    
                      <Text style={{color: corFonte, width: '90%', fontSize: 15, textAlign: 'center', }}>{statusAtual.Status}</Text>
                    </View>                                   
                </LinearGradient>          
              </View>  
            </TouchableWithoutFeedback >
          );  
        }
    
        return (
          <TouchableWithoutFeedback 
            onPress={() => this.selectItem(item)}
          >
            <View key={item.CODIGO_WEB} style={{marginLeft:'0%', borderColor:'#d4d4d4', borderBottomWidth: 1, width:'100%',}}>
              <LinearGradient start={{x: 0, y: 0}} end={{x: 0, y: 1}} colors={[corFundo, corFundo2]} style={{width: '100%', flexDirection: 'row'}}>
                <View style={{backgroundColor:'#', width: '60%', paddingTop: '3%', marginLeft: '3%', paddingBottom: '3%'}}>
                      <Text style={{color: corFonte, fontSize: 16, fontWeight: 'bold', marginBottom: 8,}}>PEDIDO {item.CODIGO_WEB}</Text>
                      <Text style={{color: corFonte,}}>{dataPedido}</Text>
                  </View>
    
                  <View style={{backgroundColor:'#', width: '40%', justifyContent: 'center', marginRight: '3%', alignItems: 'center', }}>
                    <Icon type={statusAtual.Fonte} name={statusAtual.Icone} style={{ paddingTop: 5, fontSize: 18, color:global.rgFundoFont, textAlignVertical: 'center', textAlign: 'center', }}/>    
                    <Text style={{color: corFonte, fontSize: 14, textAlign: 'center', }}>{statusAtual.Status}</Text>
                  </View>                                   
              </LinearGradient>          
            </View>  
          </TouchableWithoutFeedback >
        );*/
    }

    renderListaPedidos = () => {

        const { classes } = this.props;

        if (this.state.listaPedidos) {
            return (

                <div style={{ scrollMarginTop: (this.state.scrollMarginTop + "px"), maxWidth: '100%', }}>
                    <div>
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'column', }}>
                                {this.renderPedidos()}
                            </div>
                        </div>
                    </div>
                </div>

            );
        }
    }

    _renderTempoEntrega = () => {

        const corFonte = global.rgFundoFont;

        if (this.state.tipoPrevTemp == 'P') {
            return (
                <text style={{ color: corFonte, fontWeight: 'normal', }}>
                    Entrega prevista para: <text style={{ color: global.rgFundoFont, fontWeight: 'bold' }}>{this.state.tempPrevTemp}</text>
                </text>
            );
        } else if (this.state.tipoPrevTemp == 'M') {
            if (this.state.minuPrevTemp > 0) {
                return (
                    <text style={{ color: corFonte, fontWeight: 'normal', }}>
                        {
                            ((global.buscar) ? "Tempo estimado para retirada: " : "Tempo estimado para entrega: ")
                        }
                        <text style={{ color: global.rgFundoFont, fontWeight: 'bold' }}>{this.state.minuPrevTemp + " minutos"}</text>
                    </text>
                );
            }
        } else if ((this.state.pedido_tement.length > 0) && (this.state.pedido_tement > 0)) {
            return (
                <text style={{ color: corFonte, fontWeight: 'normal', }}>
                    {
                        ((global.buscar) ? "Tempo estimado para retirada: " : "Tempo estimado para entrega: ")
                    }
                    <text style={{ color: global.rgFundoFont, fontWeight: 'bold' }}>{this.state.pedido_tement + " minutos"}</text>
                </text>
            );
        }
    }

    renderLista = (items) => {
        const { classes } = this.props;
        var resultado = null;

        return items.map((item, index) => {

            var sabor = item.sabores;
            var qtde = item.qtde;

            //var valorTot = (qtde * item.valorUnit).toFixed(2).replace('.', ',');
            var valorTot = formatarNumero((qtde * item.valorUnit), 2);

            var saboresNome = "";

            var unidade = "";

            item.sabores.map((value, index) => {

                var prod = value.produto;

                saboresNome += (saboresNome.length > 0) ? ' ' : '';

                saboresNome += ((item.qtdeSabores > 1) ? '1/' + item.qtdeSabores + ' ' : '') + prod.nomeProduto;

                unidade = " " + value.produto.unidade;
            });//

            var tmpQtde = parseFloat(qtde);
            var strQtde = ((tmpQtde % 1 != 0) ? formatarNumero(tmpQtde, 3).replace(',', '.') : tmpQtde.toString()).replace('.', ',');

            var itemSelecionado = (item.checkedFidelidade >= 1 && !this.state.alertaProgramaFidelidade);

            var valorTotFidelidade = formatarNumero(((item.valorUnit - item.valorDescFidelidade) * qtde), 2);

            return (
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', margin: '5px 0px' }} onClick={() => {
                    this.abreDetalhesProd(item, index);
                }}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '58%', display: 'flex', flexDirection: 'column' }}>
                            <text style={{ fontSize: '16px', color: '#000', fontWeight: '420' }} >{saboresNome}</text>
                            <text style={{ fontSize: '16px', color: '#888', fontWeight: 'normal' }} >{strQtde + unidade + " x " + item.valorUnit.replace('.', ',')}</text>
                        </div>
                        <div style={{ width: '42%' }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ flex: '1' }}></div>
                                <div style={{ fontSize: '12px', fontWeight: '420', marginBottom: 'auto', marginTop: '0px', marginLeft: 'auto', marginRight: '5px', width: 'fit-content', textAlign: 'right', textDecoration: (itemSelecionado) ? 'line-through' : '' }}>{(itemSelecionado) ? 'R$ ' + valorTot : ''}</div>
                                <div style={{ fontSize: '17px', fontWeight: '420', marginBottom: 'auto', marginTop: '0px', marginLeft: 'auto', marginRight: '5px', width: 'fit-content', textAlign: 'right', color: (itemSelecionado) ? '#349449' : '' }}>R$ {valorTotFidelidade}</div>
                            </div>
                        </div>
                    </div>
                </div>
            );


        });
    }

    renderCarrinho = () => {

        const { classes } = this.props;

        return (

            <div style={{ scrollMarginTop: (this.state.scrollMarginTop + "px"), maxWidth: '100%', }}>
                <div>
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'column', }}>
                            {this.renderLista(this.state.carrinhoCompra)}
                        </div>
                    </div>
                </div>
            </div>

        );
    }

    trataListaPags() {
        if ((this.state.valor_pedido)
            && (this.state.taxa_entrega)
            && (this.state.total_pedido)
            && (this.state.total_desc)
            && (this.state.val_pag)
            && (this.state.total_troco)) {
            return this.listaPags();
        }
    }

    renderTroco(total_troco) {

        if (this.state.total_troco > 0) {
            return (
                <div style={{
                    backgroundColor: "#", width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                    marginTop: 3, marginBottom: 3, paddingTop: 6, paddingBottom: 6, display: 'flex',
                }}>
                    <text style={{ width: '60%', textAlign: 'left', fontSize: '14px', color: '#bbb', fontWeight: '400', }}>Troco</text>
                    <text style={{ width: '40%', textAlign: 'right', fontSize: '14px', color: '#bbb', fontWeight: '400', }}>{total_troco}</text>
                </div>
            );
        }

    }

    listaPags() {

        var prod_val = this.state.valor_pedido;
        prod_val = ((prod_val.trim() == "0.00") || (prod_val.trim() == "0,00")) ? "0,00" : prod_val.replace('.', ',');
        prod_val = 'R$ ' + prod_val;

        var taxa_ent = this.state.taxa_entrega;
        taxa_ent = ((taxa_ent.trim() == "0.00") || (taxa_ent.trim() == "0,00")) ? "0,00" : taxa_ent.replace('.', ',');
        taxa_ent = 'R$ ' + taxa_ent;

        var total_ped = this.state.total_pedido;
        total_ped = ((total_ped.trim() == "0.00") || (total_ped.trim() == "0,00")) ? "0,00" : total_ped.replace('.', ',');
        total_ped = 'R$ ' + total_ped;

        var desconto = this.state.total_desc;
        var strDesc = this.state.total_desc;
        strDesc = ((strDesc.trim() == "0.00") || (strDesc.trim() == "0,00")) ? "0,00" : strDesc.replace('.', ',');
        strDesc = 'R$ ' + strDesc;

        var formaPag = this.state.forma_pag;

        var pagamento_val = this.state.val_pag
        pagamento_val = ((pagamento_val.trim() == "0.00") || (pagamento_val.trim() == "0,00")) ? "0,00" : pagamento_val.replace('.', ',');
        pagamento_val = 'R$ ' + pagamento_val;

        var total_troco = this.state.total_troco
        total_troco = ((total_troco.trim() == "0.00") || (total_troco.trim() == "0,00")) ? "0,00" : total_troco.replace('.', ',');
        total_troco = 'R$ ' + total_troco;

        return (
            <div style={{ backgroundColor: "#", width: '100%', }}>
                <div style={{
                    backgroundColor: "#", width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                    margin: '0px 0px', display: 'flex',
                }}>
                    <text style={{ width: '60%', textAlign: 'left', fontSize: '14px', color: '#bbb', fontWeight: '400', }}>Total dos Produtos</text>
                    <text style={{ width: '40%', textAlign: 'right', fontSize: '14px', color: '#bbb', fontWeight: '400', }}>{prod_val}</text>
                </div>
                <div style={{
                    backgroundColor: "#", width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                    margin: '0px 0px', display: 'flex',
                }}>
                    <text style={{ width: '60%', textAlign: 'left', fontSize: '14px', color: '#bbb', fontWeight: '400', }}>Taxa de Entrega</text>
                    <text style={{ width: '40%', textAlign: 'right', fontSize: '14px', color: '#bbb', fontWeight: '400', }}>{taxa_ent}</text>
                </div>
                {(desconto > 0) ?
                    <div style={{
                        backgroundColor: "#", width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                        margin: '0px 0px', display: 'flex',
                    }}>
                        <text style={{ width: '60%', textAlign: 'left', fontSize: '14px', color: '#bbb', fontWeight: '400', }}>Desconto</text>
                        <text style={{ width: '40%', textAlign: 'right', fontSize: '14px', color: '#bbb', fontWeight: '400', }}>- {strDesc}</text>
                    </div>
                    : null}
                <div style={{
                    backgroundColor: "#", width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                    marginTop: 3, marginBottom: 3, paddingTop: 6, paddingBottom: 6, display: 'flex',
                }}>
                    <text style={{ width: '60%', textAlign: 'left', fontSize: '17px', color: '#555', fontWeight: '600', }}>Total do Pedido</text>
                    <text style={{ width: '40%', textAlign: 'right', fontSize: '17px', color: '#555', fontWeight: '600', }}>{total_ped}</text>
                </div>
                <div style={{
                    backgroundColor: "#", width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                    marginTop: 3, marginBottom: 3, paddingTop: 6, paddingBottom: 6, display: 'flex',
                }}>
                    <text style={{ width: '60%', textAlign: 'left', fontSize: '14px', color: '#bbb', fontWeight: '400', }}>Total Pago - {formaPag}</text>
                    <text style={{ width: '40%', textAlign: 'right', fontSize: '14px', color: '#bbb', fontWeight: '400', }}>{pagamento_val}</text>
                </div>
                {this.renderTroco(total_troco)}
            </div>
        );
    }

    renderProdutos() {
        return (
            this.renderCarrinho()
        );
    }

    _renderRest = () => {

        var item = this.state.restaurante;

        const corFundo = global.rgFundo;
        const corFundo2 = global.rgFundo;
        const corFonte = global.rgFundoFont;

        if (item) {
            if (item.ENDERECO) {

                var endereco = global.restEndereco.replace(/\n|\r/g, " ");
                endereco = endereco.replace("  ", " ");

                return (
                    <div style={{ marginLeft: '0%', borderColor: '#d4d4d4', borderBottomWidth: 1, width: '100%', }}>
                        <div style={{ width: '100%', flexDirection: 'row' }}>
                            <div style={{ backgroundColor: global.rgFundo, width: '100%', padding: '10px', display: 'flex', flexDirection: 'column' }}>
                                <text style={{ color: corFonte, fontSize: 16, fontWeight: 'bold', }}>{item.NOME}</text>
                                <text style={{ color: corFonte, }}>{endereco}</text>
                                <text style={{ color: corFonte, fontSize: 14, fontWeight: 'bold', marginBottom: 8, }}>{item.TELEFONE}</text>
                                <text style={{ color: corFonte, fontWeight: 'normal', }}>Pedido realizado em: {this.state.pedido_data + ' ' + this.state.pedido_hora}</text>
                                {this._renderTempoEntrega()}
                            </div>
                        </div>
                    </div>

                );
            }
        }

        return (
            <div>
            </div>
        );
    }

    geraMensagemExtra = () => {
        if ((global.buscar) && (global.msg_retirada.length > 0)) {
            return (
                <div style={{ width: '100%', padding: 10, paddingTop: 0, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                    <text style={{ color: global.rgRetirar, fontWeight: 'bold', textAlign: 'center', fontSize: 18, width: '100%' }}>{global.msg_retirada}</text>
                </div>

            );
        }
    }

    geraSenha = () => {
        if (this.state.senhaPedido.length > 0) {
            return (
                <div style={{ width: '99%', padding: 10, paddingTop: 0, justifyContent: 'center', alignItems: 'center', }}>
                    <div style={{ width: '99%', padding: '10px', borderRadius: 10, justifyContent: 'center', alignContent: 'center', flexDirection: 'row', background: 'linear-gradient(' + global.rgBarraInfo + ', ' + global.rgBarraInfoEscuro + ')' }}>
                        <div style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', alignContent: 'center', display: 'flex', flexDirection: 'row' }}>
                            <text style={{ color: global.rgFundoFont, textAlign: 'left', fontSize: 16, width: '75%', fontWeight: '400', }}>Senha para retirada do pedido</text>
                            <text style={{ color: global.rgFundoFont, height: '100%', textAlign: 'right', textAlignVertical: 'center', fontWeight: 'bold', fontSize: 24, width: '25%' }}>{this.state.senhaPedido}</text>
                        </div>
                    </div>
                </div>

            );
        }

    }

    renderPagamento = () => {

        const { classes } = this.props;

        if ((this.state.statusPedido == tp_pagamento_rejeitado) || (this.state.statusPedido == tp_pagamento_processando) || (this.state.statusPedido == tp_pagamento_pendente)) {
            return (
                <div>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => {

                            this.carregaFormasPag();
                        }}
                        style={{ backgroundColor: '#3496eb', marginTop: '15px' }}
                    >
                        Refazer pagamento
                    </Button>
                </div>
            )
        }
    }

    geraStatus = () => {

        var statusAtual = 0;

        for (var i = 0; i < this.state.listaStatus.length; i++) {

            if (this.state.listaStatus[i].ID == this.state.statusPedido) statusAtual = i;

        }

        if (this.state.listaStatus.length > 0) {
            var itemAtual = this.state.listaStatus[statusAtual];

            if (this.state.statusPedido == tp_status_pendente) {
                return (
                    <div style={{ width: '100%', padding: 10, backgroundColor: '#', justifyContent: 'center', alignItems: 'center', }}>
                        <div style={{ borderTopRightRadius: 10, borderTopLeftRadius: 10, justifyContent: 'center', alignContent: 'center', flexDirection: 'row', width: '100%', paddingTop: 10, paddingBottom: 10, background: 'linear-gradient(to right bottom, #ffc72c, #ffa02c)' }}>
                            <div style={{ backgroundColor: '#', width: '100%', justifyContent: 'center', alignContent: 'center', }}>
                                <text style={{ color: global.rgBarraInfoFont, fontWeight: 'bold', fontSize: 18, width: '100%' }}>Pedido pendente</text>
                                <text style={{ color: global.rgBarraInfoFont, fontSize: 16, width: '100%' }}>O pedido necessita de SUA CONFIRMAÇÃO para ser enviado, clique abaixo para confirmar</text>
                            </div>
                        </div>
                        <div style={{ width: '100%', backgroundColor: global.rgBarraSup, flexDirection: 'row', height: 50, justifyContent: 'center', alignItems: 'center', }}>
                            <text style={{ color: global.rgBarraSupFont, fontWeight: 'bold', textDecorationLine: 'underline', textAlign: 'center', fontSize: 18, width: '100%', }}>Clique aqui para confirmar o pedido</text>
                        </div>
                        <div style={{ width: '100%', backgroundColor: global.rgBarraSup, flexDirection: 'row', height: 50, justifyContent: 'center', alignItems: 'center', }}>
                            <text style={{ color: global.rgBarraSupFont, fontWeight: 'bold', textAlign: 'center', fontSize: 18, width: '100%', textDecorationLine: 'underline' }}>Descartar esse pedido</text>
                        </div>
                    </div>

                );
            }


            if ((this.state.statusPedido <= tp_pagamento_expirado) && (this.state.statusPedido >= tp_pagamento_pendente)) {

                statusAtual = 0;

                for (i = 0; i < this.state.FlatListPags.length; i++) {

                    if (this.state.FlatListPags[i].ID == this.state.statusPedido) statusAtual = i;

                }

                itemAtual = this.state.FlatListPags[statusAtual];
                return (
                    <div style={{ width: '100%', padding: 10, backgroundColor: '#', justifyContent: 'center', alignItems: 'center', }}>
                        <div style={{ width: '100%', flexDirection: 'row', height: 50, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                            {this.state.FlatListPags.map((item) => {

                                if (this.state.statusPedido == tp_pagamento_expirado) {
                                    var cor1 = (item.ID == tp_pagamento_mediacao) ? global.corDegrade01 : global.rgBarraSubInfo;
                                    var cor2 = (item.ID == tp_pagamento_mediacao) ? global.corDegrade01 : global.rgBarraSubInfo;

                                    var corIcone = (item.ID == tp_pagamento_mediacao) ? "#400000" : "#400000";
                                } else {
                                    var cor1 = (item.ID == this.state.statusPedido) ? global.corDegrade01 : global.rgBarraSubInfo;
                                    var cor2 = (item.ID == this.state.statusPedido) ? global.corDegrade01 : global.rgBarraSubInfo;

                                    var corIcone = (item.ID == this.state.statusPedido) ? "#400000" : "#400000";
                                }

                                if (item.ID != tp_pagamento_expirado) {
                                    return (
                                        <div style={{ borderTopLeftRadius: 7, borderTopRightRadius: 7, justifyContent: 'center', alignContent: 'center', alignItems: 'center', flexDirection: 'row', width: '19.425%', height: '100%', background: 'linear-gradient(to right bottom, ' + cor1 + ', ' + cor2 + ')', display: 'flex' }}>
                                            <Icon className={"fa fas fa-" + item.Icone} style={{ fontSize: 22, color: corIcone, width: 'auto', height: 'auto', textAlignVertical: 'center', textAlign: 'center', }} />
                                        </div>
                                    );
                                }

                            })}
                        </div>
                        <div style={{ borderBottomRightRadius: 10, borderBottomLeftRadius: 10, justifyContent: 'center', alignContent: 'center', flexDirection: 'row', width: '100%', background: 'linear-gradient(' + global.corDegrade01 + ', ' + global.corDegrade02 + ')' }}>
                            <div style={{ backgroundColor: '#', width: '100%', justifyContent: 'center', alignContent: 'center', display: 'flex', flexDirection: 'column', padding: '10px' }}>
                                <text style={{ color: global.rgBarraInfoFont, fontWeight: 'bold', fontSize: 18, width: '100%' }}>{itemAtual.Status}</text>
                                <text style={{ color: global.rgBarraInfoFont, fontWeight: '400', fontSize: 16, width: '100%' }}>{itemAtual.Mensagem}</text>
                                {((this.state.statusPedido == tp_status_recusado) ? <text style={{ color: global.rgBarraInfoFont, fontSize: 16, fontWeight: 'bold' }}>{global.tel_restaurante}</text> : null)}
                            </div>
                        </div>
                        {this.renderPagamento()}
                    </div>

                );
            }

            return (
                <div style={{ width: '100%', padding: 10, backgroundColor: '#', justifyContent: 'center', alignItems: 'center', }}>
                    <div style={{ width: '100%', flexDirection: 'row', height: 50, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                        {this.state.listaStatus.map((item) => {
                            const cor1 = (item.ID == this.state.statusPedido) ? global.corDegrade01 : global.rgBarraSubInfo;
                            const cor2 = (item.ID == this.state.statusPedido) ? global.corDegrade01 : global.rgBarraSubInfo;

                            const corIcone = (item.ID == this.state.statusPedido) ? global.rgBarraInfoFont : global.rgBarraSubInfoFont;

                            return (
                                <div style={{ borderTopLeftRadius: 7, borderTopRightRadius: 7, justifyContent: 'center', alignContent: 'center', alignItems: 'center', flexDirection: 'row', width: '19.425%', height: '100%', background: 'linear-gradient(to right bottom, ' + cor1 + ', ' + cor2 + ')', display: 'flex' }}>
                                    <Icon className={"fa fas fa-" + item.Icone} style={{ fontSize: 22, color: corIcone, width: 'auto', height: 'auto', textAlignVertical: 'center', textAlign: 'center', }} />
                                </div>
                            );
                        })}
                    </div>
                    <div style={{ borderBottomRightRadius: 10, borderBottomLeftRadius: 10, justifyContent: 'center', alignContent: 'center', flexDirection: 'row', width: '100%', background: 'linear-gradient(' + global.corDegrade01 + ', ' + global.corDegrade02 + ')' }}>
                        <div style={{ backgroundColor: '#', width: '100%', justifyContent: 'center', alignContent: 'center', display: 'flex', flexDirection: 'column', padding: '10px' }}>
                            <text style={{ color: global.rgBarraInfoFont, fontWeight: 'bold', fontSize: 18, width: '100%' }}>{itemAtual.Status}</text>
                            <text style={{ color: global.rgBarraInfoFont, fontWeight: '400', fontSize: 16, width: '100%' }}>{itemAtual.Mensagem}</text>
                            {((this.state.statusPedido == tp_status_recusado) ? <text style={{ color: global.rgBarraInfoFont, fontSize: 16, fontWeight: 'bold' }}>{global.tel_restaurante}</text> : null)}
                        </div>
                    </div>
                </div>


            );

        } else {
            return (
                <div>
                </div>
            );
        }


    }

    abreDetalhesProd = (item, index) => {
        this.setState({
            alertaDetalheProd: true,
            tmpProdSelect: JSON.parse(JSON.stringify(item)),
            tmpProdIndex: index,
        });
    }

    renderAdicionais = (sabor, produto) => {
        if (sabor.grupoAdics) {
            return sabor.grupoAdics.map((value, index) => {
                return value.adicionais.map((value, index) => {
                    if (value.checked) {
                        return (
                            <text style={{ fontSize: '16px', color: '#999', }} >{
                                ((value.checked > 1) ? value.checked + 'x ' : '+ ')
                                + value.nome
                            }</text>
                        );
                    }
                });
            });
        }
    }

    renderObservacao = (sabor, observacao) => {

        var remocoes = [];
        if (sabor.ingredientes) {
            sabor.ingredientes.map((value, index) => {
                if (value.checked) {
                    remocoes.push('Sem ' + value.nome);
                }
            });
        }

        remocoes.map((value, index) => {
            if (observacao) {
                if (observacao.length > 0) {
                    if (index == (remocoes.length - 1)) {
                        observacao += ' e ';
                    } else {
                        observacao += ', ';
                    }
                }
            } else {
                observacao = "";
            }

            observacao += value;
        });

        if (observacao) {
            if (observacao.length > 0) {
                return (
                    <text style={{ fontSize: '16px', color: '#999', }} >{'Observação: "' + observacao + '"'}</text>
                );
            }
        }
    }

    renderSabores = (qtde, sabor, produto) => {

        const { classes } = this.props;
        var qtdeSabores = sabor.length;

        return sabor.map((value, index) => {
            var prod = value.produto;

            return (
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                    <text style={{ fontSize: '16px', color: '#000', fontWeight: '450' }} >{((qtdeSabores > 1) ? '1/' + qtdeSabores + ' ' : '') + prod.nomeProduto}</text>
                    {this.renderAdicionais(prod, produto)}
                    {this.renderObservacao(prod, prod.observacao)}

                </div>

            );
        });
    }

    renderDialogProdDetalhe = () => {

        const { classes } = this.props;

        var mProduto = this.state.tmpProdSelect;

        if ((this.state.alertaDetalheProd) && (mProduto.sabores)) {

            var saboresNome = '';
            var qtdeSabores = mProduto.sabores.length;
            mProduto.sabores.map((value, index) => {

                var prod = value.produto;

                saboresNome += (saboresNome.length > 0) ? ' ' : '';

                saboresNome += ((qtdeSabores > 1) ? '1/' + qtdeSabores + ' ' : '') + prod.nomeProduto;
            });

            var sabor = mProduto.sabores;
            var qtde = mProduto.qtde;

            return (
                <Dialog
                    open={this.state.alertaDetalheProd}
                    onClose={this.handleCloseAlerta}
                    disableBackdropClick
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    classes={{ container: classes.root, paper: classes.paper }}
                    style={{ maxWidth: '720px', margin: '0 auto 0 auto' }}
                >
                    <DialogContent>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0px', marginBottom: '0px' }}>
                            <div style={{ width: '100%', }}><text style={{ fontWeight: '415', color: '#aaa', fontSize: '24px', display: 'flex' }}>Detalhes do produto</text></div>
                            <div className={classes.separator} style={{ marginTop: '12px', marginBottom: '12px', backgroundColor: '#aaa' }} ></div>
                            <div>
                                {this.renderSabores(mProduto.qtde, sabor, mProduto)}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', margin: '12px 0 12px 0', width: '100%' }}>
                                <Button onClick={() => this.setState({ alertaDetalheProd: false })} color="primary" style={{ backgroundColor: '#ddd', width: '100%', margin: '0 8px 0 0px', color: '#000' }}>
                                    Voltar
                                </Button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            );
        }
    }

    renderPagamentos() {

        const { classes } = this.props;

        return (
            <div style={{ borderRadius: '8px', backgroundColor: '#fff', margin: '6px 0px 12px 0px' }}>
                {(this.state.listaPags) ? this.state.listaPags.map((item, index) => {
                    return (
                        <div>
                            <div style={{ border: "2px groove rgba(0, 0, 0, 0.05)", borderRadius: "8px", padding: "10px", marginBottom: "10px" }}>
                                <div onClick={() => {
                                    this.selecionaFormaPag(item)
                                }} style={{ borderRadius: '8px', width: '100%', backgroundColor: '#fff', display: 'flex' }}>
                                    <img src={MoneyIcon} style={{ marginRight: '5px', height: '22px' }} />
                                    <text style={{ marginRight: '30px', fontSize: '15px', color: '#000', display: 'flex', alignItems: 'center', }} >{item.nome}</text>
                                </div>
                            </div>
                        </div>
                    );
                }) : null}
            </div>
        );
    }

    carregaFormasPag = () => {


        try {
            // here place your login logic     

            var CancelToken = axios.CancelToken;

            const { log_senha, log_usu } = this.state;

            var parametros =
                "&pargrures=" + global.cod_grurest +
                "&parres=" + global.cod_restaurante +
                "&parverapp=" + '99';




            let source = CancelToken.source();
            setTimeout(() => {
                source.cancel();

            }, global.timeOutTimer);


            axios.post(
                global.siteurl + 'pagamento_search.php',
                parametros,
                {
                    cancelToken: source.token,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                    },
                    withCredentials: true,
                }).then((response) => {
                    var responseData = response.data;

                    if (responseData.resposta == "2") {
                    } else if (responseData.resposta == "0") {
                        global.myToken = "";
                        this.setState({
                            myToken: "",
                            loading: false,
                        });
                    } else {
                        this.setState({
                            listaPags: responseData.registros,
                            alertaSelecionaPagamento: true,
                        });
                    }
                })
                .catch((error) => {

                    if (axios.isCancel(error)) {

                        BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 023', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

                    }
                    else {

                        BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 024', [{ text: 'OK', onPress: () => this.closeProgress() },], { cancelable: false },);

                    }
                });

        } catch (err) {

        }
    }

    alteraFormaPagPed = () => {
        try {
            // here place your login logic     

            var CancelToken = axios.CancelToken;

            var registros = this.state.formaPagamentoAtual;
            var pagonl = (registros.online);

            var valorPag = (registros.valTroco) ? registros.valTroco : this.state.total_pedido;

            var valTroco = valorPag.replace(',', '.') - this.state.total_pedido.replace(',', '.');
            valTroco = (valTroco < 0) ? "0" : valTroco;

            var parametros = 'parresid=' + global.cod_restaurante;
            parametros += '&parpedcod=' + global.pedidoAtual;
            parametros += '&parprepag=' + ((pagonl == 1) ? registros.nome : '');
            parametros += '&parpreval=' + ((pagonl == 1) ? valorPag : '0').replace(',', '.');
            parametros += '&parpospag=' + ((pagonl == 1) ? '' : registros.nome);
            parametros += '&parposval=' + ((pagonl == 1) ? '0' : valorPag).replace(',', '.');
            parametros += '&partroco=' + ((pagonl == 1) ? '0' : valTroco);
            parametros += '&partotpe=' + this.state.total_pedido.replace(',', '.');



            let source = CancelToken.source();
            setTimeout(() => {
                source.cancel();
            }, global.timeOutTimer);

            axios.post(
                global.siteurl + 'pedido_atualiza_pag.php',
                parametros,
                {
                    cancelToken: source.token,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                    },
                    withCredentials: true,
                }).then((response) => {
                    var responseData = response.data;

                    if (responseData.resposta == '1') {

                        this.carregaDados();

                        if (pagonl == 1) {
                            var f = document.getElementById('Form');
                            f.parope.value = global.pedidoAtual;
                            f.parres.value = global.cod_restaurante;

                            window.open('', 'TheWindow');
                            f.submit();
                        }

                    } else {

                        BS_ALE(this, 'Atenção', 'Não foi possível alterar o pagamento, tente novamente mais tarde.');

                    }
                })
                .catch((error) => {

                    if (axios.isCancel(error)) {

                        BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 025');

                    }
                    else {

                        BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 026');

                    }
                });

        } catch (err) {

        }
    }

    confirmaCopiaPedido = () => {

        let localCart = global.ProductObj.getCarrinhoCompra();
        localCart = JSON.parse(localCart);
        //load persisted cart into state if it exists
        if (!localCart) {
            localCart = [];
        }

        if (localCart.length > 0) {
            this.setState({
                alertaConfCopia: true,
            });
        } else {
            this.copiaPedido();
        }
    }

    setTrocoVal = () => {

        var pagamentoFinal = this.state.tmpFormaPagTroco;

        var total_ped = this.state.total_pedido;

        var str_troco = this.state.valorTroco.replace(',', '.');

        if (parseFloat(str_troco) >= parseFloat(total_ped)) {

            pagamentoFinal.valTroco = (this.state.valorTroco.replace(',', '.') == total_ped) ? undefined : this.state.valorTroco;

            this.setState({
                alertaDefineTroco: false,
                formaPagamentoAtual: pagamentoFinal,
            }, () => {
                this.alteraFormaPagPed();
            });

        } else {

            BS_ALE(this, 'Atenção', 'O valor para troco precisa ser maior ou igual ao valor total do pedido');

        }

    }


    renderResumoPed() {

        const { classes } = this.props;

        var valor_compra = 0;

        this.state.carrinhoCompra.map((item, index) => {
            valor_compra += (item.valorUnit * item.qtde);
        });

        var prod_val = this.state.valor_pedido;
        prod_val = ((prod_val.trim() == "0.00") || (prod_val.trim() == "0,00")) ? "0,00" : prod_val.replace('.', ',');
        prod_val = 'R$ ' + prod_val;

        var taxa_ent = this.state.taxa_entrega;
        taxa_ent = ((taxa_ent.trim() == "0.00") || (taxa_ent.trim() == "0,00")) ? "0,00" : taxa_ent.replace('.', ',');
        taxa_ent = 'R$ ' + taxa_ent;

        var total_ped = this.state.total_pedido;
        total_ped = ((total_ped.trim() == "0.00") || (total_ped.trim() == "0,00")) ? "0,00" : total_ped.replace('.', ',');
        total_ped = 'R$ ' + total_ped;

        var desconto = this.state.total_desc;
        var strDesc = this.state.total_desc;
        strDesc = ((strDesc.trim() == "0.00") || (strDesc.trim() == "0,00")) ? "0,00" : strDesc.replace('.', ',');
        strDesc = 'R$ ' + strDesc;

        var formaPag = this.state.forma_pag;

        var pagamento_val = this.state.val_pag
        pagamento_val = ((pagamento_val.trim() == "0.00") || (pagamento_val.trim() == "0,00")) ? "0,00" : pagamento_val.replace('.', ',');
        pagamento_val = 'R$ ' + pagamento_val;

        var total_troco = this.state.total_troco
        total_troco = ((total_troco.trim() == "0.00") || (total_troco.trim() == "0,00")) ? "0,00" : total_troco.replace('.', ',');
        total_troco = 'R$ ' + total_troco;

        return (
            <div style={{ backgroundColor: "#", width: '100%', }}>
                <div style={{
                    backgroundColor: "#", width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                    margin: '0px 0px', display: 'flex',
                }}>
                    <text style={{ width: '60%', textAlign: 'left', fontSize: '14px', color: '#bbb', fontWeight: '400', }}>Total dos Produtos</text>
                    <text style={{ width: '40%', textAlign: 'right', fontSize: '14px', color: '#bbb', fontWeight: '400', }}>{prod_val}</text>
                </div>
                {(desconto > 0) ?
                    <div style={{
                        backgroundColor: "#", width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                        margin: '0px 0px', display: 'flex',
                    }}>
                        <text style={{ width: '60%', textAlign: 'left', fontSize: '14px', color: '#bbb', fontWeight: '400', }}>Desconto nos produtos</text>
                        <text style={{ width: '40%', textAlign: 'right', fontSize: '14px', color: '#bbb', fontWeight: '400', }}>- {strDesc}</text>
                    </div>
                    : null}
                <div style={{
                    backgroundColor: "#", width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                    margin: '0px 0px', display: 'flex',
                }}>
                    <text style={{ width: '60%', textAlign: 'left', fontSize: '14px', color: '#bbb', fontWeight: '400', }}>Taxa de Entrega</text>
                    <text style={{ width: '40%', textAlign: 'right', fontSize: '14px', color: '#bbb', fontWeight: '400', }}>{taxa_ent}</text>
                </div>
                <div style={{
                    backgroundColor: "#", width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
                    marginTop: 3, marginBottom: 3, paddingTop: 6, paddingBottom: 6, display: 'flex',
                }}>
                    <text style={{ width: '60%', textAlign: 'left', fontSize: '17px', color: '#555', fontWeight: '600', }}>Total do Pedido</text>
                    <text style={{ width: '40%', textAlign: 'right', fontSize: '17px', color: '#555', fontWeight: '600', }}>{total_ped}</text>
                </div>
            </div>
        );
    }

    renderDialogTroco = () => {

        const { classes } = this.props;

        if ((this.state.alertaDefineTroco)) {

            return (
                <Dialog
                    open={this.state.alertaDefineTroco}
                    onClose={this.handleCloseAlerta}
                    disableBackdropClick
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    classes={{ container: classes.root, paper: classes.paper }}
                    style={{ maxWidth: '720px', margin: '0 auto 0 auto' }}
                >
                    <DialogContent>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0px', marginBottom: '0px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', padding: '0px 6px', margin: '10px 10px 10px 10px', }}>
                                {this.renderResumoPed()}
                            </div>
                            <div style={{ width: '100%', }}><text style={{ fontWeight: '415', color: '#aaa', fontSize: '24px', display: 'flex' }}><PaymentIcon style={{ marginRight: '6px' }} />Troco para:</text></div>
                            <div className={classes.separator} style={{ marginTop: '12px', marginBottom: '12px', backgroundColor: '#aaa' }} ></div>
                            <div className={"MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl"}>
                                <div tabIndex={1} autoFocus={true}></div>
                                <CurrencyInput value={this.state.valorTroco} onChange={(value) => {
                                    this.setState({
                                        valorTroco: value
                                    });
                                }} />
                            </div>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="log_senha"
                                label="Senha"
                                type="password"
                                id="log_senha"
                                autoComplete="current-password"
                                value={this.state.log_senha}
                                onChange={this.handleChange}
                                style={{ display: "none" }}
                            />
                            <div ref={(ref) => this.btnAddTroco = ref} style={{ display: 'flex', flexDirection: 'row', margin: '12px 0 0px 0', width: '100%', paddingBottom: '12px' }}>
                                <Button onClick={() => this.setState({ alertaDefineTroco: false })} color="primary" style={{ backgroundColor: '#ddd', width: '50%', margin: '0 8px 0 0px', color: '#000' }}>
                                    Voltar
                                </Button>
                                <Button onClick={() => this.setTrocoVal()} color="primary" style={{ backgroundColor: '#03a1fc', width: '50%', margin: '0 0 0px 8px' }}>
                                    Confirmar
                                </Button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            );
        }
    }

    resolveAlertaTroco = () => {
        this.setState({
            alertaTroco: false,
            alertaDefineTroco: true,
            valorTroco: '',
            alertaSelecionaPagamento: false,
        });
    }

    handleCloseTroco = () => {
        this.setState({
            alertaTroco: false,
            alertaSelecionaPagamento: false,
            formaPagamentoAtual: this.state.tmpFormaPagTroco,
        }, () => {
            this.alteraFormaPagPed();
        });
    }

    selecionaPagamentoMP = () => {

        var item = {
            online: true,
            nome: "Mercado Pago",
            valTroco: 0
        }

        this.setState({
            formaPagamentoAtual: item,
            alertaSelecionaPagamento: false,
        }, () => {
            this.alteraFormaPagPed();
        });

    }

    selecionaFormaPag = (item) => {

        if (item.troco == '1') {
            this.setState({
                tmpFormaPagTroco: item,
                alertaSelecionaPagamento: false,
                alertaTroco: true,
            });

        } else {

            this.setState({
                formaPagamentoAtual: item,
                alertaSelecionaPagamento: false,
            }, () => {
                this.alteraFormaPagPed();
            });

        }

    }

    renderPagamentosOnline = () => {

        const { classes } = this.props;

        var arrPedido = this.state.pedidoTMP;

        var permitePagOnl = (arrPedido.PEDIDO.BUSCAR) ? (global.restPonBal == '1') : (global.restPonDel == '1');

        if (permitePagOnl) {
            return (
                <div>
                    <div style={{ margin: "10px 0px", fontSize: '16px', fontWeight: '500', color: '#333' }}>Pagamentos Online</div>
                    <div style={{ border: "2px groove rgba(0, 0, 0, 0.05)", borderRadius: "8px", padding: "10px", marginBottom: "10px" }}>
                        <div onClick={() => {
                            this.selecionaPagamentoMP()
                        }} style={{ borderRadius: '8px', width: '100%', backgroundColor: '#fff', display: 'flex' }}>
                            <img src={MPIcon} style={{ marginRight: '5px', height: '28px' }} />
                            <text style={{ marginRight: '30px', fontSize: '15px', color: '#000', display: 'flex', alignItems: 'center', }} >{'Mercado Pago'}</text>
                        </div>
                    </div>
                </div>
            );
        }

    }

    renderDialogPagamento = () => {

        const { classes } = this.props;

        if ((this.state.alertaSelecionaPagamento)) {

            return (
                <Dialog
                    open={this.state.alertaSelecionaPagamento}
                    onClose={this.handleCloseAlerta}
                    disableBackdropClick
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    classes={{ container: classes.root, paper: classes.paper }}
                    style={{ maxWidth: '720px', margin: '0 auto 0 auto' }}
                >
                    <DialogContent>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0px', marginBottom: '30px' }}>
                            <div style={{ width: '100%', }}><text style={{ fontWeight: '415', color: '#aaa', fontSize: '24px', display: 'flex' }}><PaymentIcon style={{ marginRight: '6px' }} />Formas de pagamento</text></div>
                            <div className={classes.separator} style={{ marginTop: '12px', marginBottom: '12px', backgroundColor: '#aaa' }} ></div>
                            {this.renderPagamentosOnline()}
                            <div style={{ margin: "10px 0px", fontSize: '16px', fontWeight: '500', color: '#333' }}>Pagamentos na Entrega</div>
                            {this.renderPagamentos()}
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={() => {
                                    this.setState({
                                        alertaSelecionaPagamento: false
                                    });
                                }}
                                style={{ backgroundColor: '#3496eb' }}
                            >
                                Voltar
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            );
        }
    }

    resolveAlerta() {
        this.setState({
            alertaConsultaPausada: false
        }, () => {
            totalRequestsPedido = 0;
            this.timerFunction();
        });
    }

    handleCloseConsultaPausada = () => {
        this.setState({
            alertaConsultaPausada: false
        }, () => {
            

            this.props.navigate("/pedido-lista");
        });
    }

    leProdutosDisponiveis(tmpProd) {
        var menuItems = [];
        const cookies = new myCookies();

        try {

            var CancelToken = axios.CancelToken;


            let source = CancelToken.source();
            setTimeout(() => {
                source.cancel();

            }, 20000);

            var parametros = 'parresid=' + global.cod_restaurante + '&pargrures=' + global.cod_grurest + '&parmesa=' + (global.pedMesa ? "1" : "0");

            return axios.post(
                global.siteurl + 'produtos_lista.php',
                parametros,
                {
                    cancelToken: source.token,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                    },
                    withCredentials: true
                }).then((response) => {
                    var responseData = response.data;

                    if (responseData.resposta == '1') {
                        var arrLista = JSON.parse(JSON.stringify(responseData.registros));
                        var arrFinal = [];

                        console.log(`ERR - TRACK - restaberto - 3 - ${global.restAberto}`);
                        global.restAberto = (responseData.restaurante.aberto) ? responseData.restaurante.aberto : "0";
                        console.log(`ERR - TRACK - restaberto - 3.1 - ${global.restAberto}`);

                        if (responseData.promocao == '1') {

                            var arrProdPromo = [];

                            for (var index = 0; index < responseData.registros.length; index++) {
                                if (index > 1) {
                                    for (var i = 0; i < responseData.registros[index].produtos.length; i++) {
                                        if ((responseData.registros[index].produtos[i].promo == "1") && (this.comparaTexto(responseData.registros[index].produtos[i].nomeProduto, this.state.pesquisaTxt) || this.comparaTexto(responseData.registros[index].produtos[i].descricao, this.state.pesquisaTxt))) {
                                            arrProdPromo.push(responseData.registros[index].produtos[i]);
                                        }
                                    };
                                }
                            }

                            arrFinal.push({
                                nome: "Promoções",
                                imagem: "",
                                info: "",
                                produtos: arrProdPromo,
                            });
                        }

                        arrLista.map((prop, key) => {

                            arrFinal.push({
                                nome: prop.nome,
                                misto: prop.misto,
                                imagem: prop.cor,
                                info: prop.info,
                                codigo: prop.idGrupo,
                                produtos: prop.produtos,
                                grupoAdics: prop.grupoAdics,
                                fide_beneficios: prop.fide_beneficios,
                                fide_resgate: prop.fide_resgate,
                            });

                        });

                        var resultFunc = global.ProductObj.copiaPedidoCarrinho(tmpProd, arrFinal);
                        var indisponivel = resultFunc.indisp;

                        var now = new Date();
                        var time = now.getTime();
                        var maxAge = 60 * 60 * 24 * 40000;
                        var expireTime = time + maxAge;

                        var expires = (new Date(expireTime));

                        cookies.set(global.cod_restaurante + '_dicaShowed', '1', { path: '/', expires: expires, maxAge: maxAge });

                        if (indisponivel.length > 0) {
                            this.setState({
                                alertaIndisponiveis: true,
                                prodsIndisp: indisponivel,
                                produtosDispQtd: resultFunc.dispQtd
                            });
                        } else {
                            this.setState({
                                alertaIndisponiveis: true,
                                prodsIndisp: [],
                                produtosDispQtd: resultFunc.dispQtd
                            });
                        }
                    } else {
                        this.setState({
                            erroDB: true,
                        });
                        return false;
                    }
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        return false;
                    }
                    else {
                        return false;
                    }
                });
        } catch (err) {
            return false;
        }



    }

    copiaPedido = async () => {

        var pedidoAtual = global.pedidoAtual;

        var responseData = await global.ProductObj.copiaPedidoCarrinhoV2(axios, pedidoAtual);

        if (responseData == false){
            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 067', [{ text: 'OK', onPress: () => { } },], { cancelable: false },);
        }else if (responseData.INDISP.length > 0) {
            this.setState({
                alertaIndisponiveis: true,
                prodsIndisp: responseData.INDISP,
                produtosDispQtd: responseData.carrinhoTamanho
            });
        } else {
            this.setState({
                alertaIndisponiveis: true,
                prodsIndisp: [],
                produtosDispQtd: responseData.carrinhoTamanho
            });
        }
    }

    trataEstadoTela() {

        const corTitle1 = global.rgBarraSubInfo;
        const corTitle2 = global.rgBarraSubInfoEscuro;
        const { classes } = this.props;

        if (this.state.statusPedido == tp_pagamento_pendente) {
            return (
                <div style={{ height: '100vh', }}>
                    <div style={{ margin: '15px', backgroundColor: 'rgba(222, 222, 0, 0.2)', display: 'flex', flexDirection: 'column', padding: '10px', borderRadius: '6px', border: '1px solid rgba(190, 196, 26, 1)' }}>
                        <text style={{ fontSize: '16px', fontWeight: '500' }}>
                            Atenção
                        </text>
                        <text style={{ fontSize: '15px' }}>
                            Estamos aguardando seu pagamento.
                            <br />
                            Se você ainda não fez seu pagamento clique no link abaixo e continue seu pedido
                        </text>
                    </div>
                    <div style={{ marginTop: '10px' }}>
                        <div style={{ margin: '9px', display: 'flex' }} onClick={() => {

                            var f = document.getElementById('Form');
                            f.parope.value = global.pedidoAtual;
                            f.parres.value = global.cod_restaurante;

                            window.open('', 'TheWindow');
                            f.submit();
                        }}>
                            <text className={classes.linkButton} style={{ margin: 'auto', }}>Fazer pagamento deste pedido</text>
                        </div>
                        <div style={{ margin: '9px', display: 'flex' }} onClick={() => {

                            this.carregaFormasPag();

                        }}>
                            <text className={classes.linkButton} style={{ margin: 'auto', }}>Pagar este pedido com outra forma de pagamento</text>
                        </div>


                    </div>
                </div>
            );
        }

        if (this.state.alertaConsultaPausada) {
            return (
                <div style={{ height: '100vh' }}>
                    <CardBody style={{ width: '100%', height: '100%', position: 'fixed', left: 0, top: 0, right: 0, bottom: 0, overflow: 'hidden', display: 'flex' }}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', backgroundColor: '#fff' }}>
                            <Button onClick={() => this.resolveAlerta()} color="primary" style={{ color: '#fff', backgroundColor: '#03a1fc', width: '60%', marginBottom: '20px' }}>
                                {"Status do pedido"}
                            </Button>
                            <Button color="primary" style={{ color: "#fff", backgroundColor: '#03a1fc', width: '60%' }} onClick={() => {
                                this.handleCloseConsultaPausada();
                            }}>
                                {"Lista de pedidos"}
                            </Button>
                        </div>
                    </CardBody>
                </div>


            );
        }

        return (
            <CardBody>
                <div style={{ scrollMarginTop: (this.state.scrollMarginTop + "px"), maxWidth: '100%', }}>
                    <div>
                        <div>
                            <div style={{ display: 'flex', flexDirection: 'column', }}>
                                {this.geraStatus()}
                                {this.geraSenha()}
                                {this.geraMensagemExtra()}

                                <div style={{ height: 'auto', flex: 0, width: '100%', }}>
                                    <div style={{ background: 'linear-gradient(' + corTitle1 + ', ' + corTitle2 + ')', display: 'flex' }}>
                                        <text style={{ ...styles.infoText, color: global.rgBarraSubInfoFont, padding: '10px' }}>Loja</text>
                                    </div>
                                    <div style={{ borderColor: global.corFonte, width: '100%', justifyContent: 'center', alignItems: 'center', }}>
                                        {this._renderRest()}
                                    </div>
                                </div>

                                <div style={{ height: 'auto', flex: 0, width: '100%', }}>
                                    <div style={{ background: 'linear-gradient(' + corTitle1 + ', ' + corTitle2 + ')', display: 'flex' }}>
                                        <text style={{ ...styles.infoText, color: global.rgBarraSubInfoFont, padding: '10px' }}>Produtos</text>
                                    </div>
                                    <div style={{ borderColor: global.corFonte, width: '100%', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                        {this.renderCopiaPedido()}
                                        {this.renderProdutos()}
                                    </div>
                                </div>

                                <div style={{ height: 'auto', flex: 0, width: '100%', }}>
                                    <div style={{ background: 'linear-gradient(' + corTitle1 + ', ' + corTitle2 + ')', display: 'flex' }}>
                                        <text style={{ ...styles.infoText, color: global.rgBarraSubInfoFont, padding: '10px' }}>Valores Totais</text>
                                    </div>
                                    <div style={{ borderColor: global.corFonte, width: '100%', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                        {this.trataListaPags()}
                                    </div>
                                </div>

                                <div style={{ height: 'auto', flex: 0, width: '100%', }}>
                                    <div style={{ background: 'linear-gradient(' + corTitle1 + ', ' + corTitle2 + ')', display: 'flex' }}>
                                        <text style={{ ...styles.infoText, color: global.rgBarraSubInfoFont, padding: '10px' }}>Endereço de Entrega</text>
                                    </div>
                                    <div style={{ borderColor: global.corFonte, width: '100%', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                        <text style={{ ...styles.infoText, color: global.rgFundoFont }}>{this.state.enderecoFinal}</text>
                                    </div>
                                </div>

                                <div style={{ height: 'auto', flex: 0, width: '100%', }}>
                                    <div style={{ background: 'linear-gradient(' + corTitle1 + ', ' + corTitle2 + ')', display: 'flex' }}>
                                        <text style={{ ...styles.infoText, color: global.rgBarraSubInfoFont, padding: '10px' }}>Cupom fiscal</text>
                                    </div>
                                    <div style={{ borderColor: global.corFonte, width: '100%', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                        <text style={{ ...styles.infoText, color: global.rgFundoFont }}>{(this.state.nfeDoc) ? (this.state.nfeDoc.length > 0) ? this.state.nfeDoc : 'Sem cupom fiscal.' : 'Sem cupom fiscal.'}</text>
                                    </div>
                                </div>

                                <div style={{ height: 'auto', flex: 0, width: '100%', }}>
                                    <div style={{ background: 'linear-gradient(' + corTitle1 + ', ' + corTitle2 + ')', display: 'flex' }}>
                                        <text style={{ ...styles.infoText, color: global.rgBarraSubInfoFont, padding: '10px' }}>Observação</text>
                                    </div>
                                    <div style={{ borderColor: global.corFonte, width: '100%', justifyContent: 'center', alignItems: 'center', padding: '10px' }}>
                                        <text style={{ ...styles.infoText, color: global.rgFundoFont }}>{(this.state.observacao) ? (this.state.observacao.length > 0) ? this.state.observacao : 'Nenhuma observação para o pedido.' : 'Nenhuma observação para o pedido.'}</text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CardBody>
        );
    }

    renderCopiaPedido = () => {

        const { classes } = this.props;

        if (this.state.codCli != '0'){
            return (
                <div style={{ margin: '9px', display: 'flex' }} onClick={() => { this.confirmaCopiaPedido() }}>
                    <text className={classes.linkButton} style={{ margin: 'auto', }}>Fazer novo pedido com estes produtos</text>
                </div>
            );
        }
    }

    renderProdutosIndisp = (items) => {

        const { classes } = this.props;
        var resultado = null;

        items = Array.from(new Set(items));

        return items.map((value, index) => {

            var saboresNome = value.PRODUTO;

            return (
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <text style={{ fontSize: '16px', color: '#000', fontWeight: '420' }} >{saboresNome}</text>
                        </div>
                    </div>
                    <div style={{ margin: '10px 0' }} className={classes.separator}></div>
                </div>
            );


        });
        /*return produtos.map( (value, index) => {
            return (
                <div>{value.sabores[0].produto.nomeProduto}</div>
            );
        });*/
    }

    renderDialogListaProdIndisp = () => {

        const { classes } = this.props;

        var produtos = this.state.prodsIndisp;

        if ((this.state.alertaIndisponiveis)) {

            var mensagem = 'Os produtos abaixo não foram adicionados ao carrinho porque não estão disponíveis atualmente.';

            if (this.state.produtosDispQtd > 0) {
                mensagem += ' Os demais produtos foram adicionados e na próxima tela você poderá verificar as quantidades e valores para o novo pedido.';
            }else{
                mensagem += ' Nenhum produto foi adicionado ao carrinho.';
            }

            if (produtos.length <= 0) {
                mensagem = 'Os produtos foram adicionados ao carrinho com sucesso. Na próxima tela você poderá verificar as quantidades e valores para o novo pedido.';
            }

            return (
                <Dialog
                    open={this.state.alertaIndisponiveis}
                    onClose={this.handleCloseAlerta}
                    disableBackdropClick
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    classes={{ container: classes.root, paper: classes.paper }}
                    style={{ maxWidth: '720px', margin: '0 auto 0 auto' }}
                >
                    <DialogContent>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '0px', marginBottom: '0px' }}>
                            <div style={{ width: '100%', }}><text style={{ fontWeight: '415', color: '#aaa', fontSize: '24px', display: 'flex' }}>Atenção</text></div>
                            <div className={classes.separator} style={{ marginTop: '12px', marginBottom: '12px', backgroundColor: '#aaa' }} ></div>
                            <div>
                                <div style={{ marginBottom: '15px' }}>{mensagem}</div>
                                {this.renderProdutosIndisp(produtos)}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', margin: '12px 0 12px 0', width: '100%' }}>
                                <Button onClick={() => {
                                    if (this.state.produtosDispQtd > 0){
                                        this.setState({ alertaIndisponiveis: false }, () => {
                                            
                                            this.props.navigate('carrinho-compra');
                                        })
                                    }else{
                                        this.setState({
                                            alertaIndisponiveis : false,
                                        });
                                    }
                                }} color="primary" style={{ backgroundColor: '#ddd', width: '100%', margin: '0 8px 0 0px', color: '#000' }}>
                                    Ok
                                </Button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            );
        }
    }

    handleCloseConfCopia = () => {
        this.setState({
            alertaConfCopia: false,
        });
    }

    renderConfirmacao = (hist) => {
        return (
            <Confirmacao
                showAlert={this.state.alertaConfCopia}
                onClose={this.handleCloseConfCopia}
                callbackYes={() => {
                    this.setState({
                        alertaConfCopia: false,
                    }, () => {
                        this.copiaPedido();
                    });
                }}
                title={'Atenção'}
                msg={'Você possui já possui produtos no carrinho\n\nDeseja trocá-los pelos deste pedido?'}
                callbackNo={() => {
                    this.handleCloseConfCopia();
                }}
            />
        );
    }

    render() {
        const { classes } = this.props;

        const corTitle1 = global.rgBarraSubInfo;
        const corTitle2 = global.rgBarraSubInfoEscuro;

        return (
            <div className={classes.myBody} style={{ marginTop: '0px', marginBottom: '0px', paddingBottom: '0px' }}>
                <div className={classes.container} style={{ paddingBottom: '25px' }}>
                    <Card>
                        <form className={classes.form}>
                            <Header context={this} defaultIcon={false} leftLinks={
                                <div style={{ whiteSpace: 'nowrap' }}>
                                    <div style={{ width: 'max-content', whiteSpace: 'nowrap', flexDirection: 'column', marginTop: '12px', marginBottom: '12px', }} className={""}>
                                        {this.renderHeader()}
                                    </div>
                                </div>
                            } />
                            {this.trataEstadoTela()}
                        </form>
                    </Card>
                </div>
                <Footer qtdeTiposProds={this.state.carrinhoAtual.length} whiteFont />
                {this.renderDialogProdDetalhe()}
                {this.renderDialogListaProdIndisp()}
                {this.renderDialogPagamento()}
                {this.renderDialogTroco()}

                <Confirmacao
                    showAlert={this.state.alertaTroco}
                    onClose={this.handleCloseTroco}
                    callbackYes={() => { this.resolveAlertaTroco() }}
                    title={'Atenção'}
                    msg={'Você deseja troco?'}
                    callbackNo={() => {
                        this.handleCloseTroco();
                    }}
                />
                {this.renderConfirmacao()}
                <form id="Form" method="post" action={global.siteurl + 'mercado_pago.php?t=' + Date.now()} target="TheWindow">
                    <input type="hidden" name="parope" value="" />
                    <input type="hidden" name="parres" value="" />
                </form>
            </div>
        );
    }
}

export default withStyles(PedidoStatus, styles);
