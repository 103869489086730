import { container, primaryColor } from "assets/jss/material-kit-react.js";

const footerStyle = {
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right!important"
  },
  footer: {
    padding: "0.9375rem 0",
    textAlign: "center",
    display: "flex",
    zIndex: "999",
    position: "relative"
  },
  a: {
    color: primaryColor,
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF"
    }
  },
  container,
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  icon: {
    width: "18px",
    height: "18px",
    position: "relative",
    top: "3px",
  },
  stickToBottom: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: "999",
  },
  iconeFooter: {
    color: '#aaa',
    marginTop: 'auto',
    marginBottom: '3px'
  },
  iconeFooterSelecionado: {
    color: '#4287f5',
    fontWeight: 'bold',
    marginTop: 'auto',
    marginBottom: '3px'
  },
  container: {
    
  },
  floatText: {
    position: 'absolute',
    fontSize: '10px',
    fontWeight: 'bold',
    color: '#fff',
    
    left: '30px',
    right:0,
    top: 0,

    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '4px',
    width: '18px',
    height: '18px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    borderRadius: '50%',
    textAlign: 'center',
    background: '#cc0000',
  }
};
export default footerStyle;
