  const customTabsStyle = {
    flexGrow: {
        flex: '1',
    },
    button: {
        backgroundColor: '#03a1fc',
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 10,
        color: '#fff',
        '&:hover': {
            backgroundColor: '#03a1fc',
            color: '#3c52b2',
        }
    }
  };
  
  export default customTabsStyle;  