import React, { useState } from 'react';

function CurrencyInput({ value, onChange }) {
    
    const handleChange = (e) => {
      let inputValue = e.target.value;
  
      // Remove tudo que não for número ou ponto decimal
      inputValue = inputValue.replace(/[^0-9]/g, '');
    
      // Divide o valor em inteiro e decimal1
      var strVal = (inputValue / 100).toFixed(2).replace('.', ',');
    
      // Adiciona prefixo "R$ "
      onChange(strVal);
    };
  
    return (
      <div>
        <label htmlFor="currency">Valor Monetário:</label>
        <input
            id="currency"
            type="text"
            className="MuiInputBase-input MuiOutlinedInput-input"
            style={{
                height: "45px",
                border: 'solid 1px #ccc',
                width: '100%',
                borderRadius: "3px",
                textAlign: "right",
                fontSize: "16px"
            }}
            value={(value != null && value != '') ? value : "0,00"}
            onChange={handleChange}
            placeholder="Digite o valor"
        />
      </div>
    );
}

export default CurrencyInput;