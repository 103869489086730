import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

const StyledGrid = styled(Grid)(({ theme }) => ({
  marginRight: "-15px",
  marginLeft: "-15px",
  width: "auto"
}));

export default function GridContainer(props) {
  const { children, className, ...rest } = props;
  return (
    <StyledGrid container {...rest} className={className}>
      {children}
    </StyledGrid>
  );
}

GridContainer.defaultProps = {
  className: ""
};

GridContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};
