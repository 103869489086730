import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import { Box } from "@mui/material";

import styles from "assets/jss/material-kit-react/components/cardFooterStyle.js";

const StyledBox = styled(Box)(styles);

export default function CardFooter({ className, children, ...rest }) {
  return (
    <StyledBox className={className} {...rest}>
      {children}
    </StyledBox>
  );
}

CardFooter.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};
