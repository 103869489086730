import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import styles from "assets/jss/material-kit-react/components/customInputStyle.js";

// Estilize os componentes utilizando styled
const FormControlStyled = styled(FormControl)(({ theme }) => ({
  ...styles.formControl
}));

const InputLabelStyled = styled(InputLabel)(({ theme, error, success }) => ({
  ...styles.labelRoot,
  ...(error && styles.labelRootError),
  ...(success && !error && styles.labelRootSuccess)
}));

const InputStyled = styled(Input)(({ theme, white, error, success }) => ({
  "& .MuiInput-underline": {
    ...styles.underline,
    ...(error && styles.underlineError),
    ...(success && !error && styles.underlineSuccess),
    ...(white && styles.whiteUnderline)
  },
  "& .MuiInput-input": {
    ...styles.input,
    ...(white && styles.whiteInput)
  }
}));

const CustomInput = (props) => {
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success
  } = props;

  const formControlClasses = classNames(
    formControlProps?.className,
    styles.formControl
  );

  return (
    <FormControlStyled {...formControlProps} className={formControlClasses}>
      {labelText && (
        <InputLabelStyled
          htmlFor={id}
          error={error}
          success={success}
          {...labelProps}
        >
          {labelText}
        </InputLabelStyled>
      )}
      <InputStyled
        id={id}
        white={white}
        error={error}
        success={success}
        {...inputProps}
        classes={{
          root: inputRootCustomClasses
        }}
      />
    </FormControlStyled>
  );
};

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool
};

export default CustomInput;
