import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { styled } from "@mui/material/styles";

// Importar estilos
import styles from "assets/jss/material-kit-react/components/cardStyle.js";

// Criar um componente estilizado com styled
const CardRoot = styled('div')(({ theme, plain, carousel }) => ({
  ...styles.card,
  ...(plain && styles.cardPlain),
  ...(carousel && styles.cardCarousel),
}));

export default function Card(props) {
  const { className, children, plain, carousel, ...rest } = props;

  // Combina as classes usando classNames
  const cardClasses = classNames(className);

  return (
    <CardRoot className={cardClasses} plain={plain} carousel={carousel} {...rest}>
      {children}
    </CardRoot>
  );
}

Card.propTypes = {
  className: PropTypes.string,
  plain: PropTypes.bool,
  carousel: PropTypes.bool,
  children: PropTypes.node,
};