/*eslint-disable*/
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Tooltip from "@mui/material/Tooltip";
import { Apps, CloudDownload } from "@mui/icons-material";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";
import { styled } from "@mui/material/styles";
import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = () => styles;

const StyledList = styled(List)(({ theme }) => ({
  ...styles.list
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  ...styles.listItem
}));

const StyledButton = styled(Button)(({ theme }) => ({
  ...styles.navLink
}));

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    ...styles.tooltip
  }
}));

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <StyledList>
      <StyledListItem>
        <CustomDropdown
          noLiPadding
          buttonText="Components"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Apps}
          dropdownList={[
            <Link to="/" className={classes.dropdownLink}>
              All components
            </Link>,
            <a
              href="https://creativetimofficial.github.io/material-kit-react/#/documentation?ref=mkr-navbar"
              target="_blank"
              className={classes.dropdownLink}
            >
              Documentation
            </a>
          ]}
        />
      </StyledListItem>
      <StyledListItem>
        <StyledButton
          href="https://www.creative-tim.com/product/material-kit-react?ref=mkr-navbar"
          color="transparent"
          target="_blank"
        >
          <CloudDownload className={classes.icons} /> Download
        </StyledButton>
      </StyledListItem>
      <StyledListItem>
        {/*<Tooltip title="Delete">
          <IconButton aria-label="Delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>*/}
        <StyledTooltip
          id="instagram-twitter"
          title="Follow us on twitter"
          placement={window.innerWidth > 959 ? "top" : "left"}
        >
          <StyledButton
            href="https://twitter.com/CreativeTim?ref=creativetim"
            target="_blank"
            color="transparent"
          >
            <i className={classes.socialIcons + " fab fa-twitter"} />
          </StyledButton>
        </StyledTooltip>
      </StyledListItem>
      <StyledListItem>
        <StyledTooltip
          id="instagram-facebook"
          title="Follow us on facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
        >
          <StyledButton
            color="transparent"
            href="https://www.facebook.com/CreativeTim?ref=creativetim"
            target="_blank"
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </StyledButton>
        </StyledTooltip>
      </StyledListItem>
      <StyledListItem>
        <StyledTooltip
          id="instagram-tooltip"
          title="Follow us on instagram"
          placement={window.innerWidth > 959 ? "top" : "left"}
        >
          <StyledButton
            color="transparent"
            href="https://www.instagram.com/CreativeTimOfficial?ref=creativetim"
            target="_blank"
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </StyledButton>
        </StyledTooltip>
      </StyledListItem>
    </StyledList>
  );
}
