import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import { Dialog, DialogContent, DialogContentText, Button } from "@mui/material";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import { makeStyles } from "tss-react/mui";

import styles from "assets/jss/material-kit-react/components/dialogConfirmacao.js";
const useStyles = makeStyles()(styles);

export default function Confirmacao(props) {
  const {
    showAlert,
    onClose,
    btnNaoText,
    btnSimText,
    callbackYes,
    callbackNo,
    msgDestaque,
    msg,
    title,
    ...rest
  } = props;

  const { classes } = useStyles();

  const titulo = msgDestaque ? msgDestaque : 'Confirmação';
  const textoBtnSim = btnSimText ? btnSimText : 'Sim';
  const textoBtnNao = btnNaoText ? btnNaoText : 'Não';
  const maiorBtn = Math.max(textoBtnNao.length, textoBtnSim.length);
  const widthCalc = maiorBtn * 13 < 80 ? 80 : maiorBtn * 13;
  const flexDirBtns = widthCalc < 120 ? 'row' : 'column';

  return (
    <div {...rest}>
      <Dialog
        open={showAlert}
        onClose={() => onClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{display: 'flex', flexDirection: 'column'}}>
                <HelpOutlineOutlinedIcon style={{ margin: 'auto', marginBottom: '10px', fontSize: '40px', color:"#03a1fc", }} />
                <text style={{ margin: 'auto', marginBottom: '10px', fontSize: '20px', fontWeight: '500', textAlign: 'center' }}>{titulo}</text>
                <div style={{whiteSpace: 'pre-wrap', textAlign: 'center', minWidth: '225px'}}>{msg}</div>
            </DialogContentText>
        </DialogContent>
        <div style={{ display: 'flex', flexDirection: flexDirBtns, margin: 'auto', marginBottom: '15px' }}>
          <Button onClick={() => callbackYes()} className={classes.button} sx={{ width: `${widthCalc}px`, marginBottom: `${10}px` }}>
            {textoBtnSim}
          </Button>
          <Button onClick={() => callbackNo()} className={classes.button} sx={{  width: `${widthCalc}px`, marginBottom: `${10}px` }}>
            {textoBtnNao}
          </Button>
        </div>
      </Dialog>
    </div>
  );
}

Confirmacao.propTypes = {
  showAlert: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  btnNaoText: PropTypes.string,
  btnSimText: PropTypes.string,
  callbackYes: PropTypes.func.isRequired,
  callbackNo: PropTypes.func.isRequired,
  msgDestaque: PropTypes.string,
  msg: PropTypes.string,
  title: PropTypes.string,
};
