import React, { useState, useEffect } from "react";
// @mui/material components
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "@mui/material/Icon";
// @mui/icons-material
import Email from "@mui/icons-material/Email";
import People from "@mui/icons-material/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import styles from "assets/jss/material-kit-react/views/loginPage.js";
import  "assets/css/CarrinhoCompra.css";

import image from "assets/img/bg7.jpg";
import { Message } from "@mui/icons-material";
import ArrowBack  from "@mui/icons-material/ArrowBack";

import 'prevent-pull-refresh';

import axios from 'axios';

import Home from '@mui/icons-material/Home';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';

import MaskedInput from 'react-text-mask'
import { FormControl, IconButton, Input, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";

import { BS_ALE, removeEComercial } from 'funcs/MyFuncs.js';

import { withStyles } from "tss-react/mui";

import { Capacitor } from '@capacitor/core';

import { useNavigate } from "react-router-dom";

const delayProcessando     = 0;
const tempoMinProcess      = 0;

const optionsIdentificacao = [
    "Nenhum",
    "Casa",
    "Apartamento",
    "Trabalho"
];

function TelefoneMaskInput(props) {
    const { ref, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={ref ? ref.inputElement : null}
        mask={['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      />
    );
}

function CEPMaskInput(props) {
    const { ref, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={ref ? ref.inputElement : null}
        mask={[/[0-9]/, /\d/,  /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, ]}
      />
    );
}

class LoginPage extends React.Component {

    arrPosItems  = [];    
    arrMenuItems = [];
    cardBody     = null;
    myMenu       = null;
    cabecalho    = null;

    ultElementMenu = -1;

    handleChange = (event) => {
        if (event.target.name == "cad_cep"){
            this.setState({
                [event.target.name]: event.target.value,
                estadoCEP: "0",
            });
        }else{
            this.setState({
                [event.target.name]: event.target.value,
            });
        }
        
    };

    constructor(props) {
        super(props);
        
        let localCart = global.ProductObj.getCarrinhoCompra();
        localCart = JSON.parse(localCart);
        //load persisted cart into state if it exists
        if (!localCart) {
            localCart = [];
        }

        this.state = { 
            carrinhoCompra: localCart,
            estadoLogin: [0],
            cad_nome:    '',

            cad_cep :           '',
            cad_rua:            '',
            cad_bairro:         '',
            cad_numero:         '',
            cad_estado:         '',
            cad_cidade:         '',
            cad_complemento:    '',
            cad_identificacao:  '',
            cad_referencia:     '',
            log_senha:   '',
            log_usu:     '',

            cli_nome:    '',
            cli_tele:    '',
            cli_email:   '',
            cli_cpf:     '',
            myToken:     '',

            estadoCEP:   '0',
            listaBairros: [],

            identFocused: false,
        };

        this.updateDimensions = this.updateDimensions.bind(this);

        this.carregaDados = this.carregaDados.bind(this);

    }

    componentDidMount(){

        const { navigation } = this.props;

        let localCart = global.ProductObj.getCarrinhoCompra();
        localCart = JSON.parse(localCart);
        //load persisted cart into state if it exists
        if (!localCart) {
            localCart = [];
        }

        let meuToken = localStorage.getItem(global.chaveLocalLogin);
        meuToken = meuToken;
        this.setState({ 
            myToken        : meuToken,
            carrinhoCompra : localCart,
            estadoLogin    : [0]
        }, () => {
            this.carregaDados();
            window.scrollTo(0, 0);
        });

        
        
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.updateDimensions);
    }

    carregaDados = () => {
        if (this.state.myToken){


            try {
                // here place your login logic     
                      
                var CancelToken = axios.CancelToken;
    
                const {log_senha, log_usu} = this.state;
            
                var parametros = 
                "&partoken="    + this.state.myToken +
                "&pargrures=" + global.cod_grurest +
                "&parres=" + global.cod_restaurante +
                "&parverapp=" + '99';

                let source = CancelToken.source();
                setTimeout(() => {
                    source.cancel();
                }, global.timeOutTimer);
        
                
                axios.post(
                    global.siteurl + 'cliente_info.php', 
                    parametros,
                    {
                    cancelToken: source.token,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                    },
                    withCredentials: true,
                }).then((response) => {
                    var responseData = response.data;

                    if (responseData.cliPermCupom){
                        global.cliPerCupom = responseData.cliPermCupom;
                    }

                    if (responseData.resposta == "2"){
                        axios.post(
                            global.siteurl + 'renova_jwt.php', 
                            parametros,
                            {
                            cancelToken: source.token,
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                            },
                            withCredentials: true,
                        }).then((response) => {
                            var responseData = response.data;
                            localStorage.removeItem(global.chaveLocalLogin);
                            localStorage.setItem(global.chaveLocalLogin, responseData.JWT);
                            
                            this.setState({
                                myToken : responseData.JWT
                            }, () => {
                                
                            });
                        })
                        .catch((error) =>{
                            
                            if(axios.isCancel(error)){
                
                                BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{text: 'OK', onPress: () => {}},],{cancelable: false},);
                        
                            }
                            else{
                        
                                BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{text: 'OK', onPress: () => {}},],{cancelable: false},);
                        
                            }
                        });
                    }else if (responseData.resposta == "0") {
                        this.setState({
                            myToken : ""
                        });
                    }else{

                        this.setState({
                            cli_nome  : responseData.nome,
                            cli_tele  : responseData.telefone,
                            cli_email : responseData.email,
                            cli_cpf   : responseData.cpf,
                        });
                    }
                })
                .catch((error) =>{
                    
                    if(axios.isCancel(error)){
        
                        BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{text: 'OK', onPress: () => {}},],{cancelable: false},);
                
                    }
                    else{
                
                        BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{text: 'OK', onPress: () => {}},],{cancelable: false},);
                
                    }
                });
    
            } catch (err) {
            }       
        }
    }
    
    updateDimensions() {
        var alturaScroll = document.body.scrollHeight - window.innerHeight;
        var scrolledY    = window.scrollY;

        var alturaItems  = 0;

        this.arrPosItems.map((item, index) => {
            alturaItems += (item) ? item.clientHeight : 0;
        });

        var proporcao = alturaItems / (alturaScroll);

        var tmpPos = 0;
        var itemAtual = 0;

        for (var i = 0; i < this.arrPosItems.length; i++){
            var tmpItem = this.arrPosItems[i];
            tmpPos += (tmpItem) ? tmpItem.clientHeight : 0;

            if ( (scrolledY * proporcao) < ( ( (this.cabecalho) ? tmpPos + (this.cabecalho.clientHeight) : tmpPos) - this.state.scrollMarginTop) ){
                break;
            }else{
                itemAtual = (i >= (this.ultElementMenu)) ? this.ultElementMenu : i + 1;
            }
        }        

        if (this.state.posItemClicado == -1){
            if (itemAtual !== this.state.itemAtivo) {
                this.setState({ itemAtivo: itemAtual});

                if (itemAtual > 0){
                    this.myMenu.scrollTo({left: this.arrMenuItems[itemAtual].offsetLeft - 30, behavior: 'smooth'})
                }else{
                    this.myMenu.scrollTo({left: 0, behavior: 'smooth'})
                }
            }
        }else if (scrolledY == this.state.posItemClicado){

            if (this.state.eventClicado == -1){
                if (itemAtual !== this.state.itemAtivo) {
                    this.setState({ itemAtivo: itemAtual});
                }
    
                if (itemAtual > 0){
                    this.myMenu.scrollTo({left: this.arrMenuItems[itemAtual].offsetLeft - 30, behavior: 'smooth'})
                }else{
                    this.myMenu.scrollTo({left: 0, behavior: 'smooth'})
                }
                this.setState({ 
                    posItemClicado: -1,
                });
            }else{
                this.myMenu.scrollTo({left: this.arrMenuItems[this.state.eventClicado].offsetLeft - 30, behavior: 'smooth'})
                this.setState({ 
                    itemAtivo: this.state.eventClicado,
                    posItemClicado: -1,
                    eventClicado : -1,
                });
            }
        }else{
            if (itemAtual !== this.state.itemAtivo) {
                this.setState({ itemAtivo: itemAtual});
            }
        }


    }

    atualizaPadraoDB = async (parametros, metodo, tabelaNome, arrLista) => {
        
        try {
    
            var CancelToken = axios.CancelToken;
    
            let source = CancelToken.source();
            setTimeout(() => {
              source.cancel();
            }, 20000);
    
            
            await axios.post(
              global.urlServer + metodo, 
              parametros,
              {
                cancelToken: source.token,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                },
                withCredentials: true
              }).then((response) => {
                var responseData = response.data;
                if (responseData.resposta == '1') {
                  arrLista = JSON.parse(JSON.stringify(responseData.registros));
                  
                  var arrFinal = [];
    
                  if (arrLista){
                    arrLista.map((prop, key) => {
                      arrFinal.push({
                        key : key, 
                        codInterno : prop.idGrupo, 
                        nome : prop.nome, 
                        precoDelivery : prop.posicao, 
                        descricao : global.urlServer + 'imgs/' + this.state.usr_grures + '/' + prop.cor + '.png',
                        image: global.urlServer + 'imgs/' + this.state.usr_grures + '/' + prop.cor + '.png',
                      });
                    });
                  }
                  
                  this.setState({
                    listaGruPro: arrFinal,
                  });
                }else{
                  this.setState({
                    erroDB : true,
                  });
                }
            })
            .catch((error) =>{
              if (error == '785936'){
    
              }else if(axios.isCancel(error)){
    
              }else{
            
    
              }
            });
        } catch (err) {
          
        }    
      } 

    clickProd(item){
        
    }

    scrollToItem = (index) => {
        //alert('Hello: ' + index);
        //this.arrPosItems[index].scrollIntoView({behavior: 'smooth'});

        var offsetNextScroll = 80;

        var alturaScroll = document.body.scrollHeight - window.innerHeight - this.state.scrollMarginTop;
        var scrolledY    = window.scrollY;

        var alturaItems  = 0;

        this.arrPosItems.map((item, index) => {
            alturaItems += (item) ? item.clientHeight : 0;
        });

        var proporcao = (alturaItems) / alturaScroll;

        var posicaoItem  = 0;

        this.arrPosItems.map((item, itemIndex) => {
            if (itemIndex == index){
                posicaoItem = (item) ? ( (item.offsetTop + this.cardBody.offsetTop) - this.state.scrollMarginTop ) : 0;
            }
        });

        /*for (var i = 0; i < (index); i++){
            var tmpItem = this.arrPosItems[i];
            posicaoItem += (tmpItem) ? tmpItem.clientHeight : 0;
        }

        posicaoItem -= this.state.scrollMarginTop;
        posicaoItem += this.cabecalho.clientHeight;
        posicaoItem = posicaoItem / proporcao;
        posicaoItem += offsetNextScroll;
        posicaoItem = Math.round(posicaoItem);*/

        window.scrollTo({top: posicaoItem, behavior: 'smooth'});

        this.setState({
            posItemClicado : posicaoItem,
            eventClicado   : index,
        });
        
    }

    renderMenu = (items) => {
        const { classes } = this.props;
        var resultado = null;

        return items.map((item, index) => {

            var prodArrFinal = [];
            var primeiraCateg = true;
            item.produtos.filter(produto => (this.comparaTexto(produto.nomeProduto, this.state.pesquisaTxt) || this.comparaTexto(produto.descricao, this.state.pesquisaTxt))).map(
                filteredProduto => prodArrFinal.push(filteredProduto)
            );

            if (prodArrFinal.length > 0){
                if (index == this.state.itemAtivo){
                    return (
                        <div ref={(ref) => this.arrMenuItems[index] = ref} style={{marginRight: '10px', display: 'inline-block',}}>
                            <a onClick={() => {this.scrollToItem(index)}} style={{fontSize: '15px', fontWeight: 'bold', color: 'black'}}>ZZZZ - {item.nome}</a>
                            <div style={{marginTop: '4px', backgroundColor: '#f00'}} className={classes.separatorMenu}></div>
                        </div>
                    );    
                }else{    
                    return (
                        <div ref={(ref) => this.arrMenuItems[index] = ref} style={{marginRight: '10px', display: 'inline-block',}}>
                            <a onClick={() => {this.scrollToItem(index)}} style={{fontSize: '15px', fontWeight: 'bold', color: 'black'}}>{item.nome}</a>
                        </div>
                    );                      
                }
                
            }else{
                return (
                    <div style={{ display: 'inline-block', }} ref={(ref) => this.arrMenuItems[index] = ref}>

                    </div>
                );
            }
            
        });
    }

    renderProds = (prodArrFinal) => {
        const { classes } = this.props;

        return prodArrFinal.map((prod, index) => {
            return (
                <div>
                    <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                        <div>
                            <div>
                                <text style={{fontSize:'16px', fontWeight:'bold'}}>{prod.nomeProduto}</text>
                            </div>
                            <div>
                                <text style={{display: 'inline-block', fontSize:'13px', lineHeight: '18px'}}>{prod.descricao}</text>
                            </div>  
                            <div>
                                <text style={{fontSize:'16px', fontWeight:'bold'}}>R$ {prod.preco.replace('.', ',')}</text>
                            </div>                  
                        </div>
                        <div style={{flex: 1, minWidth: '100px', height: '80px', display: "flex", justifyContent: "flex-end"}}>
                            <img src="https://images.rappi.com.br/products/855fef0d-b498-4e0c-b0dd-228eb443237b-1602546044927.png?d=128x90" style={{width: '80px', height: "80px"}}></img>
                        </div>
                    </div>    
                    <div style={{marginTop: '10px'}} className={classes.separator}></div>            
                </div>
            );
        });
    }
    
    renderLista = (items) => {
        const { classes } = this.props;
        var resultado = null;

        return items.map((item, index) => {

            return (
                <div ref={(ref) => this.arrPosItems[index] = ref} style={{ scrollMarginTop: (this.state.scrollMarginTop + "px"), maxWidth: '100%', marginLeft: '20px', marginRight: '20px', marginBottom: '30px'}}>
                    <div style={{width: '100%',}}>
            <text style={{marginRight: '30px', fontSize: '22px', color: '#a30000', fontWeight: 'bold'}} >{item.qtde}x {item.nome} - {item.valorTotal}</text>
                    </div>   
                </div>
            );
            
        });
    }

    pesquisaLista = (value) => {
        window.scrollTo({top: 0, behavior: 'auto'});
        this.setState({pesquisaTxt: value}, () => {
            this.updateDimensions();
        });
    }

    abrePesquisa = (pesquisaAberta) => {
        if (pesquisaAberta){
            this.setState({
                scrollMarginTop: 125
            });
        }else{
            this.setState({
                scrollMarginTop: 75
            });
        }        
    }

    comparaTexto(val1, val2){

        var arrPalavras = val2.split(' ');
    
        var resultado = true;
        arrPalavras.map((palavra, index) => {
          if (!this.trataTexto(val1).includes(this.trataTexto(palavra))){
            resultado = false;
          }
        });
    
        return resultado;
    }

    trataTexto(val){
        return (
            val.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        );
    }

    handleLoad() {
        let localCart = global.ProductObj.getCarrinhoCompra();
        localCart = JSON.parse(localCart);
        //load persisted cart into state if it exists
        if (!localCart) {
            localCart = [];
        }

        this.setState({ 
            carrinhoCompra: localCart,
        });    
    }

    logaUsu = () => {
        try {
            // here place your login logic     
                  
            var CancelToken = axios.CancelToken;

            const {log_senha, log_usu} = this.state;
        
            var parametros = 
            "&parusu="    + log_usu   +
            "&parsenha="  + log_senha   +
            "&parres=" + global.cod_restaurante +
            "&pargrures=" + global.cod_grurest +
            "&parverapp=" + '99';
    
            let source = CancelToken.source();
            setTimeout(() => {
                source.cancel();
            }, global.timeOutTimer);

            
            
            axios.post(
                global.siteurl + 'cliente_login.php', 
                parametros,
                {
                cancelToken: source.token,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                },
                withCredentials: true,
            }).then((response) => {
                var responseData = response.data;
                
                localStorage.setItem(global.chaveLocalLogin, responseData.jwt);
                
            })
            .catch((error) =>{
                
                if(axios.isCancel(error)){
    
                    BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{text: 'OK', onPress: () => {}},],{cancelable: false},);
            
                }
                else{
            
                    
                    BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{text: 'OK', onPress: () => {}},],{cancelable: false},);
            
                }
            });

        } catch (err) {
            
        }        
    }

    verificaCampos(cep, estado, cidade, bairro, rua, numero, referencia) {
        var r = true;
        var msg = '';
        
        if (cep.trim().length != 9){
          r = false;
          msg = 'O CEP precisa ser preenchido corretamente';
        }else if (estado.trim().length != 2){
          r = false;
          msg = 'O Estado precisa ser informado corretamente';
        }else if (cidade.trim().length <= 4){
          r = false;
          msg = 'A Cidade precisa ser informada corretamente';
        }else if (bairro.trim().length <= 4){
          r = false;
          msg = 'O Bairro precisa ser informado corretamente';
        }else if (rua.trim().length <= 4){
          r = false;
          msg = 'A Rua precisa ser informada corretamente';
        }else if (numero.trim().length <= 0){
          r = false;
          msg = 'Insira o número da sua residência';
        }else if (referencia.trim().length <= 4){
          r = false;
          msg = 'A Referência precisa ser informada corretamente';
        }
    
        if (!r){
          BS_ALE(this,  'Atenção', msg, [{text: 'OK', onPress: () => {}},],{cancelable: false},);
        }
    
        return r;
    }

    renderLogin(){

        const { classes } = this.props;

        return (
            <div>
                <div className={classes.loginForm}>
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                        <Home />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                        Login
                        </Typography>
                        <form className={classes.form} noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="log_usu"
                                label="Email"
                                name="log_usu"
                                autoComplete="email"
                                value={this.state.log_usu}
                                onChange={this.handleChange}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="log_senha"
                                label="Senha"
                                type="password"
                                id="log_senha"
                                autoComplete="current-password"
                                value={this.state.log_senha}
                                onChange={this.handleChange}
                            />
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={() => this.logaUsu()}
                                className={classes.submit}
                            >
                                Entrar
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                <Link href="#" variant="body2">
                                    Esqueceu sua senha?
                                </Link>
                                </Grid>
                                <Grid item>
                                <Link onClick={() => {
                                        let arrTmp = this.state.estadoLogin;
                                        arrTmp.push(1);
                                        this.setState({
                                            estadoLogin : arrTmp,
                                        });
                                    }} variant="body2">
                                    {"Não tem uma conta? Cadastre-se"}
                                </Link>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    getBairroByid = (array, id) => {
        return array.find((element) => {
            return element.gondor === id;
        })
    }
    

    cadastraEndereco = () => {

        const {cad_cep, cad_estado, cad_cidade, cad_bairro, cad_rua, cad_numero, cad_referencia, cad_complemento, cad_identificacao,} = this.state;

        
        try {
            // here place your signup logic     

            const arrBairro = this.getBairroByid(this.state.listaBairros, this.state.cad_bairro);

            const bairro    = (arrBairro) ? arrBairro.nome   : this.state.cad_bairro;
            const codbairro = (arrBairro) ? arrBairro.codigo : '';
            
            if (this.verificaCampos(cad_cep, cad_estado, cad_cidade, bairro, cad_rua, cad_numero, cad_referencia)){
      
                var CancelToken = axios.CancelToken;
      
                //Usuario Apple
                //cpf = '123.123.123-87w';
                //senha = 'rYA33Q9q';

                var parametros = 
                "partoken="      + this.state.myToken             +
                "&parres="       + global.cod_restaurante         +
                "&parverapp="    + global.versaoApp               +
                "&pargrures="    + global.cod_grurest             +
                "&partipo="      + this.state.estadoCEP           +
                "&parindentif="  + ( (this.state.cad_identificacao == 0) ? "" : optionsIdentificacao[this.state.cad_identificacao] ) +
                "&parcep="       + cad_cep.replace('-', '')       +
                "&parest="       + cad_estado                     +
                "&parcidade="    + cad_cidade                     +
                "&parcodbairro=" + codbairro                      +
                "&parbairro="    + bairro                         +
                "&parrua="       + cad_rua                        +
                "&parnumero="    + cad_numero                     +
                "&parcomp="      + cad_complemento                +
                "&parref="       + cad_referencia;

                let source = CancelToken.source();
                setTimeout(() => {
                    source.cancel();
                }, global.timeOutTimer);
      
              
                axios.post(
                    global.siteurl + 'cliente_endereco_insert.php', 
                    parametros,
                    {
                        cancelToken: source.token,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                        },
                        withCredentials: true,
                    }).then((response) => {
                        
                        var responseData = response.data;
                     
                        if (responseData.resposta == '1') {
                            if (this.props.location.state){
                                if (this.props.location.state.finalizandoCad){
                                    this.props.navigate({
                                        pathname: "/finaliza-compra",
                                        state: {
                                            stateFinalizaCompra: this.props.location.state.stateFinalizaCompra
                                        }
                                    });
                                }else{
                                    this.props.navigate({
                                        pathname: "/login-page",
                                        state: {
                                            data: true,
                                        },
                                    });
                                }
                            }else{
                                this.props.navigate({
                                    pathname: "/login-page",
                                    state: {
                                        data: true,
                                    },
                                });
                            }
                            
                        }else{
                            BS_ALE(this,  'Atenção', responseData.msg , [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
                        }
                    })
                    .catch((error) =>{
                        if(axios.isCancel(error)){
          
                            BS_ALE(this,  'Atenção', error.message, [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
                  
                        }
                        else{
                  
                            BS_ALE(this,  'Atenção', error.message, [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
                  
                        }
                    });
      
            }else{
                
            }
        } catch (err) {
            
        }
    }

    pesquisaCEP(CEP) {

        try {

            if (this.state.cad_cep.trim().length == 9){
              var CancelToken = axios.CancelToken;
          
              this.setState({
                estadoCEP: 0,
              });  
      
              let source = CancelToken.source();
              setTimeout(() => {
                source.cancel();
              }, global.timeOutTimer);
      
              var parametros = 
              //'parres='    + global.cod_restaurante   +
              'parres='     + global.cod_restaurante   +
              "&parverapp=" + global.versaoApp +
              '&parcep='    + this.state.cad_cep.replace('-', '');
              
              axios.post(
                global.siteurl + 'verifica_cep.php', 
                parametros,
                {
                   cancelToken: source.token,
                   headers: {
                       'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                   },
                   withCredentials: true,
                }).then((response) => {
                  var responseData = response.data;
                  if (responseData.resposta == '1') {
                      axios.post(
                        global.siteurl + 'pesquisa_cep.php', 
                        parametros,
                          {
                             cancelToken: source.token,
                             headers: {
                                 'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                             },
                             withCredentials: true,
                          }).then((response) => {
      
                            this.setState({
                              'cad_estado'      : '',
                              'cad_cidade'      : '',
                              'cad_bairro'      : '',
                              'cad_rua'         : '',
                              'cad_complemento' : '',
                              'cad_numero'      : '',
                              'cad_referencia'  : '',
                              'cad_identificacao' : '',
                            });
      
                            if (response.data.resposta == "1"){
                              //responseData = response.data.registros;
      
                              responseData = response.data.registros;
                              
                              if (responseData.uf != null) {
                                this.setState({
                                    'cad_estado' : responseData.uf,    
                                    'cad_cidade' : responseData.localidade,
                                });
        
                                if ((responseData.bairro != null) && (responseData.bairro.length > 0)) {
            
                                    this.setState({
                                        estadoCEP     : 1,
                                        'cad_bairro'      : responseData.bairro,    
                                        'cad_rua'         : responseData.logradouro,
                                    });

                                }else{
                                  axios.post(
                                    global.siteurl + 'lista_bairros.php', 
                                    'parresid=' + global.cod_restaurante +
                                    "&parverapp=" + global.versaoApp +
                                    '&parclicep=' + this.state.cad_cep.replace('-', ''),
                                    {
                                       cancelToken: source.token,
                                       headers: {
                                           'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                                       }
                                    }).then((response) => {
                                      responseData = response.data;
                                      
                                      if (responseData.resposta == '1') {

                                        if (responseData.apidist == '1'){
                                            this.setState({
                                                estadoCEP     : 1
                                            });
                                        }else{
                                            this.setState({
                                                estadoCEP: 2,
                                                listaBairros: response.data.registros,
                                            });
                                        }

                                      }else{                          
                                        BS_ALE(this,  'Atenção', 'Por enquanto ainda não atendemos o endereço para o CEP informado.', [{text: 'OK', onPress: () => {}},],{cancelable: false},);
                                      }
                                  })
                                  .catch((error) =>{
                                                                       
                                    if(axios.isCancel(error)){
                          
                                      BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 025', [{text: 'OK', onPress: () => {}},],{cancelable: false},);
                                  
                                    }
                                    else{
                                  
                                      BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 026', [{text: 'OK', onPress: () => {}},],{cancelable: false},);
                                  
                                    }
        
                                    
                                  });
                                }
        
                                
                              }else{
                                BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 027', [{text: 'OK', onPress: () => {}},],{cancelable: false},);
                              }
                            }else{
                              BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 028', [{text: 'OK', onPress: () => {}},],{cancelable: false},);
                            }
                            
                        })
                        .catch((error) =>{
                          if(axios.isCancel(error)){
                
                            BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 029', [{text: 'OK', onPress: () => {}},],{cancelable: false},);
                        
                          }
                          else{
                        
                            BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 030', [{text: 'OK', onPress: () => {}},],{cancelable: false},);
                        
                          }
                        });
                  }else{
                    BS_ALE(this,  'Atenção', 'Por enquanto ainda não atendemos o endereço para o CEP informado.', [{text: 'OK', onPress: () => {}},],{cancelable: false},);
                  }
              })
              .catch((error) =>{
                if(axios.isCancel(error)){
      
                  BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 033', [{text: 'OK', onPress: () => {}},],{cancelable: false},);
              
                }
                else{
              
                  BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 034', [{text: 'OK', onPress: () => {}},],{cancelable: false},);
              
                }
              });
            }else{
              BS_ALE(this, 'Atenção', 'Informe um CEP válido e clique no botão VERIFICAR')
            }
        } catch (err) {
            
        }                      

    }

    handleDropChange = (event) => {
        this.setState({
            cad_bairro : event.target.value
        });
    };

    handleIdentificacaoChange = (event) => {
        this.setState({
            cad_identificacao : event.target.value,
        });
    };

    renderBairrosItens = () => {
        return this.state.listaBairros.map((value, index) => {
            return (
                <MenuItem value={value.gondor}>{value.nome}</MenuItem>
            );
        });
    }

    _renderBairro = () => {

        const { classes } = this.props;
    
        if (this.state.estadoCEP == 2){
          return (
            <FormControl variant="outlined" required className={classes.enderecoInputs} style={{width: '100%', marginTop: '8px'}}>
                <InputLabel id="demo-simple-select-outlined-label">Bairro</InputLabel>
                <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={this.state.cad_bairro}
                onChange={this.handleDropChange}
                label="Bairro"
                >
                    {this.renderBairrosItens()}
                </Select>
            </FormControl>);
        } else {
          return (
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="cad_bairro"
                label="Bairro"
                name="cad_bairro"
                className={classes.enderecoInputs}
                value={this.state.cad_bairro}
                onChange={this.handleChange}
                inputProps={{
                    maxLength: 40
                }}
            />
          );
        }    
    } 

    renderFormEndereco = () => {

        const { classes } = this.props;

        if ( (this.state.estadoCEP == 1) || (this.state.estadoCEP == 2) ){
            return (
            <div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="cad_estado"
                        label="Estado"
                        name="cad_estado"
                        value={this.state.cad_estado}
                        onChange={this.handleChange}
                        className={classes.enderecoInputs}
                        autoCapitalize='characters'
                        style={{
                            width: '40%',
                            marginRight: '10px',
                        }}
                        inputProps={{
                            maxLength: 2,
                            style: {textTransform: 'uppercase'}
                        }}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="cad_cidade"
                        label="Cidade"
                        name="cad_cidade"
                        value={this.state.cad_cidade}
                        className={classes.enderecoInputs}
                        onChange={this.handleChange}
                        inputProps={{
                            maxLength: 20
                        }}
                    />
                </div>
                {this._renderBairro()}
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="cad_rua"
                    label="Rua"
                    name="cad_rua"
                    autoComplete="address"
                    value={this.state.cad_rua}
                    className={classes.enderecoInputs}
                    onChange={this.handleChange}
                    inputProps={{
                        maxLength: 50
                    }}
                />

                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="cad_numero"
                        label="Nº"
                        name="cad_numero"
                        value={this.state.cad_numero}
                        className={classes.enderecoInputs}
                        onChange={this.handleChange}
                        style={{
                            width: '40%',
                            marginRight: '10px',
                        }}
                        inputProps={{
                            maxLength: 5
                        }}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="cad_complemento"
                        label="Complemento"
                        name="cad_complemento"
                        value={this.state.cad_complemento}
                        className={classes.enderecoInputs}
                        onChange={this.handleChange}
                        inputProps={{
                            maxLength: 20
                        }}
                    />
                </div>
                
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="cad_referencia"
                    label="Referência"
                    name="cad_referencia"
                    value={this.state.cad_referencia}
                    className={classes.enderecoInputs}
                    onChange={this.handleChange}
                    inputProps={{
                        maxLength: 40
                    }}
                />
                
                <Tooltip title={"Uma etiqueta para seu endereço, para mantê-los organizados"} 
                    open={this.state.identFocused}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    placement="top" classes={{ tooltip: classes.myTooltip }}>
                    <span>
                        <FormControl variant="outlined" required className={classes.enderecoInputs} style={{width: '100%', marginTop: '8px'}}>
                            <InputLabel id="select_identificacao_label">Identificação</InputLabel>
                            <Select
                            labelId="select_identificacao_label"
                            id="select_identificacao"
                            value={this.state.cad_identificacao}
                            onChange={this.handleIdentificacaoChange}
                            label="Identificação"
                            >
                                {optionsIdentificacao.map( (value, index) => {
                                    return (<MenuItem value={index} name="">{value}</MenuItem>);
                                })}
                            </Select>
                        </FormControl>                              
                    </span>
                </Tooltip>
                
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => this.cadastraEndereco()}
                    style={{backgroundColor: '#3496eb'}}
                >
                    Adicionar Endereço
                </Button>
            </div>);
        }else{
            return (<div></div>)
        }
    }

    renderCadastro() {

        const { classes } = this.props;
        var estadoCEP = this.state.estadoCEP;


        return (
            <div>
                <div className={classes.addEndForm}>
                    <div className={classes.paper}>
                        <form className={classes.form} style={{marginTop: '25px'}} noValidate>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="cad_cep"
                                    label="CEP"
                                    type="text"
                                    id="cad_cep"
                                    autoComplete="text"
                                    className={classes.enderecoInputs}
                                    InputProps={{
                                        inputComponent: CEPMaskInput,
                                        value: this.state.cad_cep,
                                        onChange: this.handleChange,
                                        onKeyDown: (event) => {
                                            //!@#!@#
                                            if (event.key === 'Enter' ) {
                                                event.preventDefault();
                                                this.pesquisaCEP(this.state.cad_cep);
                                            }
                                        }
                                    }}
                                />

                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={() => {
                                        this.pesquisaCEP(this.state.cad_cep);
                                    }}
                                    style={{
                                        backgroundColor: '#3496eb',
                                        height: 'fit-content',
                                        marginTop: 'auto',
                                        marginBottom: 'auto',
                                        marginLeft: '20px',
                                        width: 'fit-content',
                                    }}
                                >
                                    Verificar
                                </Button>
                            </div>

                            {this.renderFormEndereco()}

                            
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    renderTiposCampos() {
        var estadoLogin = this.state.estadoLogin[this.state.estadoLogin.length - 1];
        //return this.renderPagCli();

        return this.renderCadastro();
    }

    renderHeader = () => {

        return (
            <div style={{flexGrow: 1, justifyContent:'center', alignItems: 'center', fontSize: '20px', color: '#fff' }}>
                <IconButton
                color="inherit"
                style={{padding: '7px'}}
                onClick={ () => {
                    
                    if (this.props.location.state){
                        if (this.props.location.state.finalizandoCad){
                            this.props.navigate({
                                pathname: "/finaliza-compra",
                                state: {
                                    stateFinalizaCompra: this.props.location.state.stateFinalizaCompra
                                }
                            });
                        }else{
                            this.props.navigate("/login-page");
                        }
                    }else{
                        this.props.navigate("/login-page");
                    }                    
                } }
                >
                  <ArrowBack />
                </IconButton>
                Adicionar Endereço
            </div>
        );
    }

    render() {

        const { classes } = this.props;

        return (
            <div className={classes.myBody} style={{marginTop: '0px'}}>
                <div className={classes.container}>
                    <Card style={{minHeight: '100vh'}}>
                        <form className={classes.form} style={{marginTop: '0px'}}>
                            <Header context={this} defaultIcon={false} myBackgroundColor={"#222"} leftLinks={
                                <div style={{ whiteSpace: 'nowrap'}}>
                                    <div style={{width: 'max-content', whiteSpace: 'nowrap', flexDirection: 'column', marginTop: '12px', marginBottom: '12px', }} className={""}>
                                        {this.renderHeader()}
                                    </div>                                    
                                </div>
                            } />
                            <CardBody>
                                {this.renderTiposCampos()}
                            </CardBody>
                        </form>                
                    </Card>
                </div>
                <Footer qtdeTiposProds={this.state.carrinhoCompra.length} whiteFont />
            </div>
        );
    }


    render333() {

        const { classes } = this.props;

        return (
            <div className={classes.myBody} style={{marginTop: '0px'}}>
                <div className={classes.container}>
                    <Card>
                        <form className={classes.form} style={{marginTop: '0px'}}>
                            <Header context={this} myBackgroundColor={"#222"} defaultIcon={false} leftLinks={
                                <div style={{ whiteSpace: 'nowrap'}}>
                                    <div style={{width: 'max-content', whiteSpace: 'nowrap', flexDirection: 'column', marginTop: '12px', marginBottom: '12px', }} className={""}>
                                        {this.renderHeader()}
                                    </div>                                    
                                </div>
                            } />
                            <CardBody>
                                {this.renderCadastro()}
                            </CardBody>
                        </form>                
                    </Card>
                </div>
                <Footer qtdeTiposProds={this.state.carrinhoCompra.length} whiteFont />
            </div>
        );
    }
}

export default withStyles(LoginPage, styles); ;
