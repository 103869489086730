import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from 'react-router-dom';

window.addEventListener('customEvent', (event) => {

    console.log("aaaaaaa");
    global.tableApp = event.detail.tableApp;

    if (global.tableApp) {
        console.log("bbbbb");
        document.getElementById('myButton').style.display = 'block';
    } else {
        console.log("ccccccc");
        document.getElementById('myButton').style.display = 'none';
    }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter basename='/'>
    <App />
  </HashRouter>
);

reportWebVitals();