import React, { useState, useEffect } from "react";
// @mui/material components
import { withStyles } from "tss-react/mui";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "@mui/material/Icon";
// @mui/icons-material
import Email from "@mui/icons-material/Email";
import People from "@mui/icons-material/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import styles from "assets/jss/material-kit-react/views/principalPage.js";
import  "assets/css/PrincipalPage.css";

import image from "assets/img/bg7.jpg";
import { Message } from "@mui/icons-material";

import { Dialog, DialogContent, } from "@mui/material";

import 'prevent-pull-refresh';

import axios from 'axios';

import myCookies from 'universal-cookie';

import { renovaJWT, BS_ALE, removeEComercial, geraJson } from 'funcs/MyFuncs.js';

import Confirmacao from "components/Dialogs/Confirmacao.js";

import { useNavigate } from "react-router-dom";

const delayProcessando     = 0;
const tempoMinProcess      = 0;

const tp_status_pedido_enviado = 0;
const tp_status_recebido       = 1;
const tp_status_em_preparo     = 2;
const tp_status_saiu_entrega   = 3;
const tp_status_entregue       = 4;
const tp_status_concluido      = 5;
const tp_status_recusado       = 6;
const tp_status_pendente       = 99;

const tp_pagamento_rest_conhecido = 73;

const tp_pagamento_pendente       = 80;
const tp_pagamento_processando    = 81;
const tp_pagamento_rejeitado      = 82;
const tp_pagamento_restituido     = 83;
const tp_pagamento_cancelado      = 84;
const tp_pagamento_mediacao       = 85;
const tp_pagamento_expirado       = 86;

class PedidoLista extends React.Component {

    arrPosItems  = [];    
    arrMenuItems = [];
    cardBody     = null;
    myMenu       = null;
    cabecalho    = null;

    ultElementMenu = -1;

    myTmpProds     = [];

    constructor(props) {
        super(props);
        // Não chame this.setState() aqui!

        let localCart = global.ProductObj.getCarrinhoCompra();
        localCart = JSON.parse(localCart);
        //load persisted cart into state if it exists
        if (!localCart) {
            localCart = [];
        }

        this.state = {
            carrinhoCompra: localCart, 
            pesquisaTxt : global.ProductObj.pesquisaProd,
            listaGruPro: [], usr_id: 12, usr_grures: 6, scrollMarginTop: (global.ProductObj.pesquisaProd.length > 0) ? 170 : 120,
            grupos_prods: [null, null, null, null, null, null, null, null],
            nomeRest: "",
            restID: "" ,
            grupoRest: "",
            promocao: "0",
            itemAtivo: 0,
            posItemClicado: -1,
            eventClicado: -1,
            listaPedidos: [],
            alertaIndisponiveis : false,
            produtosDispQtd: 0,
            prodsIndisp: [],
            alertaConfCopia: false,
            copiaPedido: [],
        };

        this.updateDimensions = this.updateDimensions.bind(this);
        this.carregaDados = this.carregaDados.bind(this);

    }

    setScrollToTop = () => {

        window.scrollTo(0, 0);
        
    }

    preencheStatusRetirada = () => {
        let arrStatus = [];
    
        arrStatus.push({
          'ID'       : tp_status_pedido_enviado,
          'Status'   : 'Enviado à Loja',
          'Mensagem' : 'Você enviou seu pedido com sucesso, aguarde uma resposta da Loja.',
          'Icone'    : 'paper-plane',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_status_recebido,
          'Status'   : 'Recebido pela Loja',
          'Mensagem' : 'Nós já recebemos seu pedido.',
          'Icone'    : 'store-alt',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_status_em_preparo,
          'Status'   : 'Em Preparo',
          'Mensagem' : 'Estamos preparando seu pedido, dentro de alguns minutos você pode vir retirá-lo.',
          'Icone'    : 'clock',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_status_saiu_entrega,
          'Status'   : 'Pronto para Retirada',
          'Mensagem' : 'Seu pedido está pronto para ser retirado.',
          'Icone'    : 'hands',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_status_entregue,
          'Status'   : 'Retirado',
          'Mensagem' : 'Bom apetite !!!',
          'Icone'    : 'gift',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_status_concluido,
          'Status'   : 'Concluido',
          'Mensagem' : 'Esse aqui já foi, que tal pedir mais um? rs',
          'Icone'    : 'check-double',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_status_recusado,
          'Status'   : 'Recusado',
          'Mensagem' : 'Infelizmente não podemos atender seu pedido, para mais informações entre em contato conosco pelo telefone: ' + global.tel_restaurante,
          'Icone'    : 'times-circle',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_status_pendente,
          'Status'   : 'Pendente',
          'Mensagem' : 'O pedido necessita de SUA CONFIRMAÇÃO para ser enviado, clique para mais informações',
          'Icone'    : 'exclamation-triangle',
          'Fonte'    : "FontAwesome5",
        });
    
        // Pagamento Online
    
        arrStatus.push({
          'ID'       : tp_pagamento_pendente,
          'Status'   : 'Pagamento pendente',
          'Mensagem' : 'Estamos esperando pelo seu pagamento.',
          'Icone'    : 'credit-card',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_pagamento_processando,
          'Status'   : 'Pagamento em processamento',
          'Mensagem' : 'Estamos processando seu pagamento.',
          'Icone'    : 'network-wired',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_pagamento_rejeitado,
          'Status'   : 'Pagamento Rejeitado',
          'Mensagem' : 'Infelizmente seu pagamento não pôde ser concluído.',
          'Icone'    : 'exclamation-triangle',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_pagamento_rest_conhecido,
          'Status'   : 'Pagamento restituido',
          'Mensagem' : 'O pagamento foi devolvido ao cliente.',
          'Icone'    : 'undo',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_pagamento_restituido,
          'Status'   : 'Pagamento restituido',
          'Mensagem' : 'O pagamento foi devolvido ao cliente.',
          'Icone'    : 'undo',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_pagamento_cancelado,
          'Status'   : 'Pagamento Cancelado',
          'Mensagem' : 'O pagamento foi cancelado.',
          'Icone'    : 'window-close',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_pagamento_mediacao,
          'Status'   : 'Pagamento em mediação',
          'Mensagem' : 'O seu pagamento está em mediação.',
          'Icone'    : 'hourglass-end',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_pagamento_expirado,
          'Status'   : 'Tempo do pagamento expirado',
          'Mensagem' : 'O tempo disponível para pagamento acabou.',
          'Icone'    : 'hourglass-end',
          'Fonte'    : "FontAwesome5",
        });
    
    
        return arrStatus;
    }

    preencheStatus = () => {
        let arrStatus = [];
    
        arrStatus.push({
          'ID'       : tp_status_pedido_enviado,
          'Status'   : 'Enviado à Loja',
          'Mensagem' : 'Você enviou seu pedido com sucesso, aguarde uma resposta da Loja.',
          'Icone'    : 'paper-plane',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_status_recebido,
          'Status'   : 'Recebido pela Loja',
          'Mensagem' : 'Nós já recebemos seu pedido.',
          'Icone'    : 'store-alt',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_status_em_preparo,
          'Status'   : 'Em Preparo',
          'Mensagem' : 'Estamos preparando seu pedido.',
          'Icone'    : 'clock',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_status_saiu_entrega,
          'Status'   : 'Saiu para Entrega',
          'Mensagem' : 'Seu pedido já está chegando, falta pouco ;)',
          'Icone'    : 'motorcycle',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_status_entregue,
          'Status'   : 'Entregue',
          'Mensagem' : 'Bom apetite !!!',
          'Icone'    : 'gift',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_status_concluido,
          'Status'   : 'Concluido',
          'Mensagem' : 'Esse aqui já foi, que tal pedir mais um? rs',
          'Icone'    : 'check-double',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_status_recusado,
          'Status'   : 'Recusado',
          'Mensagem' : 'Infelizmente não podemos atender seu pedido, para mais informações entre em contato conosco pelo telefone: ' + global.tel_restaurante,
          'Icone'    : 'times-circle',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_status_pendente,
          'Status'   : 'Pendente',
          'Mensagem' : 'O pedido necessita de SUA CONFIRMAÇÃO para ser enviado, clique para mais informações',
          'Icone'    : 'exclamation-triangle',
          'Fonte'    : "FontAwesome5",
        });
    
        // Pagamento Online
    
        arrStatus.push({
          'ID'       : tp_pagamento_pendente,
          'Status'   : 'Pagamento pendente',
          'Mensagem' : 'Estamos esperando pelo seu pagamento.',
          'Icone'    : 'credit-card',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_pagamento_processando,
          'Status'   : 'Pagamento em processamento',
          'Mensagem' : 'Estamos processando seu pagamento.',
          'Icone'    : 'network-wired',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_pagamento_rejeitado,
          'Status'   : 'Pagamento Rejeitado',
          'Mensagem' : 'Infelizmente seu pagamento não pôde ser concluído.',
          'Icone'    : 'exclamation-triangle',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_pagamento_rest_conhecido,
          'Status'   : 'Pagamento restituido',
          'Mensagem' : 'O pagamento foi devolvido ao cliente.',
          'Icone'    : 'undo',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_pagamento_restituido,
          'Status'   : 'Pagamento restituido',
          'Mensagem' : 'O pagamento foi devolvido ao cliente.',
          'Icone'    : 'undo',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_pagamento_cancelado,
          'Status'   : 'Pagamento Cancelado',
          'Mensagem' : 'O pagamento foi cancelado.',
          'Icone'    : 'window-close',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_pagamento_mediacao,
          'Status'   : 'Pagamento em mediação',
          'Mensagem' : 'O seu pagamento está em mediação.',
          'Icone'    : 'hourglass-end',
          'Fonte'    : "FontAwesome5",
        });
    
        arrStatus.push({
          'ID'       : tp_pagamento_expirado,
          'Status'   : 'Tempo do pagamento expirado',
          'Mensagem' : 'O tempo disponível para pagamento acabou.',
          'Icone'    : 'hourglass-end',
          'Fonte'    : "FontAwesome5",
        });
    
    
        return arrStatus;
    }

    carregaDadosNoLogin = (nologin_hash) => {
        
        try {
            // here place your login logic     
                  
            var CancelToken = axios.CancelToken;

            const {log_senha, log_usu} = this.state;
        
            var parametros = 
            "partoken="    + ((global.myToken) ? global.myToken : '') +
            "&pargrures=" + global.cod_grurest +
            "&parres=" + global.cod_restaurante +
            "&nologin_hash=" + nologin_hash +
            "&parverapp=" + '99';
    
            let source = CancelToken.source();
            setTimeout(() => {
                source.cancel();
            }, global.timeOutTimer);        
            
            axios.post(
                global.siteurl + 'pedido_lista.php', 
                parametros,
                {
                cancelToken: source.token,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                },
                withCredentials: true,
            }).then((response) => {
                var responseData = response.data;
                if (responseData.resposta == "1"){
                
                    //alert( JSON.stringify(responseData));

                    let arrStatus = this.preencheStatus();
                    let arrStatusRetirada = this.preencheStatusRetirada();

                    this.setState({
                        listaPedidos : responseData.PEDIDOS,
                        listaStatus  : arrStatus,
                        listaStatusRetirada : arrStatusRetirada,
                    }); 
                    
                }else{
                    if (responseData.tknsts == "0"){
                        BS_ALE(this,  'Atenção', 'Você precisa entrar na sua conta para visualizar seus pedidos.');
                        
                        this.props.navigate("/login-page");
                    }else{
                        BS_ALE(this,  'Atenção', responseData.msg);
                    }
                }
            })
            .catch((error) =>{
                
                if(axios.isCancel(error)){
    
                    BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
            
                }
                else{
            
                    BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
            
                }
            });

        } catch (err) {
            
        }  
    }

    carregaDados = () => {
        
        if (global.myToken){
            
            try {
                // here place your login logic     
                      
                var CancelToken = axios.CancelToken;
    
                const {log_senha, log_usu} = this.state;
            
                var parametros = 
                "&partoken="    + global.myToken +
                "&pargrures=" + global.cod_grurest +
                "&parres=" + global.cod_restaurante +
                "&parverapp=" + '99';
        
                let source = CancelToken.source();
                setTimeout(() => {
                    source.cancel();
                }, global.timeOutTimer);        
                
                axios.post(
                    global.siteurl + 'pedido_lista.php', 
                    parametros,
                    {
                    cancelToken: source.token,
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                    },
                    withCredentials: true,
                }).then((response) => {
                    var responseData = response.data;
                    if (responseData.resposta == "1"){
                    
                        //alert( JSON.stringify(responseData));

                        let arrStatus = this.preencheStatus();
                        let arrStatusRetirada = this.preencheStatusRetirada();

                        this.setState({
                            listaPedidos : responseData.PEDIDOS,
                            listaStatus  : arrStatus,
                            listaStatusRetirada : arrStatusRetirada,
                        }); 
                        
                    }else{
                        if (responseData.tknsts == "0"){
                            BS_ALE(this,  'Atenção', 'Você precisa entrar na sua conta para visualizar seus pedidos.');
                            
                            this.props.navigate("/login-page");
                        }else{
                            BS_ALE(this,  'Atenção', responseData.msg);
                        }
                    }
                })
                .catch((error) =>{
                    
                    if(axios.isCancel(error)){
        
                        BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 021', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
                
                    }
                    else{
                
                        BS_ALE(this,  'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão e tente novamente. \nCOD 022', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
                
                    }
                });
    
            } catch (err) {
                
            }       
        }else{
            this.setState({
                loading: false,
            });
        }
    }

    componentDidMount(){

        var parametros = 'parresid='  + this.state.usr_id + "&parverapp=" + 23;
        this.setState({ 'erroDB' : false });

        this.updateDimensions();
        //window.addEventListener('scroll', this.updateDimensions);
        window.addEventListener('scroll', this.updateDimensions)

        try{

            let localCart = global.ProductObj.getCarrinhoCompra();
            localCart = JSON.parse(localCart);
            //load persisted cart into state if it exists
            if (!localCart) {
                localCart = [];
            }

            let meuToken = localStorage.getItem(global.chaveLocalLogin);
            meuToken = meuToken;

            global.myToken = meuToken;
            this.setState({ 
                myToken        : meuToken,
                carrinhoCompra : localCart,
                estadoLogin    : [0]
            }, () => {

                

                //!@#!@#
                const cookies = new myCookies();
                var nologin_hash = global.intercept_nologin_hash;

                nologin_hash = (nologin_hash) ? nologin_hash : '';

                if ( (global.myToken != '') && (global.myToken)){
                    this.carregaDados();
                }else if ((nologin_hash?.length > 0)){
                    this.carregaDadosNoLogin(nologin_hash);
                }else{
                    this.props.navigate("/login-page");
                }
                
            });  

        }catch (err){
        //BS_ALE(this,  '', 'Não foi possível atualizar as informações, tente novamente mais tarde', [{text: 'OK', onPress: () => this.closeProgress()},],{cancelable: false},);
            this.setState({ 'erroDB' : true });
        } 
        //this.verificaAtt();
        
        this.setScrollToTop();
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.updateDimensions);
    }
    
    updateDimensions() {
        var alturaScroll = document.body.scrollHeight - window.innerHeight;
        var scrolledY    = window.scrollY;

        var alturaItems  = 0;

        this.arrPosItems.map((item, index) => {
            alturaItems += (item) ? item.clientHeight : 0;
        });

        var proporcao = alturaItems / (alturaScroll);

        var tmpPos = 0;
        var itemAtual = 0;

        for (var i = 0; i < this.arrPosItems.length; i++){
            var tmpItem = this.arrPosItems[i];
            tmpPos += (tmpItem) ? tmpItem.clientHeight : 0;

            if ( (scrolledY * proporcao) < ( ( (this.cabecalho) ? tmpPos + (this.cabecalho.clientHeight) : tmpPos) - this.state.scrollMarginTop) ){
                break;
            }else{
                itemAtual = (i >= (this.ultElementMenu)) ? this.ultElementMenu : i + 1;
            }
        }        

        if (this.state.posItemClicado == -1){
            if (itemAtual !== this.state.itemAtivo) {
                this.setState({ itemAtivo: itemAtual});

                if (itemAtual > 0){
                    this.myMenu.scrollTo({left: this.arrMenuItems[itemAtual].offsetLeft - 30, behavior: 'smooth'})
                }else{
                    this.myMenu.scrollTo({left: 0, behavior: 'smooth'})
                }
            }
        }else if (scrolledY == this.state.posItemClicado){

            if (this.state.eventClicado == -1){
                if (itemAtual !== this.state.itemAtivo) {
                    this.setState({ itemAtivo: itemAtual});
                }
    
                if (itemAtual > 0){
                    this.myMenu.scrollTo({left: this.arrMenuItems[itemAtual].offsetLeft - 30, behavior: 'smooth'})
                }else{
                    this.myMenu.scrollTo({left: 0, behavior: 'smooth'})
                }
                this.setState({ 
                    posItemClicado: -1,
                });
            }else{
                this.myMenu.scrollTo({left: this.arrMenuItems[this.state.eventClicado].offsetLeft - 30, behavior: 'smooth'})
                this.setState({ 
                    itemAtivo: this.state.eventClicado,
                    posItemClicado: -1,
                    eventClicado : -1,
                });
            }
        }else{
            if (itemAtual !== this.state.itemAtivo) {
                this.setState({ itemAtivo: itemAtual});
            }
        }


    }

    atualizaPadraoDB = async (parametros, metodo, tabelaNome, arrLista) => {
        
        try {
    
            var CancelToken = axios.CancelToken;
    
            
            let source = CancelToken.source();
            setTimeout(() => {
              source.cancel();
              
            }, 20000);
    
            
            await axios.post(
              global.urlServer + metodo, 
              parametros,
              {
                cancelToken: source.token,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                }
              }).then((response) => {
                var responseData = response.data;
                if (responseData.resposta == '1') {
                  arrLista = JSON.parse(JSON.stringify(responseData.registros));
                  
                  var arrFinal = [];
    
                  if (arrLista){
                    arrLista.map((prop, key) => {
                      arrFinal.push({
                        key : key, 
                        codInterno : prop.idGrupo, 
                        nome : prop.nome, 
                        precoDelivery : prop.posicao, 
                        descricao : global.urlServer + 'imgs/' + this.state.usr_grures + '/' + prop.cor + '.png',
                        image: global.urlServer + 'imgs/' + this.state.usr_grures + '/' + prop.cor + '.png',
                      });
                    });
                  }
                  
    
                  
                  this.setState({
                    listaGruPro: arrFinal,
                  });
                }else{
                  this.setState({
                    erroDB : true,
                  });
                }
            })
            .catch((error) =>{
              if (error == '785936'){
    
              }else if(axios.isCancel(error)){
    
              }else{
            
    
              }
            });
        } catch (err) {
          
        }    
      } 

    clickProd(item){
        alert('Hello: ' + item.nome);
    }

    scrollToItem = (index) => {
        //alert('Hello: ' + index);
        //this.arrPosItems[index].scrollIntoView({behavior: 'smooth'});

        var offsetNextScroll = 80;

        var alturaScroll = document.body.scrollHeight - window.innerHeight - this.state.scrollMarginTop;
        var scrolledY    = window.scrollY;

        var alturaItems  = 0;

        this.arrPosItems.map((item, index) => {
            alturaItems += (item) ? item.clientHeight : 0;
        });

        var proporcao = (alturaItems) / alturaScroll;

        var posicaoItem  = 0;

        this.arrPosItems.map((item, itemIndex) => {
            if (itemIndex == index){
                posicaoItem = (item) ? ( (item.offsetTop + this.cardBody.offsetTop) - this.state.scrollMarginTop ) : 0;
            }
        });

        /*for (var i = 0; i < (index); i++){
            var tmpItem = this.arrPosItems[i];
            posicaoItem += (tmpItem) ? tmpItem.clientHeight : 0;
        }

        posicaoItem -= this.state.scrollMarginTop;
        posicaoItem += this.cabecalho.clientHeight;
        posicaoItem = posicaoItem / proporcao;
        posicaoItem += offsetNextScroll;
        posicaoItem = Math.round(posicaoItem);*/

        window.scrollTo({top: posicaoItem, behavior: 'smooth'});

        this.setState({
            posItemClicado : posicaoItem,
            eventClicado   : index,
        });
        
    }

    renderProdutos = (items) => {

        const { classes } = this.props;
        var resultado = null;

        items = Array.from(new Set(items));

        return items.map((value, index) => {

            var saboresNome = value.PRODUTO;

            return (
                <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                        <div style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
                            <text style={{fontSize: '16px', color: '#000', fontWeight: '420'}} >{saboresNome}</text>
                        </div>
                    </div>
                    <div style={{margin: '10px 0'}} className={classes.separator}></div>
                </div>
            );
            
            
        });
        /*return produtos.map( (value, index) => {
            return (
                <div>{value.sabores[0].produto.nomeProduto}</div>
            );
        });*/
    }

    renderDialogListaProdIndisp = () => {

        const { classes } = this.props;
        
        var produtos = this.state.prodsIndisp;
        
        if ((this.state.alertaIndisponiveis)){

            var mensagem = 'Os produtos abaixo não foram adicionados ao carrinho porque não estão disponíveis atualmente.';

            if (this.state.produtosDispQtd > 0){
                mensagem += ' Os demais produtos foram adicionados e na próxima tela você poderá verificar as quantidades e valores para o novo pedido.';
            }

            if (produtos.length <= 0) {
                mensagem = 'Os produtos foram adicionados ao carrinho com sucesso. Na próxima tela você poderá verificar as quantidades e valores para o novo pedido.';
            }
    
            return(
              <Dialog
                  open={this.state.alertaIndisponiveis}
                  onClose={this.handleCloseAlerta}
                  disableBackdropClick 
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  classes={{ container: classes.root, paper: classes.paper }}
                  style={{maxWidth: '720px', margin: '0 auto 0 auto'}}
              >
                  <DialogContent>
                      <div style={{display: 'flex', flexDirection: 'column', marginTop: '0px', marginBottom: '0px'}}>
                          <div style={{width: '100%', }}><text style={{fontWeight: '415', color: '#aaa', fontSize: '24px', display: 'flex'}}>Atenção</text></div>
                          <div className={classes.separator} style={{marginTop: '12px', marginBottom: '12px', backgroundColor: '#aaa'}} ></div>
                          <div>
                            <div style={{marginBottom: '15px'}}>{mensagem}</div>
                            {this.renderProdutos(produtos)}
                          </div>
                          <div style={{display: 'flex', flexDirection: 'row', margin: '12px 0 12px 0', width: '100%'}}>
                              <Button onClick={() => {
                                    
                                    this.setState({alertaIndisponiveis: false}, () => {
                                        this.props.navigate('carrinho-compra');
                                    })
                                  }} color="primary" style={{backgroundColor: '#ddd', width: '100%', margin: '0 8px 0 0px', color: '#000'}}>
                                  Ok
                              </Button>
                          </div>
                      </div>
                  </DialogContent>
              </Dialog>
          );
        }
    }

    renderMenu = (items) => {
        const { classes } = this.props;
        var resultado = null;

        return items.map((item, index) => {

            if (item == null){
                return (
                    <div ref={(ref) => this.arrMenuItems[index] = ref} style={{marginRight: '10px', display: 'inline-block', width: '125px', height: '30px'}} className={'my-loading'}></div>
                ); // my-loading
            }

            var prodArrFinal = [];
            var primeiraCateg = true;
            item.produtos.filter(produto => (this.comparaTexto(produto.nomeProduto, this.state.pesquisaTxt) || this.comparaTexto(produto.descricao, this.state.pesquisaTxt))).map(
                filteredProduto => prodArrFinal.push(filteredProduto)
            );

            if (prodArrFinal.length > 0){
                if (index == this.state.itemAtivo){
                    return (
                        <div ref={(ref) => this.arrMenuItems[index] = ref} style={{marginRight: '10px', display: 'inline-block',}}>
                            <a onClick={() => {this.scrollToItem(index)}} style={{fontSize: '15px', fontWeight: 'bold', color: 'black'}}>{item.nome}</a>
                            <div style={{marginTop: '4px', backgroundColor: '#f00'}} className={classes.separatorMenu}></div>
                        </div>
                    );    
                }else{    
                    return (
                        <div ref={(ref) => this.arrMenuItems[index] = ref} style={{marginRight: '10px', display: 'inline-block',}}>
                            <a onClick={() => {this.scrollToItem(index)}} style={{fontSize: '15px', fontWeight: 'bold', color: 'black'}}>{item.nome}</a>
                        </div>
                    );                      
                }
                
            }else{
                return (
                    <div style={{ display: 'inline-block', }} ref={(ref) => this.arrMenuItems[index] = ref}>

                    </div>
                );
            }
            
        });
    }

    selectProd = (prod, grupoProd) => {

        
        
        global.ProductObj.setQtdeSabores(1, grupoProd, grupoProd.codigo, grupoProd.nome);
        global.ProductObj.setSabor(prod);
        this.props.navigate("/selecionaprod-seleciona-sabor");

    }

    selecionaQtdSabor = (qtdeSabore, grupoProd) => {

        

        global.ProductObj.setQtdeSabores(qtdeSabore, grupoProd, grupoProd.codigo, grupoProd.nome);
        this.props.navigate("/selecionaprod-seleciona-sabor");

    }

    renderQtdeSabores = (grupoProd) => {
        const { classes } = this.props;

        return (
            <div>
                <div onClick={(e) => this.selecionaQtdSabor(1, grupoProd)}>
                    <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                        <div>
                            <div>
                                <text style={{fontSize:'18px', fontWeight:'bold'}}>{grupoProd.nome} - 1 Sabor</text>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: '10px'}} className={classes.separator}></div>            
                </div>
                <div onClick={(e) => this.selecionaQtdSabor(2, grupoProd)}>
                    <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                        <div>
                            <div>
                                <text style={{fontSize:'18px', fontWeight:'bold'}}>{grupoProd.nome} - 2 Sabores</text>
                            </div>
                        </div>
                    </div>
                    <div style={{marginTop: '10px'}} className={classes.separator}></div>            
                </div>
            </div>
        );
    }

    renderProds = (grupoProd, prodArrFinal) => {
        const { classes } = this.props;

        return prodArrFinal.map((prod, index) => {
            return (
                <div onClick={() => { this.selectProd(prod, grupoProd) }}>
                    <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                        <div>
                            <div>
                                <text style={{fontSize:'16px', fontWeight:'bold'}}>{prod.nomeProduto}</text>
                            </div>
                            <div>
                                <text style={{display: 'inline-block', fontSize:'13px', lineHeight: '18px'}}>{prod.descricao}</text>
                            </div>  
                            <div>
                                <text style={{fontSize:'16px', fontWeight:'bold', color: '#777'}}>R$ {prod.preco.replace('.', ',')}</text>
                            </div>                  
                        </div>
                        <div style={{flex: 1, minWidth: '100px', height: '80px', display: "flex", justifyContent: "flex-end"}}>
                            <img src="https://images.rappi.com.br/products/855fef0d-b498-4e0c-b0dd-228eb443237b-1602546044927.png?d=128x90" style={{width: '80px', height: "80px"}}></img>
                        </div>
                    </div>    
                    <div style={{marginTop: '10px'}} className={classes.separator}></div>            
                </div>
            );
        });
    }

    verificaGrupoProd = ( grupoProd, prodArrFinal ) => {
        if (grupoProd.misto > 1){
            return this.renderQtdeSabores(grupoProd);
        }else{
            return this.renderProds(grupoProd, prodArrFinal);
        }
    }
    
    renderLista = (items) => {
        const { classes } = this.props;
        var resultado = null;

        return items.map((item, index) => {

            if (item == null){
                return (
                    <div className={"my-loading"}  ref={(ref) => this.arrPosItems[index] = ref} style={{ scrollMarginTop: (this.state.scrollMarginTop + "px"), maxWidth: '100%', marginLeft: '20px', marginRight: '20px', marginBottom: '30px', height: '150px'}}></div>
                ); // my-loading
            }

            var prodArrFinal = [];
            var primeiraCateg = true;
            item.produtos.filter(produto => (this.comparaTexto(produto.nomeProduto, this.state.pesquisaTxt) || this.comparaTexto(produto.descricao, this.state.pesquisaTxt))).map(
                filteredProduto => prodArrFinal.push(filteredProduto)
            );

            if (prodArrFinal.length > 0){
                this.ultElementMenu = index;
                return (
                    <div ref={(ref) => this.arrPosItems[index] = ref} style={{ scrollMarginTop: (this.state.scrollMarginTop + "px"), maxWidth: '100%', marginLeft: '20px', marginRight: '20px', marginBottom: '30px'}}>
                        <div style={{width: '100%',}}>
                            <text style={{marginRight: '30px', fontSize: '22px', color: '#a30000', fontWeight: 'bold'}} >{item.nome}</text>
                            <div style={{height: '2px'}} className={classes.separator}></div>
                        </div>                
                        <div>
                            {this.verificaGrupoProd(item, prodArrFinal)}
                        </div>
                    </div>
                );
            }else{
                return (
                    <div ref={(ref) => this.arrPosItems[index] = ref} >
                        
                    </div>
                );
            }

            /*return (
                <ListItem style={{scrollMarginTop: this.state.scrollMarginTop, }} key={index} ref={(ref) => this.arrPosItems.push(ref)} onClick={() => this.clickProd(item)} alignItems="flex-start">
                    <ListItemAvatar> 
                        <Avatar variant="square" imgProps={{width: '100%', height: 'unset'}} src={item.image} classes={{ img: classes.myAvatarImg }} className={classes.myImgProds} />
                    </ListItemAvatar>
                    <ListItemText
                        primary={item.nome}
                        secondary={
                            <React.Fragment>
                                {this.renderProds(item)}
                            </React.Fragment>
                        }
                    />
                </ListItem>
            );*/
        });
    }

    pesquisaLista = (value) => {
        window.scrollTo({top: 0, behavior: 'auto'});
        global.ProductObj.pesquisaProd = value;
        this.setState({pesquisaTxt: value}, () => {
            this.updateDimensions();
        });
    }

    abrePesquisa = (pesquisaAberta) => {
        if (pesquisaAberta){
            this.setState({
                scrollMarginTop: 170
            });
        }else{
            this.setState({
                scrollMarginTop: 120
            });
        }        
    }

    comparaTexto(val1, val2){

        var arrPalavras = val2.split(' ');
    
        var resultado = true;
        arrPalavras.map((palavra, index) => {
          if (!this.trataTexto(val1).includes(this.trataTexto(palavra))){
            resultado = false;
          }
        });
    
        return resultado;
    }

    trataTexto(val){
        return (
            val.toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        );
    }

    renderHeader = () => {

        return (
            <div style={{flexGrow: 1, justifyContent:'center', alignItems: 'center', fontSize: '20px', }}>
                Lista de Pedidos
            </div>
        );
    }

    leProdutosDisponiveis(tmpProd) {
        var menuItems = [];
        const cookies = new myCookies();
    
        try {
    
          var CancelToken = axios.CancelToken;
    
          
          let source = CancelToken.source();
          setTimeout(() => {
            source.cancel();
            
          }, 20000);
    
          var parametros = 'parresid=' + global.cod_restaurante + '&pargrures=' + global.cod_grurest + '&parmesa=' + (global.pedMesa ? "1" : "0");
          
          return axios.post(
            global.siteurl + 'produtos_lista.php', 
            parametros,
            {
                cancelToken: source.token,
                headers: {
                   'Content-Type': 'application/x-www-form-urlencoded', // <-- Specifying the Content-Type
                },
                withCredentials: true
            }).then((response) => {
              var responseData = response.data;
              
              if (responseData.resposta == '1') {
                var arrLista = JSON.parse(JSON.stringify(responseData.registros));
                var arrFinal = [];

                console.log(`ERR - TRACK - restaberto - 2 - ${global.restAberto}`);
                global.restAberto = (responseData.restaurante.aberto) ? responseData.restaurante.aberto : "0";
                console.log(`ERR - TRACK - restaberto - 2.1 - ${global.restAberto}`);    
                
                if (responseData.promocao == '1'){
    
                  var arrProdPromo = [];
    
                  for (var index = 0; index < responseData.registros.length; index++){
                    if (index > 1){
                      for (var i = 0; i < responseData.registros[index].produtos.length; i++){
                        if ((responseData.registros[index].produtos[i].promo == "1") && ( this.comparaTexto(responseData.registros[index].produtos[i].nomeProduto, this.state.pesquisaTxt) || this.comparaTexto(responseData.registros[index].produtos[i].descricao, this.state.pesquisaTxt ) ) ){
                          arrProdPromo.push( responseData.registros[index].produtos[i] );
                        }
                      };
                    }  
                  }
    
                  arrFinal.push({
                    nome : "Promoções",
                    imagem : "",
                    info : "",
                    produtos : arrProdPromo,  
                    fide_beneficios: 0,
                    fide_resgate: 0,
                  });
                }
    
                arrLista.map((prop, key) => {
    
                  arrFinal.push({
                    nome : prop.nome,
                    misto : prop.misto,
                    imagem : prop.cor,
                    info : prop.info,
                    codigo: prop.idGrupo,
                    produtos : prop.produtos,
                    grupoAdics : prop.grupoAdics,  
                    fide_beneficios: prop.fide_beneficios,
                    fide_resgate: prop.fide_resgate,
                  });
                  
                });
                
                var resultFunc   = global.ProductObj.copiaPedidoCarrinho(tmpProd, arrFinal);
                var indisponivel = resultFunc.indisp;

                var now = new Date();
                var time = now.getTime();
                var maxAge     = 60*60*24*40000;
                var expireTime = time + maxAge;

                var expires = (new Date(expireTime));

                cookies.set(global.cod_restaurante + '_dicaShowed', '1', { path: '/', expires: expires, maxAge: maxAge });

                if (indisponivel.length > 0){
                    this.setState({
                        alertaIndisponiveis: true,
                        prodsIndisp: indisponivel,
                        produtosDispQtd: resultFunc.dispQtd
                    });
                }else{
                    this.setState({
                        alertaIndisponiveis: true,
                        prodsIndisp: [],
                        produtosDispQtd: resultFunc.dispQtd
                    });
                }
              }else{
                this.setState({
                  erroDB : true,
                });
                return false;
              }
          })
          .catch((error) =>{
            if(axios.isCancel(error)){
                return false;
            }
            else{
                return false;
            }
          });
        } catch (err) {
            return false;
        }  
    
    
        
      }

    confirmaCopiaPedido = (pedido) => {
        if (this.state.carrinhoCompra.length > 0){
            this.setState({
                alertaConfCopia : true,
                copiaPedido : pedido
            });
        }else{
            this.copiaPedido(pedido);
        }
    }

    copiaPedido = async (pedido) => {

        var pedidoAtual = pedido.CODIGO_WEB;    

        var responseData = await global.ProductObj.copiaPedidoCarrinhoV2(axios, pedidoAtual);

        if (responseData == false){
            BS_ALE(this, 'Atenção', 'Não foi possível efetuar a solicitação, verifique sua conexão de internet e tente novamente. \nCOD 067', [{ text: 'OK', onPress: () => { } },], { cancelable: false },);
        }else if (responseData.INDISP.length > 0) {
            this.setState({
                alertaIndisponiveis: true,
                prodsIndisp: responseData.INDISP,
                produtosDispQtd: responseData.carrinhoTamanho
            });
        } else {
            this.setState({
                alertaIndisponiveis: true,
                prodsIndisp: [],
                produtosDispQtd: responseData.carrinhoTamanho
            });
        }       
    }

    renderPedidos = () => {

        const { classes } = this.props;

        return this.state.listaPedidos.map( (value, index) => {
            return (
                <div style={{backgroundColor: '#FFF', padding: '7px', marginBottom: '20px', borderRadius: '8px', }}>
                    <div style={{margin: '7px 0'}}>
                        {this.renderPedido(value)}
                    </div>
                    
                    <div style={{margin: '1px 1% 0 1%', width: '98%', height: '1px', }} className={classes.separator}></div>              
                    <div style={{display: 'flex', flexDirection: 'row', margin: '7px 3% 0px 3%'}}>
                        {this.renderCopiaPedido(value)}
                        <div style={{display: 'flex'}} onClick={() => { this.abrePedido(value) }}>
                            <text className={classes.linkButton} style={{margin: 'auto 0'}}>Ver este pedido</text>
                        </div>
                    </div>
                </div>
            );
        });
    }

    renderCopiaPedido = (value) => {

        const { classes } = this.props;
        
        if (value.CLIENTE.CODIGO != "0"){
            return (
                <div style={{marginRight: '20px', display: 'flex'}} onClick={() => { this.confirmaCopiaPedido(value) }}>
                    <text className={classes.linkButton} style={{margin: 'auto 0',}}>Adicionar ao Carrinho</text>
                </div>
            );
        }
    }

    getLinhaById = (array, id) => {
        return array.find((element) => {
            return element.ID == id;
        })
    }

    renderPedido = (item) => {

        let statusAtual = this.getLinhaById(this.state.listaStatus, item.STATUS);
        if (item.PEDIDO.BUSCAR == '1'){
            statusAtual = this.getLinhaById(this.state.listaStatusRetirada, item.STATUS);
        }else{
            statusAtual = this.getLinhaById(this.state.listaStatus, item.STATUS);
        }
        

        if (statusAtual.ID == tp_status_pendente){
            return (
                this.renderPedidoObs(item, statusAtual)
            );  
        }else if ((statusAtual.ID >= tp_pagamento_pendente) && (statusAtual.ID <= tp_pagamento_expirado)){
            return (
                this.renderPedidoObs(item, statusAtual)
            );  
        }else{
            return (
                this.renderPedidoBasico(item, statusAtual)
            ); 
        }

    }

    renderPedidoBasico = (item, statusAtual) => {

        var dataPedido  =    item.PEDIDO.DATA.substr(8, 2) + '/' + item.PEDIDO.DATA.substr(5, 2) + '/' + item.PEDIDO.DATA.substr(0, 4) +
                            ' ' + item.PEDIDO.HORA;

        return (
            <div onClick={() => {this.abrePedido(item)}} style={{marginLeft:'0%', borderColor:'#d4d4d4', borderBottomWidth: 1, width:'100%', display: 'flex', flexDirection: 'row'}}>
                <div style={{backgroundColor:'#', width: '70%', paddingTop: '3%', marginLeft: '3%', paddingBottom: '3%'}}>
                    <div style={{color: '#000', fontSize: 16, fontWeight: 'bold', marginBottom: 8,}}>PEDIDO {item.CODIGO_WEB}</div>
                    <text style={{color: '#000',}}>{dataPedido}</text>
                </div>

                <div style={{backgroundColor:'#', width: '30%', justifyContent: 'center', marginRight: '3%', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Icon className={"fa fa-" + statusAtual.Icone} style={{ fontSize: 24, color:"#c00", textAlignVertical: 'center', textAlign: 'center', width: 'auto', height: 'auto' }}/>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                        <text style={{color: '#000', width: '90%', fontSize: 15, textAlign: 'center', }}>{statusAtual.Status}</text>
                    </div>
                </div>          
            </div>  
        );
    
    }

    renderPedidoObs = (item, statusAtual) => {
        //const isSelected = (this.state.itemSelecionado === item.CODIGO_WEB);
        
        //const corFundo  = isSelected ? global.corSelecao : global.corFundo;
        //const corFundo2 = isSelected ? global.corSelecao : global.corFundo;
        //const corFonte  = isSelected ? global.corFonte : global.corFonte;

        const { classes } = this.props;

        return (
            <div onClick={() => {this.abrePedido(item)}} style={{marginLeft:'0%', borderColor:'#d4d4d4', borderBottomWidth: 1, width:'100%', display: 'flex', flexDirection: 'row'}}>
                <div style={{backgroundColor:'#', width: '70%', paddingTop: '3%', marginLeft: '3%', paddingBottom: '3%'}}>
                    <div style={{color: '#000', fontSize: 16, fontWeight: 'bold', marginBottom: 8,}}>PEDIDO {item.CODIGO_WEB}</div>
                    <div style={{color: '#000', fontWeight: 'bold', }}>{statusAtual.Mensagem}</div>
                </div>

                <div style={{backgroundColor:'#', width: '30%', justifyContent: 'center', marginRight: '3%', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Icon className={"fa fa-" + statusAtual.Icone} style={{ fontSize: 24, color:"#c00", textAlignVertical: 'center', textAlign: 'center', width: 'auto', height: 'auto' }}/>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                        <text style={{color: '#000', width: '90%', fontSize: 15, textAlign: 'center', }}>{statusAtual.Status}</text>
                    </div>
                </div>          
            </div>            
        );
    
        /*
        var dataPedido  =    item.PEDIDO.DATA.substr(8, 2) + '/' + item.PEDIDO.DATA.substr(5, 2) + '/' + item.PEDIDO.DATA.substr(0, 4) +
                            ' ' + item.PEDIDO.HORA;
    
        if (statusAtual.ID == tp_status_pendente){
          return (
            <div>
                <text style={{color: corFonte, fontSize: 16, fontWeight: 'bold', marginBottom: 8,}}>PEDIDO {item.CODIGO_WEB}</text>
                <text style={{color: corFonte, fontWeight: 'bold', }}>{statusAtual.Mensagem}</text>
            </div>
          );  
        }
        
        if ((statusAtual.ID >= tp_pagamento_pendente) && (statusAtual.ID <= tp_pagamento_expirado)){
          return (
            <TouchableWithoutFeedback 
              onPress={() => this.selectItem(item)}
            >
              <View key={item.CODIGO_WEB} style={{marginLeft:'0%', borderColor:'#d4d4d4', borderBottomWidth: 1, width:'100%',}}>
                <LinearGradient start={{x: 0, y: 0}} end={{x: 0, y: 1}} colors={[corFundo, corFundo2]} style={{width: '100%', flexDirection: 'row'}}>
                  <View style={{backgroundColor:'#', width: '60%', paddingTop: '3%', marginLeft: '3%', paddingBottom: '3%'}}>
                        <Text style={{color: corFonte, fontSize: 16, fontWeight: 'bold', marginBottom: 8,}}>PEDIDO {item.CODIGO_WEB}</Text>
                        <Text style={{color: corFonte, fontWeight: 'bold', }}>{statusAtual.Mensagem}</Text>
                    </View>
      
                    <View style={{backgroundColor:'#', width: '40%', justifyContent: 'center', marginRight: '3%', alignItems: 'center', }}>
                      <Icon type={statusAtual.Fonte} name={statusAtual.Icone} style={{ paddingTop: 5, fontSize: 24, color:global.rgRetirar, textAlignVertical: 'center', textAlign: 'center', }}/>    
                      <Text style={{color: corFonte, width: '90%', fontSize: 15, textAlign: 'center', }}>{statusAtual.Status}</Text>
                    </View>                                   
                </LinearGradient>          
              </View>  
            </TouchableWithoutFeedback >
          );  
        }
    
        return (
          <TouchableWithoutFeedback 
            onPress={() => this.selectItem(item)}
          >
            <View key={item.CODIGO_WEB} style={{marginLeft:'0%', borderColor:'#d4d4d4', borderBottomWidth: 1, width:'100%',}}>
              <LinearGradient start={{x: 0, y: 0}} end={{x: 0, y: 1}} colors={[corFundo, corFundo2]} style={{width: '100%', flexDirection: 'row'}}>
                <View style={{backgroundColor:'#', width: '60%', paddingTop: '3%', marginLeft: '3%', paddingBottom: '3%'}}>
                      <Text style={{color: corFonte, fontSize: 16, fontWeight: 'bold', marginBottom: 8,}}>PEDIDO {item.CODIGO_WEB}</Text>
                      <Text style={{color: corFonte,}}>{dataPedido}</Text>
                  </View>
    
                  <View style={{backgroundColor:'#', width: '40%', justifyContent: 'center', marginRight: '3%', alignItems: 'center', }}>
                    <Icon type={statusAtual.Fonte} name={statusAtual.Icone} style={{ paddingTop: 5, fontSize: 18, color:global.rgFundoFont, textAlignVertical: 'center', textAlign: 'center', }}/>    
                    <Text style={{color: corFonte, fontSize: 14, textAlign: 'center', }}>{statusAtual.Status}</Text>
                  </View>                                   
              </LinearGradient>          
            </View>  
          </TouchableWithoutFeedback >
        );*/
    }

    abrePedido = (item) => {

        
        global.buscar      = (item.PEDIDO.BUSCAR == '1');
        global.pedidoAtual = item.CODIGO_WEB;
        this.props.navigate('pedido-status');
    }

    renderListaPedidos = () => {

        const { classes } = this.props;

        if (this.state.listaPedidos){
            return (

                <div style={{ scrollMarginTop: (this.state.scrollMarginTop + "px"), maxWidth: '100%', }}>
                    <div>
                        <div>
                            <div style={{display: 'flex', flexDirection: 'column', }}>
                                <div style={{display: 'flex', flexDirection: 'column', borderRadius: '8px', backgroundColor: '#efefef', padding: '20px', paddingBottom: '0px', width: '94%', margin: '0 3% 0 3%'}}>
                                    {this.renderPedidos()}           
                                </div>
                            </div>        
                        </div> 
                    </div>
                </div>  
    
            );
        }        
    }

    handleCloseConfCopia = () => {
        this.setState({
            alertaConfCopia : false,
        });
    }

    renderConfirmacao = (hist) => {
        return (
          <Confirmacao 
              showAlert={this.state.alertaConfCopia}
              onClose={this.handleCloseConfCopia}
              callbackYes={() => {
                this.setState({
                    alertaConfCopia : false,
                }, () => {
                    this.copiaPedido(this.state.copiaPedido);
                });
              }} 
              title={'Atenção'}
              msg={'Você possui já possui produtos no carrinho\n\nDeseja trocá-los pelos deste pedido?'}
              callbackNo={() => {
                  this.handleCloseConfCopia();
              }}
          />
        );
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.myBody} style={{marginTop: '0px', marginBottom: '0px', paddingBottom: '0px'}}>
                <div className={classes.container} style={{paddingBottom: '0px'}}>
                    <Card style={{minHeight: '100vh'}}>
                        <form className={classes.form}>
                            <Header context={this} defaultIcon={false} leftLinks={
                                <div style={{ whiteSpace: 'nowrap'}}>
                                    <div style={{width: 'max-content', whiteSpace: 'nowrap', flexDirection: 'column', marginTop: '12px', marginBottom: '12px', }} className={""}>
                                        {this.renderHeader()}
                                    </div>                                    
                                </div>
                            } />
                            <CardBody>
                                {this.renderListaPedidos()}
                            </CardBody>
                        </form>                
                    </Card>
                </div>
                {this.renderDialogListaProdIndisp()}
                {this.renderConfirmacao()}
                <Footer qtdeTiposProds={this.state.carrinhoCompra.length} whiteFont/>
            </div>
        );
    }
}

export default withStyles(PedidoLista, styles);
