import { 
  container,
  defaultFont,
  primaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  roseColor,
  transition,
  boxShadow,
  drawerWidth

 } from "assets/jss/material-kit-react.js";

const signupPageStyle = {
  container: {
    ...container,
    position: "relative",
    paddingTop: "0px",
    color: "#FFFFFF",
    paddingBottom: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  myBody:{
    overscrollBehaviorY: 'contain',
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)"
  },
  pageHeader: {
    minHeight: "100vh",
    height: "auto",
    display: "inherit",
    position: "relative",
    margin: "0",
    padding: "0",
    border: "0",
    alignItems: "center",
    "&:before": {
      background: "rgba(0, 0, 0, 0.5)"
    },
    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""'
    },
    "& footer li a,& footer li a:hover,& footer li a:active": {
      color: "#FFFFFF"
    },
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%"
    }
  },
  form: {
    margin: "0"
  },
  myImgProds: {
      height: "80px",
      width: "80px",
      marginRight: "10px",
  },
  cardHeader: {
    width: "auto",
    textAlign: "center",
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: "-40px",
    marginBottom: "0px",
    height: "250px",    
    background: "none",
    backgroundColor: "#fff",
    padding: "0px",
  },
  socialIcons: {
    maxWidth: "24px",
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px"
  },
  divider: {
    marginTop: "30px",
    marginBottom: "0px",
    textAlign: "center"
  },
  cardFooter: {
    paddingTop: "0rem",
    border: "0",
    borderRadius: "6px",
    justifyContent: "center !important"
  },
  socialLine: {
    marginTop: "1rem",
    textAlign: "center",
    padding: "0"
  },
  inputIconsColor: {
    color: "#495057"
  },
  myAvatarImg: {
    width: '100%',
    height: 'unset',
    textAlign: 'center',
    // Handle non-square image. The property isn't supported by IE 11.
    objectFit: 'cover',
    // Hide alt text.
    color: 'transparent',
    // Hide the image broken icon, only works on Chrome.
    textIndent: 10000,
  },
  separator: {
    width: "100%",
    height: "1px",
    marginRight: "30px",
    backgroundImage: "radial-gradient(#CCC, #FFF), radial-gradient(#CCC, #FFF)",
    backgroundPosition: "0, 100%, 0, 100%",
  },
  separatorMenu: {
    width: "100%",
    height: "2px",
    marginRight: "30px",
    backgroundImage: "radial-gradient(#F00, #FFF), radial-gradient(#F00, #FFF)",
    backgroundPosition: "0, 100%, 0, 100%",
  },
  loginForm: {
    width: "70%",
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '80px',
    marginTop: '80px',
    maxWidth: '444px',
  },
  addEndForm: {
    width: "85%",
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '80px',
    maxWidth: '444px',
  },
  usuarioForm: {
    width: "90%",
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '80px',
    marginTop: '80px',
  },
  paperHeader: {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: '2px',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: '6px 0px 4px',
    fontSize: '15px',
    fontWeight: '400',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
  },
  facebookBtn: {
    backgroundColor: '#3b5998',
    margin: '6px 0px 4px',
    fontSize: '15px',
    width: '100%',
    color: '#fff',
    fontWeight: '400',
    height: '45px',
  },
  enderecoInputs : {
    [`& fieldset`]: {
      borderRadius: '14px',
    },
    [`& .MuiSelect-select`]: {
      borderRadius: '14px',
    },
    marginTop: '12px',
    marginBottom: '12px',
  },
  myTooltip: {
    fontSize: '18px',
    width: '450px',
  },

  appBar: {
    display: "flex",
    border: "0",
    borderRadius: "3px",
    padding: "0",
    marginBottom: "20px",
    color: "#555",
    width: "100%",
    backgroundColor: "#fff",
    boxShadow:
      "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)",
    transition: "all 150ms ease 0s",
    alignItems: "center",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    position: '-webkit-sticky',
    position: 'sticky',
    top: 0,
    zIndex: 5
  },
  absolute: {
    position: "absolute",
    zIndex: "1100"
  },
  fixed: {
    position: "fixed",
    zIndex: "1100"
  },
  flex: {
    flex: 1,
    width: '200px',
  },
  title: {
    ...defaultFont,
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    padding: "8px 16px",
    letterSpacing: "unset",
    "&:hover,&:focus": {
      color: "inherit",
      background: "transparent"
    }
  },
  myTitle: {
    flexGrow: 1,
    alignSelf: 'flex-end',
  },
  appResponsive: {
    margin: "20px 10px"
  },
  primary: {
    backgroundColor: primaryColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(156, 39, 176, 0.46)"
  },
  info: {
    backgroundColor: infoColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(0, 188, 212, 0.46)"
  },
  success: {
    backgroundColor: successColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(76, 175, 80, 0.46)"
  },
  warning: {
    backgroundColor: warningColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(255, 152, 0, 0.46)"
  },
  danger: {
    backgroundColor: dangerColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(244, 67, 54, 0.46)"
  },
  rose: {
    backgroundColor: roseColor,
    color: "#FFFFFF",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(233, 30, 99, 0.46)"
  },
  transparent: {
    backgroundColor: "transparent !important",
    boxShadow: "none",
    paddingTop: "25px",
    color: "#FFFFFF"
  },
  dark: {
    color: "#FFFFFF",
    backgroundColor: "#212121 !important",
    boxShadow:
      "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46)"
  },
  white: {
    border: "0",
    padding: "0.625rem 0",
    marginBottom: "20px",
    color: "#555",
    backgroundColor: "#fff !important",
    boxShadow:
      "0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)"
  },
  root: {
    alignItems: "flex-end", // push the dialog to bottom
    margin: '0px',
  },
  paper: {
    // make the content full width
    margin: "0px",
    maxWidth: "100%",
    width: "100%",
    maxHeight: "100%",
    height: "100%",
  },
  drawerPaper: {
    border: "none",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    width: drawerWidth,
    ...boxShadow,
    position: "fixed",
    display: "block",
    top: "0",
    height: "100vh",
    right: "0",
    left: "auto",
    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
    ...transition
  }
};

export default signupPageStyle;
