import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";

import styles from "assets/jss/material-kit-react/components/cardBodyStyle.js";

const StyledBox = styled(Box)(styles);

export default function CardBody({ className, children, ...rest }) {
  return (
    <StyledBox
      className={className}
      sx={{ minHeight: '550px', paddingBottom: '100px' }}
      {...rest}
    >
      {children}
    </StyledBox>
  );
}

CardBody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};
