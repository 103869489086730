import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import { Box } from "@mui/material";

import styles from "assets/jss/material-kit-react/components/cardHeaderStyle.js";

const StyledBox = styled(Box)(styles);

export default function CardHeader({ className, children, color, plain, ...rest }) {
  const cardHeaderClasses = `${styles.cardHeader} ${
    color ? styles[`${color}CardHeader`] : ''
  } ${plain ? styles.cardHeaderPlain : ''} ${className || ''}`;

  return (
    <StyledBox className={cardHeaderClasses} {...rest}>
      {children}
    </StyledBox>
  );
}

CardHeader.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(["warning", "success", "danger", "info", "primary"]),
  plain: PropTypes.bool,
  children: PropTypes.node
};
