import { container } from "assets/jss/material-kit-react.js";

const signupPageStyle = {
  container: {
    ...container,
    position: "relative",
    paddingTop: "0px",
    color: "#FFFFFF",
    paddingBottom: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  myBody:{
    overscrollBehaviorY: 'contain',
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)"
  },
  pageHeader: {
    minHeight: "100vh",
    height: "auto",
    display: "inherit",
    position: "relative",
    margin: "0",
    padding: "0",
    border: "0",
    alignItems: "center",
    "&:before": {
      background: "rgba(0, 0, 0, 0.5)"
    },
    "&:before,&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: '""'
    },
    "& footer li a,& footer li a:hover,& footer li a:active": {
      color: "#FFFFFF"
    },
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%"
    }
  },
  form: {
    margin: "0"
  },
  myImgProds: {
      height: "80px",
      width: "80px",
      marginRight: "10px",
  },
  cardHeader: {
    width: "auto",
    textAlign: "center",
    marginLeft: "0px",
    marginRight: "0px",
    marginTop: "-40px",
    marginBottom: "0px",
    background: "none",
    backgroundColor: "#fff",
    padding: "0px",
  },
  socialIcons: {
    maxWidth: "24px",
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px"
  },
  divider: {
    marginTop: "30px",
    marginBottom: "0px",
    textAlign: "center"
  },
  cardFooter: {
    paddingTop: "0rem",
    border: "0",
    borderRadius: "6px",
    justifyContent: "center !important"
  },
  socialLine: {
    marginTop: "1rem",
    textAlign: "center",
    padding: "0"
  },
  inputIconsColor: {
    color: "#495057"
  },
  myAvatarImg: {
    width: '100%',
    height: 'unset',
    textAlign: 'center',
    // Handle non-square image. The property isn't supported by IE 11.
    objectFit: 'cover',
    // Hide alt text.
    color: 'transparent',
    // Hide the image broken icon, only works on Chrome.
    textIndent: 10000,
  },
  separator: {
    width: "100%",
    height: "1px",
    marginRight: "30px",
    backgroundImage: "radial-gradient(#CCC, #FFF), radial-gradient(#CCC, #FFF)",
    backgroundPosition: "0, 100%, 0, 100%",
  },
  separatorMenu: {
    width: "100%",
    height: "2px",
    marginRight: "30px",
    backgroundImage: "radial-gradient(#F00, #FFF), radial-gradient(#F00, #FFF)",
    backgroundPosition: "0, 100%, 0, 100%",
  },
  linkButton: {
    fontSize: '12px !important',
    fontWeight: '410 !important',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important',
    textDecoration: 'underline',
    color: '#daa520'
  },
  paperFullSize: {
    // make the content full width
    margin: 0,
    maxWidth: "100%",
    width: "100%",
    maxHeight: "100%",
    height: "100%",
    padding: 0,
  },
  paperImg: {
    // make the content full width
    margin: 0,
    maxWidth: "100%",
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    maxHeight: "100%",
    height: "auto",
    padding: 0,
  },
  dialogProAle: {
    // make the content full width
    margin: 0,
    maxWidth: "100%",
    width: "85%",
    marginLeft: "auto",
    marginRight: "auto",
    maxHeight: "100%",
    height: "auto",
    padding: 0,
  },
  rootNoPadding: {
    padding: 0,
  }
};

export default signupPageStyle;
